import React from 'react'
import { CardActions, Box } from '@material-ui/core';
import FormBackButton from './FormBackButton';
import FormSubmitButton from './FormSubmitButton';
import FormSaveDraftButton from './FormSaveDraftButton';
import PropTypes from 'prop-types'

const FormButtons = (props) => {    

    const { onBack, onSubmit, onSaveDraft, displayBackButton = true, displaySaveDraftButton = true, submitEnabled = true} = props;    
    return (
        <Box pt={3} pb={1}>
            <CardActions style={{justifyContent: 'flex-end'}}>
                { displayBackButton && <FormBackButton onClick={onBack}/> }
                { displaySaveDraftButton && <FormSaveDraftButton onClick={onSaveDraft} /> }
                <FormSubmitButton onClick={onSubmit} enabled={submitEnabled}/>
            </CardActions>
        </Box>
    )
}

FormButtons.propTypes = {
    displayBackButton: PropTypes.bool,
    displaySaveDraftButton: PropTypes.bool,

    submitEnabled: PropTypes.bool,

    onBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onSaveDraft: PropTypes.func,
}

export default FormButtons;