
const copyToClipboard = (text) => {
    let result = null;
    if (navigator?.clipboard?.writeText) {
        result = navigator.clipboard.writeText(text);

    } else {
        result = new Promise( (resolve, reject) => {
            try {
                const el = document.createElement("textarea");
                el.value = text;
                el.setAttribute("readonly", "");
                el.style.position = "absolute";
                el.style.left = "-9999px";
                document.body.appendChild(el);
                el.select();
                el.setSelectionRange(0, 99999);
                document.execCommand("copy");
                document.body.removeChild(el);
                
            } catch (error) {
                reject(error);
            }
    
            resolve();
        })
    }

    return result;
}

export default copyToClipboard;