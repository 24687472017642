import React, { useState, useEffect } from 'react'
import CropFreeIcon from '@material-ui/icons/CropFree';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import useQRCodeScan from '../../../hooks/useQRCodeScan';

const isValidMac = (value) => {
    return /^[0-9a-f]{12}$/.test(value);
}

const MacScanner = (props) => {
    const [value, setValue] = useState(props?.value||'');
    const { key, name, onChange, listenOnChange = null, required = false, ...remainingProps } = {...props};
    const { startQrCode, decodedQRData, stopQrCode } = useQRCodeScan({
        qrcodeMountNodeID: "qrCodeScanner",
      });

    const { isScanning = false } = decodedQRData;

    const startQrScan = () => {
        startQrCode();
    }

    const stopQrScan = () => {
        stopQrCode();
    }

    const handleTextChange = (value = null) => {
        if (value === null) return;
        value = value.replace(/[^a-fA-F0-9 ]/g, "").slice(0,12).toLowerCase();
        setValue(value);
        if ( ! isValidMac(value) ) return;
        
        onChange(name, value);
        listenOnChange && listenOnChange (value);
    }

    useEffect ( () => {
        console.log ('QR Scanner status', decodedQRData);
        if (decodedQRData.isScanFailure) {
            console.error (decodedQRData);

            if (decodedQRData.error === 'Error getting userMedia, error = NotAllowedError: Permission denied') {
                toast.error('Debe dar permisos para acceder a la cámara', {toastId: 'camera_not_allowed_error'});
            } else {
                toast.error(`Ocurrió un error accediendo a la cámara ${decodedQRData.error}`, {toastId: 'camera_undefined_error'});
            }

        } else {
            if (decodedQRData?.isScanSuccess) handleTextChange (decodedQRData.data);

        }



    }, [decodedQRData])


    return (
        <Grid container direction="row" alignItems="flex-end">
            <Grid item xs={11}>
                <TextField
                    {...remainingProps}
                    key={`${key}_field`}
                    inputProps = {{ 'aria-label': 'Ingresar MAC o escanear código QR' }}
                    label = "Ingresar MAC o escanear código QR"
                    onChange = { (event) => handleTextChange (event.target.value) }
                    value = { value }
                    maxLength = { 12 }
                    autoComplete="off"
                    fullWidth
                    required={required}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton 
                    key={`${key}_button`}
                    type="button" 
                    aria-label="Escanear código QR"
                    title={isScanning ? 'Detener escaneo' : 'Escanear código QR'}
                    onClick={ () => { console.log(isScanning) ; isScanning ? stopQrScan() : startQrScan() } }
                >
                    {isScanning ? <ClearIcon /> : <CropFreeIcon />}
                </IconButton>
            </Grid>
            <Grid item xs={12} style={{paddingTop: 20}}>
                <div id="qrCodeScanner" />
            </Grid>
        </Grid>
    )
}

MacScanner.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    listenOnChange: PropTypes.func,
    required: PropTypes.bool
}

export default MacScanner;