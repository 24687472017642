import React from 'react'

import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const DialogPhoto = (props) => {
    const {open, onClose, title, url} = props;

    if (! open || ! url) return (null);
    return (<Lightbox image={url} title={title} onClose={onClose}/>);
}

export default DialogPhoto;