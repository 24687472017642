import React from 'react'

export default function withHiddenProp(WrappedComponent) {
  return class extends React.Component {
      render() {
          if (this.props.hidden) {
              return null;
          } else {
              return <WrappedComponent
                {...this.props} />;
          }
      }
  };
}