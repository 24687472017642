import React, { Component } from 'react'
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/styles';

import { Box } from '@material-ui/core';
import { Paper } from '@material-ui/core';

import Swal from 'sweetalert2';

import { pageStyle } from '../components/styles';
import RtuTable from '../components/RtuTable';
import loggedUserServerFetch from '../services/loggedUserServerFetch';
import RtuForm from '../components/forms/RtuForm';
import OtaPopup from '../components/OtaPopup';
import StateDialog from '../components/StateDialog';
import FileDownload from 'js-file-download';
import { COMMAND_REQUEST_CONFIG_ID } from '../constants/misc';
import RtuConfigForm from '../components/RtuConfig.new';
import RtuDebugDialog from '../components/RtuDebugDialog';
import RtuHistory from '../components/RtuHistory/index';
import RtuRawConfigForm from '../components/RtuConfig/RtuRawConfigForm';
import { API_RTU_IMPORT, API_RTU_LIST, API_RTU, API_RTU_FORM, API_RTU_FAT, 
    API_RTU_COMMAND, API_RTU_UPDATE_CONFIG, API_RTU_PATCH, 
    API_RTU_UPDATE_RAW_CONFIG, API_RTU_GET_SETUP_TEMPLATE, API_ASSEMBLY_CREATE, API_RTU_LABEL } from '../constants/api';
import AssemblyForm from '../components/forms/AssemblyForm';
import PopUp from '../components/popup/Popup';
import RtuParametersForm from '../components/forms/RtuParametersForm';
import RtuAdvancedSearch from '../components/RtuTable/RtuAdvancedSearch';
import _ from 'lodash';
import dayjs from '../helpers/dayjs';

const SEARCH_MODE = {
    SIMPLE: 0,
    FULL: 1,
}


class RtusPage extends Component {
    constructor (props) {
        super(props);

        this.errors = {
            null: 'Ocurrió un error inesperado. Por favor reintente más tarde',
            'E10': 'El firmware de la remota no tiene un template de configuración asociado',
        }

        const timer = setInterval ( () => {
            this.tableRef.current && this.tableRef.current.onQueryChange();
        }, 5000);

        this.state = {
            didCancel: false,
            hideTable: false,
            hideForm: true,
            
            hideConfigForm: false,
            readonlyConfigForm: false,

            rtu: null,
            rtuId: null,            
            formSchema: null,
            timer: timer,

            rtu_types: [],

            commands: [],

            state_display: false,            
            rtuTimer: null,

            ota_form_display: false,

            debug_dialog_display: false,
            debug_dialog_rtu: null,

            history_dialog_display: false,
            history_dialog_rtu: null,

            rawConfigRtu: null,
            hideJsonConfigForm: true,

            displayAssembleForm: false,
            assembleFormData: null,

            displaySetType: false,

            search_mode: SEARCH_MODE.SIMPLE,
            advanced_filter: {}
        }

        this.lastQuery = {mode: 0, search: ''};

        this.tableRef = React.createRef();
    }

    setStateAsync = async (state) => {
        return new Promise((resolve) => this.setState(state, resolve) );
    }

    /**
     * Fetch additional information from server (command list, etc)
     */
    componentDidMount = async () => {
        const url = API_RTU_FORM;
        const method = 'get';

        try {
            const response = await loggedUserServerFetch (url, method);
            this.setStateAsync( { ...this.state, commands: response.data?.commands||[], rtu_types: response.data?.rtu_types||[]});

        } catch (error) {
            toast.error ('Ocurrió un error obteniendo la lista de comandos disponibles');
            console.error(error);
        }
    }

    /**
     * When component is about to close, clear timers
     */
    componentWillUnmount = () => {
        if (this.state.timer) clearInterval(this.state.timer);
        if (this.state.rtuTimer) clearInterval(this.state.rtuTimer);
        this.setState ({...this.state, timer: null, rtuTimer: null, didCancel: true});
    }

    _isFilterSame = (newQuery, oldQuery = this.lastQuery) => {
        let nquery = {...newQuery}
        let oquery = {...oldQuery}

        delete nquery.sort_field;
        delete nquery.sort_order;
        delete nquery.page;
        delete nquery.page_size;

        delete oquery.sort_field;
        delete oquery.sort_order;
        delete oquery.page;
        delete oquery.page_size;

        return _.isEqual(nquery, oquery);
    }

    /**
     * Fetch elements from backend to data table
     * @param {object} query 
     * @returns {Promise}
     */
    fetchElements = (query) => {
        if (! window.navigator.onLine) return new Promise ((resolve,reject)  => reject ('Esperando conexión a internet...'));

        let q = {};

        if (this.state.search_mode === SEARCH_MODE.FULL ) {
            //Búsqueda avanzada
            q = {...this.state.advanced_filter, mode: 1};

        } else {
            //Búsuqeda simple
            if (query?.search !== null) q = { search: query.search, mode: 0};

        }

        if (query?.orderBy !== null && query?.orderBy !== undefined) {
            q['sort_field'] = query.orderBy?.field;
            q['sort_order'] = query.orderDirection;
        }

        //If filter has changed, reset page to 1
        q['page'] = this._isFilterSame(q) ? ( (query?.page || 0) + 1) : 1 ;
        q['page_size'] = query?.pageSize;

        this.lastQuery = {...q};

        return new Promise ((resolve,reject) => {
            loggedUserServerFetch (API_RTU_LIST, 'get', {}, {}, q).then ( (response) => {
                const result = {
                    data: (response?.data?.items || []).map( e => ({ key: `RTU_${e?.id}`, ...e }) ),
                    page: Math.max((response?.data?.pagination?.page -1)||0,0),
                    totalCount: response?.data?.pagination?.total_items || 0,
                };
                resolve(result);
    
            }).catch ( (error) => {
                if (!error.response) {
                    console.error('Network error updating rtu grid', error);
                    reject ('Conectando...');

                } else if (window.navigator.onLine) {
                    console.error('Error updating rtu grid', error, window.navigator, error.response);
                    toast.error('Ocurrió un error de comunicación', 'error_server_comm');
                    reject (error);

                } else {
                    reject ('Esperando conexión a internet...');
                }
            })
        })        
    }

    /**
     * Download listed RTUs
     */
    handleDownloadList = () => {
        let query = {...this.lastQuery}
        delete query.page;
        delete query.page_size;

        const p = new Promise ( (resolve, reject) => {
            loggedUserServerFetch (API_RTU_LIST, 'get', {}, {}, query).then ( (response) => {
                resolve();
                let output = '"Mac", "Nombre", "IMEI", "Tipo", "Fecha de creación", "Estado", "Fecha ultimo estado", "Online?", "Alimentación", "OTA?", "FW", "Cliente"\n';

                (response.data.items || []).forEach(item => {
                    const row = [
                        item.mac,
                        item.friendly_name,
                        item.imei,
                        item.rtu_type.description,
                        dayjs(item.created_at).format('DD/MM/YYYY HH:mm:ss'),
                        item.status,
                        item?.last_state_at ? dayjs(item.last_state_at).format('DD/MM/YYYY HH:mm:ss') : '',
                        item.is_online ? 'Online' : 'Offline',
                        item.is_on_battery ? 'Batería' : 'AC',
                        item.has_pending_ota ? 'Sí' : 'No',
                        item.fw_ver,
                        item.customer.description
                    ].map( i => `"${i}"`).join(', ');
                    output += row + '\n';
                })
            
                FileDownload(output, 'rtus.csv');
                
            }).catch ( (error) => {
                console.error(error);
                reject ('Ocurrió un error inesperado');
            })
        })

        toast.promise(p, {
            'pending': 'Descargando listado de RTUs...',
            'success': 'Listado de RTUs descargado exitosamente',
            'error': 'Ocurrió un error inesperado',
            'pauseOnFocusLoss': false
        })

    }

    /**
     * Shows upload form
     */
    handleCreate = () => {
        this.setState( { ...this.state, hideForm: false, hideTable: true} );
    }

    /**
     * Hides upload form
     */
    handleCancel = () => {
        this.setState( { ...this.state, hideForm: true, hideTable: false}, () => {
            this.tableRef.current && this.tableRef.current.onQueryChange();
        } );
    }

    /**
     * Pushes mac addresses to server
     * @param {object} form      
     */
    handleSubmit = async ( form ) => {
        try {
            //Separo el string por salto de línea, elimino las líneas vacías y paso todo a minúsculas
            const macs = form.macs.split(/\r\n|\n|\r/).filter ( (e) => !!e ).map( (e) => e.toLowerCase().trim() );
            
            if ( macs.length === 0 ) {
                toast.error ('Debe ingresar al menos una macaddress', 'err_mac_error');
                return;
            }

            //Verifico que todas las líneas correspondan a una mac
            const macRegex = /^([0-9a-f]{2}[:-]?){5}([0-9a-f]{2})$/;
            if (! macs.every ( e =>  macRegex.test(e) ) ) {
                toast.error ('Se han introducido datos inválidos', 'err_mac_error2');
                return;
            }

            let url = API_RTU_IMPORT;
            let method = 'put';
            const payload = {
                mac: macs,
                rtu_type: form.rtu_type,
            }

            const response = await loggedUserServerFetch (url, method, payload);
            await this.setStateAsync( { ...this.state, hideForm: true, hideTable: false });

            (this.tableRef.current) && this.tableRef.current.onQueryChange();
            
            console.log ( 'Resultado de la importación de remotas', response.data);
    
            Swal.fire ({
                title: 'Importar remotas',
                type: 'success',
                html: `${response.data?.created?.length||0} creadas<br>${response.data?.exists?.length||0} existentes<br>${response.data?.error?.length||0} fallaron`,
            });
            
            
        } catch (error) {
            if (error.response.status === 400) {
                toast.error(error.response.data, 'err_custom');
    
            } else {
                toast.error ('Ocurrió un error desconocido. Por favor vuelva a intentar', 'err_unknown');
            }            
        }        
    }

    /**
     * Refresh RTU state
     * @param {integer} id 
     */
    updateRtuData = async (id) => {
        try {
            const url = (API_RTU).replace('{$id}', id);
            const method = 'get';
            const response = await loggedUserServerFetch (url, method);
            await this.setStateAsync( { ...this.state, state_display: response.data.last_state } );

        } catch (error) {
            (this.state.rtuTimer) && clearInterval(this.state.rtuTimer);
            await this.setStateAsync({...this.state, rtuTimer: null});
            toast.error ('Ocurrió un error actualizando el estado de la remota. El refresco automático está deshabilitado', 'err_rtu_state_refresh');
        }
    }

    /**
     * Display RTU State
     * @param {integer} id 
     * @param {object} state first state to display
     */
    handleViewState = (id, state) => {        

        if (this.state.rtuTimer) clearInterval(this.state.rtuTimer);
        const timer = setInterval ( () => {
            this.updateRtuData(id);
        }, 5000);   

        this.setState({...this.state, state_display: state, rtuId: id, rtuTimer: timer});
    }

    /**
     * Close state view
     */
    handleCloseViewState = () => {
        (this.state.rtuTimer) && clearInterval(this.state.rtuTimer);
        this.setState({...this.state, state_display: null, rtuId: null, rtuTimer: null});
    }

    handleSendCommand = async (rtuId, commandId, param = null) => {
        try {
            const url = (API_RTU_COMMAND).replace('{$id}', rtuId).replace('{$command_id}', commandId);
            const method = 'put';
            await loggedUserServerFetch (url, method, param);
            toast.success ('Comando encolado exitosamente', 'command_enqueue_success');
            this.tableRef.current && this.tableRef.current.onQueryChange();

        } catch (error) {
            toast.error ('No se pudo encolar el comando. Intente nuevamente', 'command_enqueue_error');
        }
    }

    handleFatDownload = async (element) => {
        try {
            const url = (API_RTU_FAT).replace('{$id}', element.id);
            const response = await loggedUserServerFetch (url, 'get', {}, {}, {}, {responseType: 'blob'});
            FileDownload(response.data, `FAT-${element.mac}.pdf`);

        } catch (error) {
            toast.error ('Ocurrió un error desconocido al descargar el ensayo FAT', 'err_fat');
            console.error(error);
        }
    }

    handleEditConfig = (element) => {
        //1) Si no hay configuración: deshabilitar el botón. (Gris)
        //2) Si hay configuración y hay un comando y el comando es de config: Ver cacheda (RO) (Celeste)
        //3) Si hay configuración y está actualizada: editar (Verde)
        //4) Si hay configuración y no está actualizada: preguntar (Amarillo)

        if (! element.config.value) return; //1

        if (element.config.value && element.command.pending && element.command.config_related_command) {
            //2
            //Ver cacheada
            toast.info('Hay un comando de configuración pendiente. No se puede editar la configuración.', 'info');
            this.editConfig ( element, true);

        } else if (element.config.value && ! element.config.is_timedout) {
            //3
            //Editar
            this.editConfig ( element, false);

        } else if (element.config.value && element.config.is_timedout) {
            //4
            //Preguntar
            const self = this;

            Swal.fire({
                showCancelButton: true,
                cancelButtonText: 'No editar esta configuración', 
                
                showConfirmButton: true,
                confirmButtonText: 'Continuar con la opción seleccionada',
                confirmButtonColor: '#3085d6',

                icon: 'question',

                text: 'La configuración almacenada es antigua y se recomienda pedir una nueva copia. ¿Qué desea hacer?',

                input: 'select',

                inputOptions: { safe: 'Solicitar actualizción de configuración', risk: 'Continuar bajo mi propio riesgo' },
                inputPlaceholder: 'Seleccione una acción',

                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (!! value) {
                          resolve()
                        } else {
                          resolve('Debe seleccionar una opción')
                        }
                      })
                }

            }).then((result) => {
                if (result.value === 'risk') {
                    self.editConfig ( element, false);

                } else if (result.value === 'safe') {
                    self.handleSendCommand(element.id, COMMAND_REQUEST_CONFIG_ID);
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                }
            });
        }
    }

    editConfig = async (rtu, readonly = false) => {
        try {
            const url = (API_RTU_GET_SETUP_TEMPLATE).replace('{$id}', rtu.id);
            const {data} = await loggedUserServerFetch(url, 'get');
            
            if ((data?.form||[]).length === 0) {
                toast.error ('El firmware no tiene una plantilla de configuración asociada o la plantilla es incorrecta');
                return false;
            }
            
            this.setState ( {...this.state, rtu: rtu, hideConfigForm: false, readonlyConfigForm: readonly, hideTable: true, formSchema: data});

        } catch (error) {
            console.error(error);
            let str = null;
            const errorCode = error?.response?.status||null;

            switch (errorCode) {
                case 404:
                    str = 'No se encuentra la RTU, o el equipo no está asociado a un firmware';
                    break;

                case 400:
                    str = this.errors[error?.response?.data?.status||null];
                    break;
            
                default:
                    str = 'Ocurrió un error desconocido, por favor vuelva a intentar nuevamente';
                    break;
            }
            toast.error (str);
        }
    }

    handleSaveConfig = async (rtuId, config) => {
        try {
            const url = (API_RTU_UPDATE_CONFIG).replace('{$id}', rtuId);
            let method = 'patch';
            const response = await loggedUserServerFetch (url, method, config);
            
            let type = 'success';
            let text = '';
            if (!!response.data?.dispatched) {
                type = 'success';
                text = 'Se guardó la nueva configuración y se despachó el comando para actualizar el equipo';                    

            } else if (!! response.data?.has_pending) {
                type = 'warning';
                text = 'Se guardó la nueva configuración pero no se despachó el comando para actualizar el equipo ya que hay un comando pendiente. Por favor despache el comando de actualización manaualmente.';                

            } else if (!! response.data?.cmd_unavailable) {
                type = 'danger';
                text = 'Se guardó la nueva configuración pero no existe un comando para actualizar el equipo';

            } else {
                type = 'danger';
                text = 'Ocurrió un error desconocido al guardar la configuración';
            }

            Swal.fire ({
                title: 'Actualización de configuración',
                type: type,
                text: text,
            });

            this.setState ({...this.state, hideConfigForm: true, hideTable: false, formSchema: null});

        } catch (error) {
            console.error('RtuPage::handleSaveConfig', error);
            toast.error('No se pudo guardar la configuración. Por favor intente nuevamente', 'err_cfg');            
        }
    }

    handleCloseConfigForm = () => {
        
        this.setState ({...this.state, hideConfigForm: true, hideTable: false, rawConfigRtu: null, hideJsonConfigForm: true, formSchema: null},
            () => {this.tableRef.current && this.tableRef.current.onQueryChange();});
    }

    handleEditJsonConfig = (element) => {
        element && this.setState({...this.state, rawConfigRtu: element, hideJsonConfigForm: false, hideTable: true});

    }

    handleSaveJsonConfig = async (form) => {
        try {
            const url = API_RTU_UPDATE_RAW_CONFIG.replace('{$id}', form.id);
            const method = 'patch';
            await loggedUserServerFetch (url, method, form);

            Swal.fire ({
                title: 'Actualización de configuración JSON',
                type: 'success',
                text: 'La configuración se actualizó exitosamente. No se ha enviado ningún comando a la RTU',
            });

            this.handleCloseConfigForm();
        } catch (error) {
            console.error('RtuPage::handleSaveJsonConfig', error);
            toast.error('No se pudo guardar la configuración. Por favor intente nuevamente');
            
        }
    }

    handleRename = (element) => {
        let id = element.id;
		let desc = element.mac;
		let value = element.friendly_name;

		Swal.fire({
			title: `Cambiar nombre de la RTU ${desc}`,
			input: 'text',
			inputValue: value,
			showCancelButton: true,
			inputValidator: (value) => {
				if (!value) {
					return 'El nuevo nombre no puede estar vacío';
				}
			}
		}).then ( result => {
			if (result.value)  {

                const url = API_RTU_PATCH.replace('{$id}', id);

                loggedUserServerFetch (url, 'patch', {friendly_name: result.value}).then( response => {
                    this.tableRef.current && this.tableRef.current.onQueryChange();

                }).catch( ( err ) => {         
                    console.error(err);                       
                    toast.error ('No se pudo actualizar el nombre. Reintente nuevamente');
        
                }).then( () => {
        
                })
			}
		});

    }

    //Debug del equipo
    handleOpenDebugDialog = (rtu) => {
        this.setState ( {...this.state, debug_dialog_display: true, debug_dialog_rtu: rtu })
    }

    handleCloseDebugDialog = () => {
        this.setState ( {...this.state, debug_dialog_display: false, debug_dialog_rtu: null })
    }    

    //Historia del equipo
    handleShowHistory = (element) => {
        this.setState ( {...this.state, history_dialog_display: true, history_dialog_rtu: element} );
    }

    handleCloseHistory = () => {
        this.setState ( {...this.state, history_dialog_display: false, history_dialog_rtu: null} );
    }
    
    //Form de ensamblaje
    displayAssembleForm = (element) => {
        this.setState ( { ...this.state, displayAssembleForm: true, assembleFormData: {mac: element?.mac }, hideTable: true })
    }

    hideAssembleForm = () => {
        this.setState ( { ...this.state, displayAssembleForm: false, assembleFormData: null, hideTable: false });
    }

    handleAssemble = async ( form ) => {
        let result = null;
        const url = API_ASSEMBLY_CREATE;
        const method = 'put';

        const successString = `La RTU ${form?.mac} se ensambló correctamente`;

        try {
            result = await loggedUserServerFetch(url, method, form);
            result = result?.data;
            toast.success(successString, 'success_save_item');
            this.hideAssembleForm();

        } catch (error) {
            let msg = this.strings.error.save;
            let error_code = error?.response?.data?.status || null;

            if ( error_code && this.strings.error[error_code] ) msg = this.strings.error[error_code];
            
            console.error(error, error.response.data);
            result = error?.response?.data;
            toast.error(msg, 'handle_save_error');
            throw error;
        }

        return result;
    }

    //Form de tipos de equipo
    displaySetType = (element) => {
        this.setState ( { ...this.state, displaySetType: true, rtu: element, hideTable: true });
        
    }

    hideSetType = () => {
        this.setState ( { ...this.state, displaySetType: false, rtu: null, hideTable: false });
    }

    handleSetType = async (form) => {
        const url = API_RTU_PATCH.replace('{$id}', form.id);
        const method = 'patch';
        let result = null;

        const successString = `Se actualizó la información de la RTU ${form?.mac}`;

        try {
            result = await loggedUserServerFetch(url, method, form);
            result = result?.data;
            toast.success(successString, 'success_save_item');
            this.hideSetType();

        } catch (error) {
            console.error(error, error?.response?.data);
            let msg = this.strings.error.save;
            let error_code = error?.response?.data?.status || null;

            if ( error_code && this.strings.error[error_code] ) msg = this.strings.error[error_code];
            
            result = error?.response?.data;
            toast.error(msg, 'handle_save_error');
            throw error;

        }

        return result;
    }

    handleLabelDownload = async (element) => {
        try {
            const url = (API_RTU_LABEL).replace('{$id}', element.id);
            const response = await loggedUserServerFetch (url, 'get', {}, {}, {}, {responseType: 'blob'});
            FileDownload(response.data, `LABEL-${element.mac}.lbx`);

        } catch (error) {
            toast.error ('Ocurrió un error desconocido al descargar la etiqueta', 'err_label');
            console.error(error);
        }
    }

    handleOpenOtaForm = (rtu) => {
        this.setState ( {...this.state, ota_form_display: true, rtu} );
    }

    handleCloseOtaForm = (success = false) => {
        this.setState ( {...this.state, ota_form_display: false, rtu: null} );
        if (success) this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    handleFilterChange = async (name, value) => {
        let advancedFilter = {...this.state.advanced_filter};

        if (! value) {
            delete advancedFilter[name];
        } else {
            advancedFilter[name] = value;
        }

        await this.setStateAsync ( {...this.state, advanced_filter: advancedFilter});
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }
    
    render = () => {
        const { classes } = this.props;
        const {hideConfigForm, hideJsonConfigForm, rawConfigRtu, displayAssembleForm, displaySetType} = {...this.state};
        return(
            <div className={classes.root}>
                <Box>
                    <Paper>
                        { !! displaySetType && 
                            <PopUp
                                title={`Asignar tipo de equipo a ${this.state?.rtu?.mac||''}`}
                                open={displaySetType}
                                classes={classes}
                                onClose = { () => {} }
                                fullWidth
                            >
                                <RtuParametersForm 
                                    values = { this.state.rtu }
                                    isNew = { true }
                                    onClose = { this.hideSetType }
                                    onCreate = { this.handleSetType }
                                    onUpdate = { () => {} }
                                />
                            </PopUp>
                        }
                        {!! displayAssembleForm && 
                            <PopUp 
                                title="Ensamblar RTU"
                                open={displayAssembleForm}
                                classes={classes}
                                onClose = { () => {} }                                
                                fullWidth
                            >
                                <AssemblyForm 
                                    values = { this.state.assembleFormData }
                                    isNew = { true }
                                    onClose = { this.hideAssembleForm }
                                    onCreate = { this.handleAssemble }
                                    onUpdate = { () => {} }
                                /> 
                            </PopUp>
                        }
                        {!hideJsonConfigForm &&
                            <RtuRawConfigForm
                                rtu = {rawConfigRtu}
                                onSubmit = {this.handleSaveJsonConfig}
                                onCancel = {this.handleCloseConfigForm}
                            />
                        }
                        {
                            !hideConfigForm &&
                            <RtuConfigForm 
                                open = { ! this.state.hideConfigForm }
                                readOnly = { this.state.readonlyConfigForm }
                                rtu = { this.state.rtu }
                                form = { this.state.formSchema }
                                onSave = { this.handleSaveConfig }
                                onClose = { this.handleCloseConfigForm }
                            />
                        }
                        <RtuForm 
                            hidden={this.state.hideForm}
                            onSubmit={this.handleSubmit}
                            onCancel={this.handleCancel}
                            rtu_types={this.state.rtu_types}
                        />
                        {(this.state.search_mode === SEARCH_MODE.FULL) && <RtuAdvancedSearch onChange={this.handleFilterChange}/> }
                        <RtuTable
                            dataSource = { query => this.fetchElements( query ) }
                            tableRef = { this.tableRef }
                            isHidden = { this.state.hideTable }
                            autoRefresh
                            updateInterval = { 5000 }
                            uploadRtuHandler = { this.handleCreate }
                            downloadRtusHandler = { this.handleDownloadList }
                            commands = { this.state.commands }
                            displaySearch = { this.state.search_mode === SEARCH_MODE.SIMPLE }

                            onViewState = { this.handleViewState }
                            onSendCommand = { this.handleSendCommand }
                            onDownloadFat = { this.handleFatDownload }
                            onEditConfig = { this.handleEditConfig }
                            onRename = { this.handleRename }
                            onDebug = { this.handleOpenDebugDialog }
                            onShowHistory = { this.handleShowHistory }
                            onEditJsonConfig = { this.handleEditJsonConfig }
                            onAssemble = { this.displayAssembleForm }
                            onSetType = { this.displaySetType }
                            onLabelDownload = { this.handleLabelDownload }
                            handleDisplayOtaForm = { this.handleOpenOtaForm}
                            onToggleSearch = { () => {
                                const currentMode = this.state.search_mode;
                                const newMode = (currentMode === SEARCH_MODE.SIMPLE) ? SEARCH_MODE.FULL : SEARCH_MODE.SIMPLE;
                                this.setState({...this.state, search_mode: newMode});
                            }}
                        />
                    </Paper>
                    <StateDialog
                        open = { !! this.state.state_display}
                        title = { `Estado de ${this.state?.state_display?.id} - ${this.state?.state_display?.time||'Sin timestamp'}` }
                        state = { this.state.state_display || {} } 
                        name = { this.state.state_display?.id || ''}
                        onCloseClick = { this.handleCloseViewState }
                    />
                    { !! this.state.debug_dialog_display &&
                        <RtuDebugDialog
                            open = { true }
                            rtu = { this.state.debug_dialog_rtu }
                            onClose = { this.handleCloseDebugDialog}
                        />
                    }
                    { !! this.state.history_dialog_display &&
                        <RtuHistory
                            rtu = { this.state.history_dialog_rtu }
                            history = {[]}
                            allowAddNotes = {true}
                            onClose = { this.handleCloseHistory}
                        />
                    }
                    <OtaPopup
                        open = { this.state.ota_form_display }
                        onClose = {this.handleCloseOtaForm}
                        rtu = { this.state.rtu }
                    />
                </Box>
            </div>
        );
    }
}

export default withStyles(pageStyle)(RtusPage);