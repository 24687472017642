import React from 'react'
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SelectInput = (props) => {
    const {onChange, name, label, options = [], getLabel, getId, groupBy = null, ...other} = props;    

    return (        
        <FormControl fullWidth>
            <Autocomplete
                key = {`selector_${name}`}
                clearText="Borrar campo"
                id = {name}
                options = {options}
                getOptionLabel = { getLabel }
                groupBy = { groupBy }
                fullWidth
                onChange = { (event, value) => onChange ( name, getId (value), value ) }
                {...other}
                renderInput={(params) => <TextField {...params} label={label} />}
            />
        </FormControl>
    )
}

SelectInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    getId: PropTypes.func.isRequired,
    getLabel: PropTypes.func.isRequired,
    groupBy: PropTypes.func,
}

export default SelectInput;
