import React from 'react'
import { Switch as SwitchControl, FormControlLabel, FormGroup, Box } from '@material-ui/core';

const Switch = ({onChange, value, ...props}) => {
    return (
        <Box pt={3}>
            <FormGroup row>
                <FormControlLabel
                    label={props.label || ''}
                    control={
                        <SwitchControl
                        key={`field_inner_${props.name}`}
                        name={props.name}
                        checked={ !! value }
                        onChange={ (ev) => onChange(ev.target.name, ev.target.checked) }
                        color={props.color || 'primary'}
                        required={!! props.required}
                        disabled={!! props.disabled}
                        //{...props}
                        />
                    }
                />
            </FormGroup>
        </Box>
    )
}

export default Switch;