import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useGoogleMap } from '@react-google-maps/api';

export const POSITION_BOTTOM = 11;
export const POSITION_BOTTOM_CENTER = 11;
export const POSITION_BOTTOM_LEFT = 10;
export const POSITION_BOTTOM_RIGHT = 12;
export const POSITION_CENTER = 13;
export const POSITION_LEFT = 5;
export const POSITION_LEFT_BOTTOM = 6;
export const POSITION_LEFT_CENTER = 4;
export const POSITION_LEFT_TOP = 5;
export const POSITION_RIGHT = 7;
export const POSITION_RIGHT_BOTTOM = 9;
export const POSITION_RIGHT_CENTER = 8;
export const POSITION_RIGHT_TOP = 7;
export const POSITION_TOP = 2;
export const POSITION_TOP_CENTER = 2;
export const POSITION_TOP_LEFT = 1;
export const POSITION_TOP_RIGHT = 3;



export default function MapControl({ position, children }) {
  const map = useGoogleMap();

  const [container] = useState(document.createElement('div'));

  React.useEffect(() => {
    if (map) {
      map.controls[position].push(container);
    }
  }, [map]);

  return createPortal(children, container);
}

MapControl.propTypes = {
  // https://developers.google.com/maps/documentation/javascript/controls?hl=uk#ControlPositioning
  position: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
