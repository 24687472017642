import React from 'react'
import dayjs from 'dayjs';
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';
import AbstractAbmPage from './AbstractAbmPage.js';
import { API_USER_LIST, API_USER_DELETE, API_USER_ACTIVATE, API_USER, API_USER_CREATE, API_USER_EDIT } from '../constants/api';
import ActiveIcon from '../components/icondisplay/ActiveIcon.js';
import UserForm from '../components/forms/UserForm';

class UsersPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = UserForm;
        this.strings = {
            createItem: 'Crear nueva cuenta de usuario',
            editItem: 'Editar cuenta de usuario',
            itemCreationSuccess: 'Cuenta creada exitosamente',
            itemUpdateSuccess: 'Cuenta actualizada exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra la cuenta de usuario seleccionado',
                save: 'Ocurrió un error al guardar la cuenta de usuario. Intente nuevamente.',

                E01: 'No se especificó el nombre de usuario',
                E02: 'No se especificó el e-mail',
                E03: 'El nombre de usuario ya se encuentra en uso',
                E04: 'El e-mail ya se encuentra en uso',
                E05: 'No se encontró id de usuario',
                E06: 'La contraseña es demasiado corta',
                
            }
        }
        
        this.urls = {
            list: API_USER_LIST,
            item: API_USER,
            toggle: API_USER_ACTIVATE,
            delete: API_USER_DELETE,
            create: API_USER_CREATE,
            update: API_USER_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'name', field: 'name', title: 'Nombre', sorting: true, },
                {id: 'email', field: 'email', title: 'E-mail', sorting: true, },
                {id: 'username', field: 'username', title: 'Usuario', sorting: true, },
                {id: 'type', field: 'type.description', title: 'Tipo', sorting: true, },
                {id: 'customer', field: 'instance.description', title: 'Cliente', sorting: true},
                {id: 'created_at', field: 'created_at', title: 'Creado', sorting: true, render: (element) => dayjs(element.created_at).format('DD/MM/YYYY HH:mm')},
                {id: 'last_login_at', field: 'last_login_at', title: 'Último login', sorting: true, render: (element) => element.last_login_at && dayjs(element.last_login_at).format('DD/MM/YYYY HH:mm')},
                {id: 'status', field: 'status', title: 'Estado', sorting: false, render: (e) => <ActiveIcon active={!!e.active} activeLabel="Activo" inactiveLabel="Inactivo"/>},
            ],

            actions:[
                {
                    icon: 'person_add',
                    tooltip: 'Nuevo usuario',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar cuenta':'Activar cuenta',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => ({
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: () => { this.handleEdit(element.id) }
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar el firmware ${element.description}? Se cancelarán todos los OTA pendientes`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(UsersPage));


