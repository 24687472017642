import React from 'react';
import { Paper, withStyles, Grid, TextField, Button} from '@material-ui/core';
import { PASSWORD_RECOVER_URL } from '../../constants/url';

import logo from '../../img/logo.png';

const styles = theme => ({
    margin: {
        margin: theme.spacing(2)
    },
    padding: {
        padding: theme.spacing(1)
    }
});

class LoginForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            user: '',
            pass: '',
        }
        
        this.onComponentChange = this.onComponentChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onComponentChange(evt) {
        const { name, value } = evt.target;
        this.setState({[name]: value});
    }

    onSubmit(evt) {
        evt.preventDefault();
        this.props.onLogin(this.state.user, this.state.pass);
    }

    render() {
        const { classes, loading } = this.props;
        return (
            <Paper className={classes.padding}>
                <form method="post" onSubmit={this.onSubmit}>
                    <div className={classes.margin}>
                        <img src={logo} alt='logo' style={ {width: '100%'} }></img>
                        <Grid container spacing={8} alignItems="flex-end" style={{ paddingTop: 30 }}>
                            <Grid item md={true} sm={true} xs={true}>
                                <TextField 
                                    id="username" 
                                    label="Nombre de usuario" 
                                    type="text" 
                                    autoComplete="username"
                                    fullWidth 
                                    autoFocus 
                                    required 
                                    name="user"
                                    value={this.state.user}
                                    onChange={this.onComponentChange}    
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8} alignItems="flex-end">
                            <Grid item md={true} sm={true} xs={true}>
                                <TextField 
                                    id="password" 
                                    label="Contraseña" 
                                    type="password" 
                                    autoComplete="current-password"
                                    fullWidth 
                                    required 
                                    name="pass"
                                    value={this.state.passr}
                                    onChange={this.onComponentChange}    
                                />
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" style={{ paddingTop: 30 }}>
                            <Button 
                                variant="contained" 
                                color="primary"
                                type="submit" 
                                fullWidth
                                disabled={loading}
                                style={{ textTransform: "none" }}>Iniciar sesión</Button>
                        </Grid>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Button 
                                    disableFocusRipple
                                    disableRipple style={{ textTransform: "none" }} 
                                    variant="text" 
                                    href={PASSWORD_RECOVER_URL}
                                    color="primary">Olvidé mi contraseña</Button>
                            </Grid>
                        </Grid>

                        <Grid container alignItems="center" justifyContent="center" style={{ paddingTop: 30 }}>
                            <Grid item>
                                <div>
                                    &copy; 2022 <a href="https://greenlabstech.com" target="_blank" rel="noopener noreferrer">greenlabstech.com</a>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Paper>
        );
    }
}

export default withStyles(styles)(LoginForm);
