import loggedUserServerFetch from '../services/loggedUserServerFetch';
import FileDownload from 'js-file-download';

const filedownloader = async (url, filename) => {
    try {
        const response = await loggedUserServerFetch (url, 'get', {}, {}, {}, {responseType: 'blob'});
        FileDownload(response.data, filename);        
    } catch (error) {
        console.error (`filedownloader: Error descargando archivo ${filename} de ${url}`, error);
        throw error;
    }
}

export default filedownloader;