import React from 'react'
import { Button } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import PropTypes from 'prop-types';
import WithOnClick from './withOnClick';

const FormSubmitButton = (props) => {
    const { title, color, onClick = null, label, enabled=true } = props;
    const isSubmit = (onClick === null);
    const buttonType = isSubmit ? 'submit' : 'button';
    
    let button = <Button
        disabled = {!enabled}
        type={buttonType}
        variant="contained" 
        color={color || "primary"}
        title={title || 'Guardar'}
        startIcon={<Save />}
    >{label||'Guardar'}</Button>

    if (! isSubmit) button = WithOnClick(onClick)(button);

    return ( button );
}

FormSubmitButton.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string
}

export default FormSubmitButton;