import dayjs from 'dayjs';
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import { pageStyle } from "../styles/styles.js";
import { getUpdateInterval } from '../redux/reducers/ui';
import AbstractAbmPage from './AbstractAbmPage.js';
import { API_APIKEY_LIST, API_APIKEY, API_APIKEY_CREATE, API_APIKEY_DELETE, API_SERVER } from '../constants/api';
import ApiKeyForm from '../components/forms/ApiKeyForm';
import copyToClipboard from '../helpers/copyToClipboard';

class ApiKeyPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = ApiKeyForm;
        this.strings = {
            createItem: 'Nueva clave de API',
            editItem: '',
            itemCreationSuccess: 'Clave de API creada exitosamente',
            itemUpdateSuccess: '',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra la clave de API seleccionada',
                save: 'Ocurrió un error al guardar la clave de API. Intente nuevamente.',
                
                
                
                
                
                E01: 'Debe seleccionar al menos un tipo de RTU',
                E02: 'Ya existe un firmware con ese nombre de archivo',
                E03: 'No se encuentra el formulario de configuración seleccionado',
                E04: 'El CRC32 del archivo es incorrecto',
                E05: 'Ocurrió un error al guardar el archivo de firmware en el servidor',
            }
        }
        
        this.urls = {
            list: API_APIKEY_LIST,
            item: API_APIKEY,
            toggle: null,
            delete: API_APIKEY_DELETE,
            create: API_APIKEY_CREATE,
            update: null,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'description', field: 'description', title: 'Nombre', sorting: true, },
                {id: 'customer', field: 'customer', title: 'Cliente', sorting: true, render: (e) => e?.customer?.description},
                {id: 'user', field: 'user', title: 'Usuario', sorting: true, render : (e) => e?.user.name},
                {id: 'created_at', field: 'created_at', title: 'Creación', sorting: true, render: (element) => dayjs(element.created_at).format('DD/MM/YYYY HH:mm')},
                {id: 'last_access_at', field: 'last_access_at', title: 'Último uso', sorting: true, render: (element) =>element.last_access_at && dayjs(element.last_access_at).format('DD/MM/YYYY HH:mm')},
                {id: 'last_access_from', field: 'last_access_from', title: 'Última IP', sorting: true},    
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nueva clave de API',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                {
                    icon: 'help',
                    tooltip: 'Ayuda',
                    isFreeAction: true,
                    onClick: () => { this.showHelp(); },
                },
                (element) => ({
                    icon: 'file_copy',
                    tooltip: 'Copiar token al portapapeles',
                    onClick: () => { this.handleViewToken(element); },
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar la clave de API ${element.description}?`) }
                }),
            ]
        };    
    }

    showHelp = () => {
        const header = 'x-auth';
        const url = API_SERVER;

        Swal.fire({
            type: 'info',
            title: 'Ayuda',
            html: `<ul align="left"><li>La URL de la API es <strong style="white-space: nowrap;">${url}</strong></li><li>El token debe ser enviado en el encabezado <strong>${header}</strong></li></ul>`,
        })
    }

    handleViewToken = (element) => {
        copyToClipboard(element.token).then ( () => {
            toast.success('Token copiado al portapapeles', 'token_copy_ok');

        }).catch( (error) => {
            console.error (error);
            toast.error('Ocurrió un error al copiar el token al portapapeles', 'token_copy_error');
        })
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(ApiKeyPage));


