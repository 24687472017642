//import SimpleForm from './SimpleForm';
import AbstractForm from './AbstractForm'

const TriggerForm = (props) => {
    const schema = {
        fields: [
            {
                key: 'id',
                name: 'id',
                type: 'hidden',
                label: '',
                required: false,

            },
            {
                key: 'description',
                name: 'description',
                type: 'textarea',
                label: 'Descripción',
                required: true,
                disabled: false,
                autoFocus: true,
            },
            {
                key: 'field',
                name: 'field',
                type: 'textarea',
                label: 'Campo (Puede indicar campos directos como uptime o anidados como power.vext',
                requiredIf: {
                    match: 'all',
                    rules: [
                        { field: 'operator', value:'device_is_online', operator: '!=' },
                        { field: 'operator', value:'device_is_offline', operator: '!=' },
                    ]
                },
            },
            {
                key: 'operator',
                name: 'operator',
                type: 'select',
                label: 'Operador',
                required: true,
                options: {'==': '==','>': '>', '>=':'>=', '<':'<', '<=': '<=', '!=': '!=', 'in': 'in','device_is_online': 'Online', 'device_is_offline': 'Offline'},
            },
            {
                key: 'value',
                name: 'value',
                type: 'textarea',
                label: 'Valor',                
                requiredIf: {
                    match: 'all',
                    rules: [
                        { field: 'operator', value:'device_is_online', operator: '!=' },
                        { field: 'operator', value:'device_is_offline', operator: '!=' },
                    ]
                },
            },
            {
                key: 'active',
                name: 'active',
                type: 'switch',
                label: 'Activo',
            }

        ]
    }
    
    return (<AbstractForm
        schema = { schema }
        {...props}
    />)
}
export default TriggerForm;