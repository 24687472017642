import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_RTU_PARAMETERS_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';

class RtuParametersForm extends Component {
    constructor (props) {
        super(props);
        
        this.state = {
            type: [],
            loading: true,
        };        
    }

    componentDidMount = () => {
        this.populateForm();
    }

    populateForm = () => {
        loggedUserServerFetch (API_RTU_PARAMETERS_FORM, 'get', {}, {}, {}).then ( (response) => {
            const type = response?.data?.types || [];

            this.setState({...this.state, type, loading: false});

        }).catch ( (error) => {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de OTA');
            this.props.onClose();
        })

    }

    buildSchema = (values) => {      
        let rtuTypes = {};
        this.state.type.forEach ( element => {
            rtuTypes[element.id] = element.description;
        });
              
        const schema =  {
            fields: [            
                {
                    key: 'id',
                    name: 'id',
                    type: 'hidden',
                    label: '',
                    required: true,
                    disabled: false,
                    autoFocus: false,
                },
                {
                    key:'friendly_name',
                    name: 'friendly_name',
                    type: 'text',
                    label: 'Nombre',
                    required: false,
                    disabled: false,
                    autoFocus: true,
                },
                {
                    key: 'rtu_type_id',
                    name: 'rtu_type_id',
                    type: 'select',
                    label: 'Tipo de equipo',
                    required: true,
                    disabled: false,
                    options: rtuTypes,
                },
            ]
        };
        return schema;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        if ( this.state.loading ) return this.renderLoading();
        const schema = this.buildSchema;
        let {values, ...otherProps} = this.props;
        values.rtu_type_id = values.rtu_type?.id;

        return (
            <AbstractForm 
                schema={schema} 
                values = {values}
                {...otherProps}
            />)
    }
}

export default RtuParametersForm;