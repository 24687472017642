import React from 'react'
import PropTypes from "prop-types";
import Input from './input';
import Select from './select';
import DinamycTable from '../../DinamycTable';

const typeOptions = {B: 'B', H: 'H', s: 's', I:'I'};
const dataTypeOptions = {uint8_t: 'uint8_t', uint16_t: 'uint16_t', int8_t: 'int8_t', uint32_t: 'uint32_t'};
const printTypeOptions = {L: 'L', I: 'I', S: 'S', F: 'F'};
const orientationTypeOptions = { V: 'Vertical', H: 'Horizontal'}
//const dataTypeOffsets = {uint8_t: 1, uint16_t: 2, int8_t: 1, uint32_t: 4};

const renderCellContent2 = (column, index, values, onChange) => {
    let result = null;
    
    switch (column) {
        case 'name':
            result = <Input key={`${column}_${index}`} type="text" name={column} value={values[column]} required onChange={(n,v) => onChange(v)} />
            break;
    
        case 'elems':
            result = <Input key={`${column}_${index}`} type="number" name={column} value={values[column]} required inputProps={{min:1}} onChange={(n,v) => onChange(v)} />
            break;

        case 'type':
            result = <Select key={`${column}_${index}`} name={column} value={values[column]} options={typeOptions} required onChange={(n,v) => onChange(v)} />
            break;

        case 'dataType':
            result = <Select key={`${column}_${index}`} name={column} value={values[column]} options={dataTypeOptions} required onChange={(n,v) => onChange(v)} />
            break;

        case 'offset': 
            result = <Input key={`${column}_${index}`} type="number" name={column} value={values[column]} inputProps={{min:0}} required onChange={(n,v) => onChange(v)} />
            break;

        case 'printType':
            result = <Select key={`${column}_${index}`} name={column} value={values[column]} options={printTypeOptions} required onChange={(n,v) => onChange(v)} />
            break;

        case 'printFormat':
            result = <Input key={`${column}_${index}`} type="text" name={column} value={values[column]} onChange={(n,v) => onChange(v)} />
            break;

        case 'description':
            result = <Input key={`${column}_${index}`} type="text" name={column} value={values[column]} onChange={(n,v) => onChange(v)} />
            break;

        case 'group':
            result = <Input key={`${column}_${index}`} type="text" name={column} value={values[column]} onChange={(n,v) => onChange(v)} />
            break;

        case 'orientation':
            let value = (values[column] === 'H')?'H':'V';
            result = <Select key={`${column}_${index}`} name={column} value={value} options={orientationTypeOptions} required onChange={(n,v) => onChange(v)} />
            break;

                
        default:
            break;
    }
    return result;
}

const renderCellContent = (column, index, values, onChange) => {
    let result = null;
    
    switch (column) {
        case 'name':
        case 'elems':
        case 'type':
        case 'offset': 
        case 'printType':
        case 'description':
        case 'group':
            result = values[column];
            break;

        case 'printFormat':
            result = String(values[column]||'').substr(0,20);
            break;

        case 'dataType':
            result = dataTypeOptions[values[column]];
            break;

        case 'orientation':
            const ori = (values[column]).toUpperCase();
            result = ['H', 'HORIZONTAL', 'HORIZ'].includes(ori) ? 'Horizontal' : 'Vertical';
            break;

        default:
            break;
    }
    return result;
}

/*
const calculateOffset = (items, index = null) => {
    if ((! items.length) || (index === 0)) return 0;
    if (index === null) index = items.length -1;

    const lastIndex = index - 1;
    const {dataType, offset, elems} = {...items[lastIndex]};
    const dataLength = dataTypeOffsets[dataType] ;
    return offset + elems * dataLength;
}
*/
const newRowTemplate = (items) => {
    return {name: '', elems: 0, type: '', dataType:'', printType: '', printFormat: '', description: '', group: '', orientation: 'V'};
    //let newOffset = calculateOffset(items);
    //return {name: '', elems: 0, type: '', dataType:'', offset: newOffset, printType: '', printFormat: ''};
}

const SetupDecoderTemplate = (props) => {
    return <DinamycTable
    values={props.value||[]}
    newRowTemplate={newRowTemplate}
    columns={ [
        {id: 'name', 'label':'Nombre'}, 
        {id: 'elems', label:'Elementos'}, 
        {id: 'type', label: 'Tipo'}, 
        {id: 'dataType', label: 'Tipo de dato'}, 
        {id: 'offset', label: 'Offset', display: false},
        {id: 'printType', label: 'Tipo de impresión'}, 
        {id: 'printFormat', label: 'Formato de impresión'}, 
        {id: 'description', label: 'Descripción'},
        {id: 'group', label: 'Grupo'},
        {id: 'orientation', label: 'Orientación'},
        
    ]}
    renderCellContent={renderCellContent}
    name={props.name}
    onChange={props.onChange}
    enableImport
/>
}

SetupDecoderTemplate.propTypes = {
    value: PropTypes.array,    //Valores de la tabla
    name: PropTypes.string.isRequired, //Nombre del componente
    onChange: PropTypes.func.isRequired, //Función (field, value) para actualizar form
}

export default SetupDecoderTemplate;