import AbstractForm from './AbstractForm'

const schema = {
    title: '',
    fields: [
        {
            name: 'id',
            type: 'hidden',
            label: 'Id',
        },
        {
            name: 'description',
            type: 'text',
            label: 'Nombre',
            required: true,
            disabled: false,
            autoFocus: true,
        },
        {
            name: 'url',
            type: 'url',
            label: 'Url',
            required: true,
            disabled: false,
        },
        {
            name: 'outgoing_api_key',
            type: 'text',
            label: 'Clave de API para comunicarse hacia TLS',
            required: true,
            disabled: false,
        },
        {
            name: 'active',
            type: 'switch',
            label: 'Instancia activa',
            checked: true,
            disabled: false,
        },

    ]
}


const InstanceForm = (props) => {
    return (
        <AbstractForm
            schema={schema}
            {...props}
        />
    )
}

export default InstanceForm;