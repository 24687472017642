import loggedUserServerFetch from '../services/loggedUserServerFetch';
import { SERVER, API_ICON_LIST } from '../constants/api';

export const getImageDataUrl = (url)  => {
    return new Promise((resolve, reject) => {
        fetch(SERVER + url)
        .then(response => response.blob())
        .then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const dataUrl = reader.result;
                resolve(dataUrl);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        })
        .catch(reject);
    });
  }

export const loadIcons = async () => {
    try {
        const response = await loggedUserServerFetch(API_ICON_LIST);
        const icons = response.data; 

        const promises = [];

        icons.forEach((icon) => {            
            promises.push(getImageDataUrl(icon.url));
        })

        const iconData = await Promise.all(promises);
        const iconsWithNameAndImg = icons.map((icon, index) => ({
            name: icon.filename,
            data: iconData[index]
        }));

        return iconsWithNameAndImg;
    } catch (error) {
        console.error('Failed to load icons:', error);
        throw error;
    }
}
