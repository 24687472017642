import React, { Component } from 'react'

//Material design
import { Grid } from '@material-ui/core';

import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { PropTypes } from 'prop-types';

import background from '../img/login-background.jpg';

import { setUserLogon, logoutUser } from '../redux/actions/index';
import { doLogin } from '../services/auth';
import LoginForm from '../components/forms/LoginForm';

import BackgroundImage from '../components/BackgroundImage';

class LoginPage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            submitting: false
        }
        
        this.doLogin = this.doLogin.bind(this);
    }

    doLogin(user, pass) {
        this.setState({submitting: true});
        doLogin(user, pass)
        .then( (response) => {
            this.props.setUserLogon(response.data);
            this.props.history.push(this.props.success_redirect);

        }).catch( ( err ) => {
            toast.error('Nombre usuario o contraseña incorrectos');
            this.props.logoutUser();

        }).then( () => {
            this.setState({submitting: false});
        })
    }

    onComponentChange(evt) {
        const { name, value } = evt.target;
        this.setState({[name]: value});
    }

    render () {
        const { submitting } = this.state;
        return (
            <div style={{paddingTop: 64}}>
                <BackgroundImage src={background} />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} md={4}>
                        <LoginForm 
                            loading={submitting}
                            onLogin={this.doLogin}
                        />
                    </Grid>
                </Grid> 
            </div>
        );
    }
}

LoginPage.propTypes = {
    success_redirect: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    //...state
});

const mapDispatchToProps = dispatch => ({
    setUserLogon: value => dispatch(setUserLogon(value)),
    logoutUser: () => dispatch(logoutUser()),
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);