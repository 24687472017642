import React from 'react'
import PropTypes from 'prop-types';
import { FormControl, TextField } from '@material-ui/core';

const DateInput = (props) => {
    const {onChange, name, label, ...other} = props;

    return (
        <FormControl fullWidth>
            <TextField
                InputLabelProps={{ shrink: true }}
                key={`field_${name}`}
                type="date"
                label={label}
                name={name}
                onChange={ (event) => onChange(name, event.target.value) }
                {...other}
                fullWidth
            />
        </FormControl>
    )
}

DateInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default DateInput;
