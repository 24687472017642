import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import { Container, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { pageStyle } from '../styles';
import { toast } from 'react-toastify';
import FormButtons from '../form-builder/FormButtons';
import useWindowDimensions from '../useWindowDimension';

const RtuRawConfigForm = (props) =>
{

    const { height } = useWindowDimensions();
    const editorHeight = Math.max (height - 263, 300);
    const {rtu, onSubmit, onCancel, classes} = {...props};
    const [json, setJson] = useState(rtu?.config?.value||{});
    const [jsonChanged, setJsonChanged] = useState(false);

    const handleBack = () => {
        if (jsonChanged) {
            Swal.fire ({
                title: 'La configuración tiene cambios',
                text: '¿Desea salir sin guardar?',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sí, salir',
                cancelButtonText: 'Quedarme aquí',
                customClass: {
                    container: 'always-on-top',
                }

            }).then( (result) => {
                result.value && onCancel();
            });

        } else {
            onCancel();
        }        
    }

    const handleChange = (val) => {
        setJson (val);
        setJsonChanged(true);
    }

    const handleSubmit = () => {        
        try {                        
            onSubmit( {id: rtu.id, config: json});
    
        } catch (error) {
            console.error('Error parseando la configuración', json, error);
            toast.error('La cadena ingresada no es un JSON válido');
        }        
    }
    
    return <React.Fragment>
            <Container>
                <Grid item xs={12}>
                    <Typography 
                        variant="h6" 
                        className={classes.title}
                        style={{paddingTop: 20}} 
                    >Editar configuración JSON para la RTU {rtu.mac}</Typography>
                </Grid>
                <Grid item xs={12}>                
                    <Editor                        
                        name={`Configuracion de ${rtu.mac}`}
                        value={json}
                        mode="code"
                        onChange={ value => handleChange (value) }
                        htmlElementProps={{style: {height: editorHeight}}}
                    />
                </Grid>
                <FormButtons 
                    displaySaveDraftButton = { false }
                    onBack = {handleBack}
                    onSubmit = {handleSubmit}
                />
            </Container>
        </React.Fragment>
}

RtuRawConfigForm.propTypes = {
    rtu: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default withStyles(pageStyle)(RtuRawConfigForm);