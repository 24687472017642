import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Box, Grid, Paper } from '@material-ui/core';
import DateInput from './DateInput';
import SelectInput from './SelectInput';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import { API_RTU_FILTER } from '../../constants/api';


/*
Fecha de instalacion desde (Fecha)
Fecha de instalacion hasta (Fecha)
Cliente (Select)
Instalación (Fabricadas y por instalación) (Select)
Tipo de instalación (Select)

Tipo de hardware (Select)
Firmware (Select)
Estado (Select)
onLine (Select)
Alimentación (Select)
*/

const installationNotInstalled = {    
    id: -1,
    description: '-Fabricadas-',
    customer_id: -1,
    customer: ''
}

const customerNoCustomer = {
    id: -1,
    description: '-Sin cliente-',
}

const onlines = [ {id: 1, description: 'Online'}, {id: 2, description: 'Offline'} ];
const powers = [ {id: 1, description: 'Batería'}, {id: 2, description: 'Suministro eléctrico'} ]

const RtuAdvancedSearch = (props) => {

    const [ installations, setInstallations ] = useState([]);
    const [ customers, setCustomers ] = useState ([]);
    const [ installationTypes, setInstallationTypes ] = useState ([]);
    const [ hardwareTypes, setHardwareTypes ] = useState ([]);
    const [ firmwares, setFirmwares ] = useState ([]);
    const [ states, setStates ] = useState ([]);

    const {onChange} = props;

    useEffect ( () => {
        const fetchData = async () => {
            try {       
                const {data} = await loggedUserServerFetch ( API_RTU_FILTER , 'get' );

                const installations = [ installationNotInstalled,  ...(data.installations || []) ];
                setInstallations ( installations );

                const customers = [customerNoCustomer, ...(data.customers || []) ];
                setCustomers ( customers );

                const installationTypes = ( data.installation_types || []).map ( e => ( {id: e, description: e} ) ) ;
                setInstallationTypes (installationTypes);

                const hardwareTypes = data.hardware_types || [];
                setHardwareTypes ( hardwareTypes );

                const firmwares = data.firmwares || [];
                setFirmwares(firmwares);

                const states = data.states || [];
                setStates (states);

            } catch (error) {
                console.error(error);
            }
        }
        fetchData ();

    }, []);

    return (
        <Box>
            <Paper>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    style={{padding: 10}}
                >
                    <Grid item xs={12} md={2}>
                        <DateInput
                            name="from_date"
                            label="Fecha de instalación desde"
                            onChange={ onChange }
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <DateInput
                            name="to_date"
                            label="Fecha de instalación hasta"
                            onChange={ onChange }
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SelectInput
                            onChange = { onChange }
                            label = "Cliente"
                            name = "customer"
                            options = { customers }
                            getLabel = { e => e.description }
                            getId = { e => e?.id||null }                            
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SelectInput
                            onChange = { onChange }
                            label = "Instalación"
                            name = "installation"
                            options = { installations }
                            getLabel = { e => e.description }
                            getId = { e => e?.id||null }
                            groupBy = { e => e.customer || null }
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SelectInput
                            onChange = { onChange }
                            label = "Tipo de Instalación"
                            name = "installation_type"
                            options = { installationTypes }
                            getLabel = { e => e.description }
                            getId = { e => e?.id||null }
                            loading = {true}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    style={{padding: 10}}
                >           

                    <Grid item xs={12} md={2}>
                        <SelectInput
                            onChange = { onChange }
                            label = "Tipo de hardware"
                            name = "hardware_type"
                            options = { hardwareTypes }
                            getLabel = { e => e.description }
                            getId = { e => e?.id||null }
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SelectInput
                            onChange = { onChange }
                            label = "Firmware"
                            name = "firmware"
                            options = { firmwares }
                            getLabel = { e => (`${e.version} - ${e.description}`) }
                            getId = { e => e?.id||null }
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SelectInput
                            onChange = { onChange }
                            label = "Estado"
                            name = "state"
                            options = { states }
                            getLabel = { e => e.description }
                            getId = { e => e?.id||null }
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SelectInput
                            onChange = { onChange }
                            label = "Conexíon"
                            name = "online"
                            options = { onlines }
                            getLabel = { e => e.description }
                            getId = { e => e?.id||null }
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SelectInput
                            onChange = { onChange }
                            label = "Alimentación"
                            name = "power"
                            options = { powers }
                            getLabel = { e => e.description }
                            getId = { e => e?.id||null }
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

RtuAdvancedSearch.propTypes = {
    onChange: PropTypes.func.isRequired,
}

export default RtuAdvancedSearch;