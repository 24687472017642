/*import React, { Component } from 'react'
import AbstractForm from './AbstractForm';

class ApiKeyForm extends Component {

    buildFormSchema = (form) => {
    }

    render = () => {
        return (
            <AbstractForm
                schema={this.buildFormSchema}
                {...this.props}
            />
        );
    }
}

export default ApiKeyForm;


*/
import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_APIKEY_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';
import { getUserPermission } from '../../redux/reducers/users';
import { store } from '../../redux/store';
import { USER_IS_ROOT } from '../../constants/profiles';

class FirmwareForm extends Component {
    constructor (props) {
        super(props);
                
        this.state = {
            loading: true,
        };


        const state = store?.getState();
        const permission = getUserPermission(state);
        this.userIsRoot = permission.includes(USER_IS_ROOT);
    }

    componentDidMount = async () => {
        try {
            const response = await loggedUserServerFetch (API_APIKEY_FORM, 'get', {}, {}, {});
            await new Promise((resolve) => this.setState({...this.state, formData: response?.data||{}, loading: false}, resolve) );

        } catch (error) {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de claves de API');
            this.props.onClose();
        }
    }

    buildSchema = (form) => {
        const {users = []} = this.state?.formData;

        const fields = [];
        fields.push(
            {
                key: 'description',
                name: 'description',
                type: 'textarea',
                label: 'Descripción',
                required: true,
                disabled: false,
                autoFocus: true,
            },
        );

        if (this.userIsRoot) {
            fields.push(
                {
                    name: 'owned',
                    type: 'switch',
                    label: (!!form.owned)?'Esta api key es propia':'Esta api key pertenece a otro usuario',
                    checked: true,
                    disabled: false,
                },
            );

            const hiddenRule = {
                match: 'any',
                rules: [
                    { field: 'owned', value:[true], operator: 'contains' },
                ]
            };

            let userOptions = {};
            users.forEach ( element => {
                let label = element.name;
                label += ` - Perfil de usuario: ${element.role.description}`;
                if (element.instance) label += ` - Cliente: ${element.instance.description}`;

                userOptions[element.id] = label;
            });
            fields.push(
                {
                    name: 'user',
                    type: 'select',
                    label: 'Usuario',
                    required: true,
                    disabled: false,
                    options: userOptions,
                    hiddenIf: hiddenRule,
                }
            );

        }

        const schema = { fields: fields };

        return schema;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema;
        if (this.state.loading) return this.renderLoading();
        return (
            <AbstractForm 
                schema={schema} 
                {...this.props}
            />
        )
    }
}

export default FirmwareForm;