import React from 'react';
import ReactJson from 'react-json-view';

const RtuState = (props) => {
    const {state, name} = props;
    return (
        <ReactJson
            src = { state }
            name = { name || ''}
            iconStyle = 'triangle'
            collapsed = { 1 }
            displayDataTypes = { false }
            enableClipboard = { false }
        />)
}

export default RtuState;