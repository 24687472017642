import { TextField } from "@material-ui/core";


const InputText = (props) => {
    const {description, name, value, elems, readonly = false} = props;

    return (
        <TextField
            id = {`setup_component_${name}`} 
            label = {description} 
            name = {name} 
            defaultValue = {value}
            readonly = {readonly}
            inputProps={ {maxLength: elems} }
            fullWidth
        />
    )
}

export default InputText;