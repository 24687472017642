import React, { Component } from 'react'

import { withStyles, Paper, Grid, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import ReactLoading from 'react-loading';

import BackgroundImage from '../components/BackgroundImage';
import logo from '../img/logo.png';
import background from '../img/login-background.jpg';
import { RECAPTCHA_KEY } from '../constants/ui';
import { Link } from 'react-router-dom';
import { LOGIN_URL } from '../constants/url';
import { issuePasswordRecovery, cancelPasswordRecovery, isTokenValid, setPassword } from '../services/passwordRecovery';
import withHiddenProp from '../components/withHiddenProp';
import Div from '../components/Div';


const HDiv = withHiddenProp(Div);

const styles = theme => ({
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(5)
    },
    root: {
        marginTop: theme.spacing(),
    },
    dosbotones: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: 20,
    }
});


class PasswordRecoverPage extends Component {

    constructor (props) {
        super(props);
        this.state = {
            tag: props?.match?.params?.tag || null,
            step: 1,
            loading: false,
            checkingToken: true,
            tokenValid: false,
            success: false,
            form: {
                email: '',
                captcha: '',
                password1: '',
                password2: '',
                close_sessions: true,
            }
        }
        if (props.recover) this.state.step = 4;
        if (props.cancel) this.state.step = 3;

        this.onComponentChange = this.onComponentChange.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onCaptchaComplete = this.onCaptchaComplete.bind(this);
        this.handleSubmitStep1 = this.handleSubmitStep1.bind(this);
        this.handleSubmitStep4 = this.handleSubmitStep4.bind(this);
    }

    onComponentChange ( evt ) {
        let newState = {...this.state};
        newState.form[evt.target.name] = evt.target.value;
        this.setState (newState);
    }

    onCheckChange ( evt ) {
        let newState = {...this.state};
        newState.form[evt.target.name] = evt.target.checked;
        this.setState (newState);
    }

    onCaptchaComplete (value) {
        this.onComponentChange( { target: { name: 'captcha', value}});
    }

    handleSubmitStep1 (ev) {
        ev.preventDefault();
        if (! this.state.form.captcha  ) {
            toast.error ('Debe completar el captcha');
            return;
        }
        this.setState ( {loading: true});
        //Enviar al server el pedido de recuperación de claves
        issuePasswordRecovery(this.state.form.email).then( () => {
            this.setState ( {step: 2});

        }).catch( (err) => {
            console.error(err);
            toast.error ('Ocurrió un error al comunicarse con el servidor');

        }).then ( () => {
            this.setState ( {loading: false});
        });
        
    }

    handleSubmitStep4 (ev) {
        ev.preventDefault();
        if (! this.state.form.email) {
            toast.error ('El campo e-mail está vacío');
            return;
        }

        if (! this.state.form.password1) {
            toast.error ('Debe ingresar una contraseña nueva');
            return;
        }

        if (! this.state.form.password2) {
            toast.error ('Debe ingresar la nueva contraseña nuevamente');
            return;
        }

        if (this.state.form.password1.length < this.props.minimum_password_length) {
            toast.error('La contraseña es demasiado corta');
            return;
        }

        if (this.state.form.password1 !== this.state.form.password2) {
            toast.error ('Las contraseñas deben ser iguales');
            return;
        }

        this.setState( {...this.state, loading :true});
        setPassword(this.state.tag, this.state.form.email, this.state.form.password1, this.state.form.close_sessions).then ( (result) => {
            this.setState( {...this.state, success: true, tokenValid: false});

        }).catch ( ( error ) => {
            console.error(error);
            toast.error ('Ocurrió un error al comunicarse con el servidor');

        }).then( () => {
            this.setState( {...this.state, loading: false});
        })
        
    }


    cancelPasswordRecoveryRequest() {
        cancelPasswordRecovery (this.state.tag).catch( (err) => {
            console.error(err);
            toast.error ('Ocurrió un error al comunicarse con el servidor');
        });
    }

    componentDidMount() {
        if (this.state.step === 3) this.cancelPasswordRecoveryRequest();
        if (this.state.step === 4) {
            this.setState( {...this.state, checkingToken:true});
            isTokenValid(this.state.tag).then( (response) => {
                this.setState( {...this.state, tokenValid:response.data.valid || false});

            }).catch( (err) => {
                console.error(err);
                toast.error ('Ocurrió un error al comunicarse con el servidor');

            }).then ( () => {
                this.setState( {...this.state, checkingToken:false});
            });
        }
    }

    renderStep1 () {
        return (
            <div>
                <div align="Center"><h3>Recuperar contraseña</h3></div> 
                <form method="post" onSubmit={this.handleSubmitStep1}>
                    <Grid container spacing={8} alignItems="flex-end" style={{ paddingTop: 30 }}>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField 
                                id="email" 
                                label="E-mail" 
                                type="email"
                                autoComplete="e-mail"
                                fullWidth 
                                autoFocus 
                                required 
                                name="email"
                                value={this.state.form.email}
                                onChange={this.onComponentChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={8} alignItems="flex-end" style={{ paddingTop: 30 }}>
                        <Grid item md={true} sm={true} xs={true}>
                            <ReCAPTCHA
                                sitekey={RECAPTCHA_KEY}
                                onChange={this.onCaptchaComplete}
                            />
                        </Grid>
                    </Grid>
                    <div className={this.props.classes.dosbotones}>
                        <Link
                            className="float-right"
                            as={Link}
                            variant="contained" 
                            color="secondary"
                            component={Button}
                            to={LOGIN_URL}
                        >Cancelar</Link>

                        <Button
                            type="submit"
                            variant="contained" 
                            
                            color="primary"
                            disabled={this.state.loading}
                        >
                            {this.state.loading?<ReactLoading className="loading" type="bars" color="#ffffff" width="24px" height="24px"/>:"Continuar"}                                                    
                        </Button>

                    </div>
                </form>
                
            </div>
        );
    }

    renderStep2 () {
        return (
            <div>
                <div align="Center"><h3>Recuperar contraseña</h3></div>
                <p align="center"><strong>Se ha enviado un e-mail a la dirección de correo</strong></p>
                <p align="justify">Si la dirección de correo electrónico ingresada es correcta, entonces las instrucciones para restablecer la clave se han enviado a esa dirección.</p>
                <p align="justify">Si no encuentra el mensaje de correo electrónico en su <strong>bandeja de entrada</strong> búsquelo en la carpeta de <strong>correo no deseado</strong> o vuelva a revisar tu correo más tarde.</p>
                <div align="center" style={{paddingTop: 20}}>
                    <Link
                        variant="contained" 
                        color="secondary"
                        component={Button}
                        to={LOGIN_URL}
                    >Volver</Link>
                </div>
            </div>
        );
    }

    renderStep3 () {
        return (
            <div>
                <div align="Center"><h3>Recuperar contraseña</h3></div>
                <p align="center">Se ha <strong>cancelado</strong> el pedido de recuperación de contraseña</p>
                <div align="center" style={{paddingTop: 20}}>
                    <Link
                        variant="contained" 
                        color="secondary"
                        component={Button}
                        to={LOGIN_URL}
                    >Volver</Link>
                </div>
            </div>
        );
    }

    renderStep4() {
        const { minimum_password_length } = this.props;
        const { checkingToken, tokenValid, success } = this.state;
        const hideCheckingToken = !checkingToken;
        const hideTokenValid = checkingToken || !tokenValid || success;
        const hideTokenInvalid = checkingToken || tokenValid || success;
        const hideSuccess = ! success;

        return(
            <div>
                <div align="Center"><h3>Recuperar contraseña</h3></div>                
                <HDiv hidden={hideCheckingToken} align="center">
                    <p>Validando token...</p>
                    <ReactLoading className="loading" type="bars" color="#007bff" width="32px" height="32px"/>
                </HDiv>
                <HDiv hidden={hideSuccess} align="center">
                    <p>La contraseña se ha actualizado.</p>
                    <div align="center" style={{paddingTop: 20}}>
                        <Link
                            variant="contained" 
                            color="secondary"
                            component={Button}
                            to={LOGIN_URL}
                        >Continuar</Link>
                    </div>
                </HDiv>
                <HDiv hidden={hideTokenInvalid} align="center">
                    <p>El enlace que está intentando utilizar ya ha caducado.</p>
                    <div align="center" style={{paddingTop: 20}}>
                        <Link
                            variant="contained" 
                            color="secondary"
                            component={Button}
                            to={LOGIN_URL}
                        >Volver</Link>
                    </div>
                </HDiv>

                <HDiv hidden={hideTokenValid}>
                    <form method="post" onSubmit={this.handleSubmitStep4}>

                        <Grid container spacing={8} alignItems="flex-end" style={{ paddingTop: 30 }}>
                            <TextField
                                type="email"
                                id="email" 
                                name="email"
                                label="E-mail" 
                                autoComplete="e-mail"
                                fullWidth 
                                autoFocus 
                                required 
                                value={this.state.form.email}
                                onChange={this.onComponentChange}
                            />
                        </Grid>

                        <Grid container spacing={8} alignItems="flex-end" style={{ paddingTop: 30 }}>
                            <TextField
                                type="password"
                                id="password1" 
                                name="password1"
                                label="Nueva contraseña (Al menos {minimum_password_length} caracteres)" 
                                autoComplete="password"
                                minLength={minimum_password_length}
                                fullWidth 
                                required 
                                value={this.state.form.password1}
                                onChange={this.onComponentChange}
                            />
                        </Grid>

                        <Grid container spacing={8} alignItems="flex-end" style={{ paddingTop: 30 }}>
                            <TextField
                                type="password"
                                id="password2" 
                                name="password2"
                                label="Repetir nueva contraseña" 
                                autoComplete="password"
                                minLength={minimum_password_length}
                                fullWidth 
                                required 
                                value={this.state.form.password2}
                                onChange={this.onComponentChange}
                            />
                        </Grid>

                        <Grid container spacing={8} alignItems="flex-end">
                            <Grid item md={true} sm={true} xs={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.onCheckChange}
                                            name="close_sessions"
                                            color="primary"
                                        />
                                    }
                                    label="Cerrar todas las sesiones"
                                />
                            </Grid>
                        </Grid>

                        <div className={this.props.classes.dosbotones}>
                            <Link
                                className="float-right"
                                as={Link}
                                variant="contained" 
                                color="secondary"
                                component={Button}
                                to={LOGIN_URL}
                            >Cancelar</Link>

                            <Button
                                type="submit"
                                variant="contained" 
                            
                                color="primary"
                                disabled={this.state.loading}
                            >
                                {this.state.loading?<ReactLoading className="loading" type="bars" color="#ffffff" width="24px" height="24px"/>:"Continuar"}                                                    
                            </Button>

                        </div>
                    </form>
                </HDiv>

            </div>
        );
    }

    renderStep() {
        let result = null;
        switch (this.state.step) {
            case 4:
                result = this.renderStep4();
                break;

            case 3:
                result = this.renderStep3();
                break;
            case 2:
                result = this.renderStep2();
                break;

            case 1:
                result = this.renderStep1();
                break;
        
            default:
                this.setState ( {step: 1} );
                result = this.renderStep1();
                break;
        }
        return result;
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <BackgroundImage src={background}/>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    >
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.padding} style={ {minWidth: '100%'} }>
                            <img src={logo} alt="Greenlabs" fluid></img>
                            {this.renderStep()}
                        </Paper>
                    </Grid>   
                </Grid> 
            </div>
        );
    }
}

export default withStyles(styles)(PasswordRecoverPage);