import { Table } from '@material-ui/core';
import React, { Component } from 'react'

import BasicInfo from './RtuStatus/BasicInfo.js';
import BlockHeader from './RtuStatus/BlockHeader.js';
import IoInfo from './RtuStatus/IoInfo.js';
import PowerInfo from './RtuStatus/PowerInfo.js';
import '../styles/supergreen.scss';

class RtuStatus extends Component {
    render = () => {
        const last_state = this.props.status&&this.props.status.last_state;
        if (! last_state) return null;
        return( 
            <Table>
                <BlockHeader title="Información básica" className="SuperGreen"/>
                <BasicInfo data={this.props.status} />
                
                {last_state && <BlockHeader title="Energía" className="SuperGreen"/>}
                {last_state && <PowerInfo data={last_state} />}

                {last_state && <BlockHeader title="Entradas" className="SuperGreen"/>}
                {last_state && last_state.inputs && <IoInfo data={last_state} />}
            </Table>);
    }
}
/*
{last_state && <BlockHeader title="Conectividad"/>}
{last_state && <ConnInfo data={last_state} />}     
*/           
export default RtuStatus;