import { SERVER_URL } from "./config";

export const SERVER = SERVER_URL;

export const API_SERVER = `${SERVER_URL}v1`;
const API_SERVER_NODE = `${SERVER_URL}v1/tlsm`;

export const API_AUTH = `${API_SERVER}/auth`;
export const API_AUTH_LOGIN = `${API_AUTH}/login`;
export const API_AUTH_LOGOUT = `${API_AUTH}/logout`;
export const API_AUTH_CHECK = `${API_AUTH}/check`;

export const API_PROFILE = `${API_SERVER}/profile`;
export const API_PROFILE_UPDATE_POSITION = `${API_PROFILE}/position`;
export const API_PROFILE_CHANGE_PASSWORD = `${API_PROFILE}/password`;

export const API_PASSWORD_RECOVERY = `${API_SERVER}/password_recovery`;
export const API_PASSOWRD_RECOVERY_ISSUE = API_PASSWORD_RECOVERY;
export const API_PASSWORD_RECOVERY_CANCEL = API_PASSWORD_RECOVERY;
export const API_PASSWORD_RECOVERY_UPDATE = API_PASSWORD_RECOVERY;
export const API_PASSWORD_RECOVERY_CHECK = API_PASSWORD_RECOVERY;

export const API_USER_LIST = `${API_SERVER}/users`;
export const API_USER = `${API_SERVER}/user/{$id}`;
export const API_USER_CREATE = `${API_SERVER}/user`;
export const API_USER_DELETE = API_USER;
export const API_USER_ACTIVATE = API_USER;
export const API_USER_EDIT = API_USER;
export const API_USER_FORM = `${API_SERVER}/forms/user`;

export const API_INSTANCE_LIST = `${API_SERVER}/instances`;
export const API_INSTANCE = `${API_SERVER}/instance/{$id}`;
export const API_INSTANCE_CREATE = `${API_SERVER}/instance`;
export const API_INSTANCE_EDIT = API_INSTANCE;
export const API_INSTANCE_ACTIVATE = API_INSTANCE;
export const API_INSTANCE_DELETE = API_INSTANCE;
export const API_INSTANCE_PING = `${API_INSTANCE}/ping`;

export const API_RTU_LIST = `${API_SERVER}/rtus`;
export const API_RTU = `${API_SERVER}/rtu/{$id}`;
export const API_RTU_COMMAND = `${API_RTU}/command/{$command_id}`;
export const API_RTU_IMPORT = `${API_SERVER}/rtu`;
export const API_RTU_FORM = `${API_SERVER}/forms/rtu`;
export const API_RTU_FILTER = `${API_SERVER}/forms/rtu/filter`;
export const API_RTU_FAT = `${API_RTU}/fat`;
export const API_RTU_UPDATE_CONFIG = `${API_RTU}/config`;
export const API_RTU_PATCH = `${API_RTU}`;
export const API_RTU_DEBUG_LIST = `${API_SERVER_NODE}/debugs/{$mac}`;
export const API_RTU_DEBUG_DELETE = `${API_SERVER_NODE}/debug/{$debugid}`;
export const API_RTU_HISTORY_LIST = `${API_RTU}/history`;
export const API_RTU_UPDATE_RAW_CONFIG = `${API_RTU}/config/raw`;
export const API_RTU_GET_SETUP_TEMPLATE = `${API_RTU}/setup_template`;
export const API_RTU_PARAMETERS_FORM = `${API_SERVER}/forms/rtu_parameters`;
export const API_RTU_LABEL = `${API_RTU}/label`;

export const API_FIRMWARE_LIST = `${API_SERVER}/fws`;
export const API_FIRMWARE = `${API_SERVER}/fw/{$id}`;
export const API_FIRMWARE_CREATE = `${API_SERVER}/fw`;
export const API_FIRMWARE_EDIT = API_FIRMWARE;
export const API_FIRMWARE_ACTIVATE = API_FIRMWARE;
export const API_FIRMWARE_DELETE = API_FIRMWARE;
export const API_FIRMWARE_FORM = `${API_SERVER}/forms/firmware`;

export const API_OTA_LIST = `${API_SERVER}/otas`;
export const API_OTA = `${API_SERVER}/ota/{$id}`;
export const API_OTA_ACTIVATE = `${API_OTA}`;
export const API_OTA_DELETE = `${API_OTA}`;
export const API_OTA_CREATE = `${API_SERVER}/ota`;
export const API_OTA_FORM = `${API_SERVER}/forms/ota`;

export const API_TRIGGER_LIST = `${API_SERVER}/triggers`
export const API_TRIGGER = `${API_SERVER}/trigger/{$id}`;
export const API_TRIGGER_EDIT = API_TRIGGER;
export const API_TRIGGER_ACTIVATE = `${API_TRIGGER}`;
export const API_TRIGGER_DELETE = `${API_TRIGGER}`;
export const API_TRIGGER_CREATE = `${API_SERVER}/trigger`
export const API_TRIGGER_FORM = `${API_SERVER}/forms/trigger`;

export const API_EVENT_LIST = `${API_SERVER}/events`;
export const API_EVENT = `${API_SERVER}/event/{$id}`;

export const API_COMMAND_RTU_LIST = `${API_SERVER}/commands`;
export const API_COMMAND_RTU = `${API_SERVER}/command/{$id}`;
export const API_COMMAND_RTU_ACTIVATE = `${API_COMMAND_RTU}`;
export const API_COMMAND_RTU_CANCEL = `${API_COMMAND_RTU}`;

export const API_ASSEMBLY_LIST = `${API_SERVER}/assemblies`;
export const API_ASSEMBLY = `${API_SERVER}/assembly/{$id}`;
export const API_ASSEMBLY_PHOTO = `${API_ASSEMBLY}/photo`;
export const API_ASSEMBLY_CREATE = `${API_SERVER}/assembly`;
export const API_ASSEMBLY_EDIT = `${API_ASSEMBLY}`;
export const API_ASSEMBLY_DELETE = `${API_ASSEMBLY}`;

export const API_DELIVERY_LIST = `${API_SERVER}/deliveries`;
export const API_DELIVERY = `${API_SERVER}/delivery/{$id}`;
export const API_DELIVERY_CREATE = `${API_SERVER}/delivery`;
export const API_DELIVERY_EDIT = `${API_DELIVERY}`;
export const API_DELIVERY_DELETE = `${API_DELIVERY}`;
export const API_DELIVERY_FORM = `${API_SERVER}/forms/delivery`;
export const API_DELIVERY_DOCUMENT = `${API_DELIVERY}/document`;

export const API_APIKEY_LIST = `${API_SERVER}/api_keys`;
export const API_APIKEY = `${API_SERVER}/api_key/{$id}`;
export const API_APIKEY_CREATE = `${API_SERVER}/api_key`;
export const API_APIKEY_DELETE = `${API_APIKEY}`;
export const API_APIKEY_FORM = `${API_SERVER}/forms/api_key`;

export const API_MAP_RTU_LIST = `${API_SERVER}/map/rtu`;

export const API_HISTORY_CREATE = `${API_SERVER}/history`;
export const API_HISTORY_INSTALLATION = `${API_SERVER}/history/{$id}/installation`;
export const API_HISTORY_TICKET = `${API_SERVER}/history/{$id}/ticket`;
export const API_HISTORY_TICKET_PRINT = `${API_SERVER}/history/{$id}/ticket/print`;

export const API_RTU_TYPE_LIST = `${API_SERVER}/rtu_types`;
export const API_RTU_TYPE = `${API_SERVER}/rtu_type/{$id}`;
export const API_RTU_TYPE_CREATE = `${API_SERVER}/rtu_type`;
export const API_RTU_TYPE_DELETE = API_RTU_TYPE;
export const API_RTU_TYPE_EDIT = API_RTU_TYPE;

export const API_SETUP_LIST = `${API_SERVER}/setups`;
export const API_SETUP = `${API_SERVER}/setup/{$id}`;
export const API_SETUP_CREATE = `${API_SERVER}/setup`;
export const API_SETUP_DELETE = API_SETUP;
export const API_SETUP_EDIT = API_SETUP;
export const API_SETUP_FORM = `${API_SERVER}/forms/setup`;
export const API_SETUP_TEMPLATE_FORM = `${API_SERVER}/setup/{$id}/form`;

export const API_MAP_FORM = `${API_SERVER}/forms/map`;

export const API_DEMO = `${API_SERVER}/demo/data`;

export const API_ICON_LIST = `${API_SERVER}/icons`;