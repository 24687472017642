import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import { toast } from 'react-toastify';

import { getUpdateInterval } from '../redux/reducers/ui';
import { pageStyle } from "../styles/styles.js";

import AbstractAbmPage from './AbstractAbmPage.js';
import {  API_SETUP_LIST, API_SETUP, API_SETUP_DELETE, API_SETUP_CREATE, API_SETUP_EDIT, API_SETUP_TEMPLATE_FORM } from '../constants/api';
import SetupForm from '../components/forms/SetupForm';
import loggedUserServerFetch from '../services/loggedUserServerFetch';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RtuConfigForm from '../components/RtuConfig.new/components/form';
import { Button } from '@material-ui/core';
import { element } from 'prop-types';


class SetupPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = SetupForm;
        this.strings = {
            createItem: 'Nueva plantilla de configuración',
            editItem: 'Editar plantilla de configuración',
            itemCreationSuccess: 'Plantilla cargada exitosamente',
            itemUpdateSuccess: 'Plantilla actualizada exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra la plantilla seleccionada',
                save: 'Ocurrió un error al guardar la plantilla. Intente nuevamente.',
            }
        }
        
        this.urls = {
            list: API_SETUP_LIST,
            item: API_SETUP,
            toggle: null,
            delete: API_SETUP_DELETE,
            create: API_SETUP_CREATE,
            update: API_SETUP_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'description', field: 'description', title: 'Nombre', sorting: true, },
                {id: 'fields_count', field: 'fields_count', title: 'Campos', sorting: false, render: (e) => e?.template?.length},
                {id: 'firmwares', field: 'firmwares', title: 'Firmwares', sorting: false, },
                ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nueva plantilla de configuración',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: 'preview',
                    tooltip: 'Vista previa',
                    onClick: () => {  this.handlePreview(element); },
                }),
                (element) => ({
                    icon: 'file_copy',
                    tooltip: 'Colnar',
                    onClick: () => {  this.handleClone(element.id); },
                }),
                (element) => ({
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: () => {  this.handleEdit(element.id) },
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: (element.firmwares > 0)?'No se puede eliminar un template en uso':'Eliminar',
                    disabled: (element.firmwares > 0),
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar la plantilla ${element.description}?`) }
                }),
            ]
        };

        this.state.ping = false;
        this.state.showPreview = false;
        this.state.element = null;
    }
    handleClone = async (id) => {
        const url = API_SETUP.replace('{$id}', id);

        try {
            const response = await loggedUserServerFetch (url, 'get');
            const setup = {...response.data, id: null, description: `${response?.data?.description} (Copia)`};
            this.handleCreate (setup);

        } catch (error) {
            toast.error('Ocurrió un error al clonar la plantilla', 'msg_clone_err');
            console.error(error);
        }
    }

    handlePreview = async (element) => 
    {
        const url = API_SETUP_TEMPLATE_FORM.replace('{$id}', element.id);

        try {

            const response = await loggedUserServerFetch (url, 'get');
            this.setState ( {...this.state, showPreview: true, element: {...element, form: response.data}});

        } catch (error) {
            toast.error('Ocurrió un error al obtener el formulario', 'msg_get_form_err');
            console.error(error);
        }
    }

    closePreview = () => {
        this.setState ( {...this.state, showPreview: false, element: null});
    }

    postRender = () => {

        if (! this.state.showPreview) return null;

        return (
            <Dialog
                open={true}
                keepMounted
                fullWidth
                maxWidth="xl"
                onClose={this.closePreview}
            >
                <DialogTitle>Vista previa del formulario {this.state.element.description}</DialogTitle>
                <DialogContent>
                    <RtuConfigForm 
                        setup={{}}
                        form={ this.state.element }
                        onSubmit={ () => {} }
                        onClose={this.closePreview}
                        readonly={true}
                        hideButtons={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={this.closePreview} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        )
    } 
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(SetupPage));


