import React from 'react'
import useGeolocation from './react-hook-geolocation';

const ReactGeolocation = (props) => {
    const {onUpdateGeolocation, ...setup} = props;
    const geolocation = useGeolocation(setup, onUpdateGeolocation);
    
    return null;
}

export default ReactGeolocation;