import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
//import MaterialTable from 'material-table';
import MaterialTable from "@material-table/core";
import { ITEMS_PER_PAGE } from '../constants/ui';
import { getUpdateInterval } from '../redux/reducers/ui';

class Table extends Component {
    constructor (props) {
      super(props);

      const {
        //orderByColumnIndex = 0, 
        //orderDirection = 'asc', 
        defaultSortColumnIndex = 0,
        defaultSortDirection = 'asc',
    } = props;      
      
      this.state = {
        search: props.searchValue || '',
        orderDirection: defaultSortDirection,
        orderByColumnIndex: defaultSortColumnIndex,
        page: props.page || 0,
        itemsPerPage: props.itemsPerPage || ITEMS_PER_PAGE, 
        timer: null,
        tableRef: (props.tableRef)?props.tableRef:React.createRef(),
      }
    }

    setTimer = () => {
      if (! this.props.autoRefresh) return;
      const timer = setInterval ( () => {
        this.state.tableRef.current && this.state.tableRef.current.onQueryChange();
      },this.props.updateInterval)
  
      this.setState ( {...this.state, timer: timer});
    }

    clearTimer = () => {
      if (! this.props.autoRefresh) return;
      if (this.state.timer !== null) clearInterval(this.state.timer);
    }

    componentDidMount = () => {
      this.setTimer();
    }

    componentWillUnmount = () => {
      this.clearTimer();
    }

    componentDidUpdate = (prevProps,  prevState, snapshot) => {
      if (prevProps.updateInterval !== this.props.updateInterval) {
        this.clearTimer();
        this.setTimer();
      }
    }

    handleChangeOrder = (index, direction) => {
      console.log (index, direction);
      this.setState( {...this.state, orderByColumnIndex: index, orderDirection: direction});
    }

    handleChangeSearch = (search)  => {
      this.setState( { ...this.state, search: search});
    }

    handleChangePage = (page, itemsPerPage) => {
      this.setState({...this.state, page});
    }

    render() {
      let { columns } =  this.props;

      const {        
        tableOptions = {}, 
        displaySearch = true, 
        actions = [], 
        title = '',        
        hidden,
      } = this.props;            

      const tableRef = this.state.tableRef;
      const style = hidden?{display: 'none'}:{};

      const { orderDirection, orderByColumnIndex } = this.state;

      if ( (typeof orderByColumnIndex === 'number' )&& (orderByColumnIndex >=0 ) && (orderByColumnIndex < columns.length) ) columns[orderByColumnIndex].defaultSort = orderDirection;

      return(
        <div style={style}>
        
        <MaterialTable
          {...this.props}
          tableRef={tableRef}
          title={title}
          actions={actions}          
          options={{
            sorting:true,
            actionsColumnIndex: -1,
            searchText: this.state.search,
            initialPage: this.state.page,
            pageSize: this.state.itemsPerPage,
            search: displaySearch,
            ...tableOptions
          }}
          localization={
            {
              body: {
                emptyDataSourceMessage: 'No hay registros'
              },
              toolbar: {
                searchTooltip: 'Buscar',
                searchPlaceholder: 'Buscar',
              },
              pagination: {
                labelRowsSelect: 'filas por página',
                labelDisplayedRows: '{from}-{to} de {count}',
                firstTooltip: 'Primer página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima página',
                lastTooltip: 'Última página'
              }
            }
          }
          components={{
            OverlayLoading: props => (<div></div>),
          }}

          onOrderChange={ (orderedColumnId, orderDirection) => { this.handleChangeOrder(orderedColumnId, orderDirection)}}
          onSearchChange={ (search) => { this.handleChangeSearch(search)}}

          onChangePage={ this.handleChangePage }
        />


      </div>
      )
    }
  }

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.func.isRequired,
  
  tableOptions: PropTypes.object,
  displaySearch: PropTypes.bool,
  actions: PropTypes.array,
  title: PropTypes.string,
  searchValue: PropTypes.string,
  orderDirection: PropTypes.string,
  orderByColumnIndex: PropTypes.number,
  page: PropTypes.number,
  itemsPerPage: PropTypes.number,
  tableRef: PropTypes.any,
  hidden: PropTypes.bool,
  defaultSortColumnIndex: PropTypes.number,
  defaultSortDirection: PropTypes.string,
}
  

const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});
  
export default connect(mapStateToProps, mapDispatchToProps)(Table);