import SimpleForm from './SimpleForm';
const RtuForm = (props) => {
    let myprops = {...props};

    let rtuTypeOptions = {};
    const rtuTypes = (props.rtu_types || []);
    rtuTypes.forEach (rtuType => {
        rtuTypeOptions[rtuType.id] = rtuType.description;
    });

    myprops.schema = {
        fields: [
            {
                key: 'rtu_type',
                name: 'rtu_type',
                type: 'select',
                label: 'Tipo de RTU',
                required: true,
                disabled: false,
                autoFocus: true,
                options: rtuTypeOptions,
            },            {
                key: 'macs',
                name: 'macs',
                type: 'textarea',
                label: 'RTUs (Ingresar una macadress por línea)',
                required: true,
                disabled: false,
                autoFocus: false,
                multiline: true,
                rows: 20,
            },
        ]
    }

    return (
        SimpleForm(myprops)
    );
}
export default RtuForm;