import React, { Component } from 'react'
import dayjs from 'dayjs';

import { green, red } from '@material-ui/core/colors';
import BlockBody from './BlockBody.js';
import { Chip } from '@material-ui/core';

class BasicInfo extends Component {
    render = () => {
        const {mac, status, last_state_at, imei, is_online, firmware_version} = this.props.data;        
        const connectionString = is_online?'Online':'Offline';
        const color = { backgroundColor: is_online?green[500]:red[500], color: 'white' };
        const onlineFlag = <Chip style={color} label={connectionString}/>
        
        return (
                <BlockBody fields={[
                    {name: 'Estado', value: status},
                    {name: 'MAC', value: mac},
                    {name: 'IMEI', value: imei},
                    {name: 'Versión de firmware', value: firmware_version},
                    {name: 'Última actualización', value: last_state_at && dayjs(last_state_at).format('DD/MM/YYYY HH:mm:ss')},
                    {name: 'Conexión',value: onlineFlag},
                ]}/>
        )
    }
}

export default BasicInfo;