import React from 'react'
import { RTU_STATUS_BORN, RTU_STATUS_BUILT, RTU_STATUS_DELIVERED, RTU_STATUS_INSTALLED, RTU_STATUS_UNINSTALLED } from '../constants/misc';
import { Chip } from '@material-ui/core';

const RtuInfoStatus = (props) => {
    
    let label = props.status;
    let color = null;

    switch (props.status) {
        case RTU_STATUS_BORN:   //Celeste
            color = '#17a2b8';
            break;

        case RTU_STATUS_BUILT:  //Negra
            color = '#343a40';
            break;

        case RTU_STATUS_DELIVERED: 
            color = '#28a745';
            break;
            
        case RTU_STATUS_INSTALLED:  //Azul
            color = '#007bff';
            break;

        case RTU_STATUS_UNINSTALLED:    //Naranja
            color = '#ffc107';
            break;

        default:
            color = null;
            break;            
    }

    //icon = <Icon>{icon}</Icon>;
        
    return (
        <Chip
            size='small'
            variant="outlined"
            label={label}
            style={{borderColor: color, color: color}}
        />
    );
}

export default RtuInfoStatus;