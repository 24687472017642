import React from 'react'
import EnhancedMenu from "./EnhancedMenu";
import { blue, yellow, green } from '@material-ui/core/colors';
import { RTU_STATUS_BORN } from '../constants/misc';

const buildMenu = (element, onClose, props) => {
    const result = [];
    if ( ! element) return result;

    //Parámetros de la remota
    const unasignedType = element?.rtu_type?.is_unasigned || false;
    const hasAsignedType = ! unasignedType;

    result.push ({
        key: "actionMenuItemAssignType",
        caption: "Editar parámetros",
        disabled: false,
        //icon:  "favorite_icon",
        onClick: (event) => {
            props.handleSetType(element, event);
            onClose();
        }  
    })

    //Configuracion
    const {is_editable, is_timedout, value} = {...element.config};
    const {pending, config_related_command} = {...element.command};

    //Si no hay configuración: deshabilitar el botón. (Gris)
    //Si hay configuración y hay un comando y el comando es de config: Ver cacheda (RO) (Celeste)
    //Si hay configuración y está actualizada: editar (Verde)
    //Si hay configuración y no está actualizada: preguntar (Amarillo)

    
    let disabled = ! ( value && is_editable) ;// (! value) || (! is_editable);
    let style = {};
    let caption = 'Configuración';

    if (value && ! is_timedout) {
        style = {color: green[500]};
        caption = 'Editar configuración';

    } else if (value && pending && config_related_command) {
        style = {color: blue[500]};
        caption = 'Ver configuración';

    } else  if (value && is_timedout) {
        style = {color: yellow[900]};
        caption = 'Editar configuración desactualizada';
        
    } else if (! value && pending && config_related_command) {
        caption = 'Esperando configuración'
    }

    hasAsignedType && result.push({
        key: "actionMenuItem2",
        caption: caption,
        disabled:  disabled,
        style: style,
        //icon:  "check_box_icon",
        onClick: (event) => {
            props.handleEditConfig(element);
            onClose();
        }
    });

    //Si está fabricada, ofrecemos ensamblarla
    if  ((element.status === RTU_STATUS_BORN) && hasAsignedType) {
        result.push({
            key: "actionMenuItemAssemble",
            caption: 'Ensamblar',
            disabled:  false,
            style: style,
            onClick: (event) => {
                props.handleAssemble(element);
                onClose();
            }      
        })
    }

    //TODO: Si soy ROOT
    hasAsignedType && result.push({
        key: "actionMenuItem2-GOD",
        caption: "Editar configuración JSON",
        //icon:  "center_focus_strong_icon",
        onClick: (event) => {
            props.handleEditJsonConfig(element);
            onClose();
        }  
    });

    //Comandos
    result.push({
        key: "actionMenuItem3",
        caption: "Comandos",
        disabled: element.command.pending || props.commands.length === 0,
        //icon:  "settings_icon",
        subMenuItems: props.commands?.map( (cmd) => {
            return ( {
                key: `actionMenuCommand${cmd.id}`,
                caption: cmd.label,
                onClick: () => {
                    props.handleDispatchCommand(element.id, cmd.id)
                    onClose();
                }
            })
        })
    });

    //FAT
    hasAsignedType && result.push({
        key: "actionMenuItem4",
        caption: "Ensayo FAT",
        disabled: ! element.last_state,
        //icon:  "center_focus_strong_icon",
        onClick: (event) => {
            props.handleDownloadFat (element);
            onClose();
        }  
    });

    //Diagnóstico de remotas
    result.push ( {
        key: 'actionMenuItem5',
        caption: 'Diagnóstico',
        disabled: ! element.last_debug,
        onClick: (event) => {
            props.handleDebug(element);
            onClose();
        }
    })

    //Ver historia
    hasAsignedType && result.push ( {
        key: 'actionShowHistory6',
        caption: 'Historia',
        disabled: ! element.has_history,
        onClick: (event) => {
            props.handleShowHistory(element);
            onClose();
        }
    })

    //Imprimir etiqueta
    result.push ( {
        key: 'actionLabelDownload7',
        caption: 'Descargar etiqueta',
        disabled: false,
        onClick: (event) => {
            props.handleLabelDownload(element);
            onClose();
        }
    })

    //Ota
    result.push ( {
        key: 'actionLabelDownload8',
        caption: 'Encolar OTA',
        disabled: false,
        onClick: (event) => {
            props.handleDisplayOtaForm(element);
            onClose();
        }
    })


    return result;
}

const RtuActionDropdown = ({open, onClose, element, anchor, ...props}) => {
    return (
        <EnhancedMenu
            open={open}
            menuItems={buildMenu(element,onClose, props)}
            anchorElement={anchor}
            onClose={onClose}
        />
    );
}

export default RtuActionDropdown;