const dec2hex = (dec) => {
    return dec.toString(16).padStart(2,'0');
};

const hex2float = (str, precision) => {
    var number = 0, sign, order, mantiss, exp, i;
    if (str.length <= 6) {
        exp = parseInt(str,16);
        sign = (exp >> 16)? -1:1;
        mantiss = ((exp >> 12) & 255) - 127;
        order = ((exp & 2047) + 2048).toString(2);
        for (i = 0; i < order.length; i += 1) {
            number += parseInt(order[i],10)? Math.pow(2,mantiss):0;
            mantiss--;
        }
    } else if (str.length <= 10) {
        exp = parseInt(str,16);
        sign = (exp >> 31)? -1:1;
        mantiss = ((exp >> 23) & 255) - 127;
        order = ((exp & 8388607) + 8388608).toString(2);
        for (i = 0; i < order.length; i += 1) {
            number += parseInt(order[i],10)? Math.pow(2,mantiss):0;
            mantiss--;
        }
    }

    if (precision === 0 || precision)
        return (number * sign).toFixed(precision).toString(10);

    return parseFloat((number * sign).toString(10));
};

const bin2hex = (str) => {
    return parseInt(str, 2).toString(16).toUpperCase();
};

const bin2dec = (str) => {
    return parseInt(str, 2).toString(10);
};

const bin2float = (str, precision) => {
    return this.hex2float(this.bin2hex(str), precision);
};

const hex2bin = (str) => {     
    return parseInt(str, 16).toString(2);
};

const hex2dec = (str, signed) => {
    var number = str;
    if (signed === undefined) return parseInt(number,16);

    if (number.substring(0,2) !== "0x") {
      number = "0x" + number;
    }

    if (signed === 16 && number > 32768) {
      number -= 65536;

    } else {
      if (signed === 32 && number > 2147483648) {
        number -= 4294967296;

      } else {
        number = parseInt(number,16);
      }
    }
    return number;
}

const dec2bin = (str) => {
    return parseInt(str, 10).toString(2);
};

export {
    bin2hex,
    bin2dec,
    bin2float,
    dec2bin,
    dec2hex, 
    hex2bin,
    hex2dec,
    hex2float
};