import React from 'react'
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { green } from '@material-ui/core/colors';

import InputText from './input-text';
import InputNumber from './input-number';
import InputSelect from './input-select';
import InputIp from './input-ip';
import { Grid } from '@material-ui/core';



const ORIENT_H = 'H';
const ORIENT_V = 'V';

const TYPE_L = 'L';
const TYPE_I = 'I';
const TYPE_S = 'S';
const TYPE_F = 'F';

const INTEGER_LIMITS = {
    'uint8_t': {'min': 0, 'max': 255},
    'uint16_t': {'min': 0, 'max': 65535},
    'uint32_t': {'min': 0, 'max': 4294967295},
    'int8_t': {'min': -128, 'max': 127},
    'int16_t': {'min': -32768, 'max': 32767},
    'int32_t': {'min': -2147483648, 'max': 2147483647},
}

function renderField (field, setup, readonly) {
    /*
    dataType: "uint8_t"
    description: "Conexion"
    elems: 1
    group: 0
    name: "primary"
    offset: 0
    orientation: null
    printFormat: "GSM,ETH"
    printType: "L"
    type: "B"*/    
    
    const {printType, printFormat, dataType, description, elems, name} = field
    const value = setup[name] || '';
    let result = null;

    switch (printType) {
        case TYPE_L:
            result = <InputSelect description={description} name={name} value={value} options={printFormat} readonly={readonly}/>
            break;

        case TYPE_I:
            const {min = 0, max = 1} = INTEGER_LIMITS[dataType];
            result = <InputNumber description={description} name={name} value={value} min={min} max={max}  readonly={readonly}/>
            break;

        case TYPE_F:
            if (elems === 4 && printFormat === '{}.{}.{}.{}') {
                result = <InputIp description={description} name={name} value={value}  readonly={readonly}/>
            } else {
                result = <InputText description={description} name={name} value={value} maxLength={elems}  readonly={readonly}/>
            }
            break;            

        case TYPE_S:
        default:
            result = <InputText description={description} name={name} value={value} maxLength={elems}  readonly={readonly}/>
            break;
    }

    return result;
}

const horizontalGroup = (htmlFields) =>  {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    <TableRow>
                        {htmlFields.map( e=> <TableCell>{e}</TableCell>)}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const verticalGroup = (htmlFields) => {
    return htmlFields.map( e=> <Box paddingTop={2} paddingBottom={2}>{e}</Box>);
}


const group = (props) => {
    const { orientation = ORIENT_V, fields, setup, readonly } = props;    
    const isVertical = (orientation === ORIENT_V);

    const htmlFields = fields.map ( e => renderField ( e, setup.value||{}, readonly));
    
    return (
        <Grid item borderColor={ green[500]} border={1} xs={12} style={{padding: 20, margin: 20, border: '1px solid green'}}>
            {isVertical ? verticalGroup (htmlFields) : horizontalGroup (htmlFields) }
        </Grid>
    )
}

export default group;