import AbstractForm from './AbstractForm'
const schema = {
    fields: [
        {
            name: 'id',
            type: 'hidden',
            label: 'id',
        },
        {
            name: 'description',
            type: 'text',
            label: 'Descripción',
            required: true,
            disabled: false,
            autoFocus: true,
        },
        {
            name: 'active',
            type: 'switch',
            label: 'Formulario habilitado',
            checked: true,
            disabled: false,
        },
        {
            name: 'template',
            type: 'setup-decoder-template',
            label: 'Plantilla de decodificación',
        }


    ]
};

const SetupForm = (props) => {
    return <AbstractForm schema={schema} {...props}/>
}

export default SetupForm;