 import React, { Component } from 'react';

import { GoogleMap, LoadScript, Marker, InfoWindow, StandaloneSearchBox, TrafficLayer, DrawingManager, Polygon } from '@react-google-maps/api';
import { withStyles } from '@material-ui/styles';
import ticketInfoWindowBuilder from '../helpers/mapInfoWindowBuilder';
import { GMAPS_KEY } from '../constants/ui';
import { PropTypes } from 'prop-types';
import transparentMarker from '../img/transparent.png';
import rtuInfoWindowBuilder from '../helpers/RtuMap/rtuInfoWindowBuilder';

const styles = theme => ({
});

const ICONSIZE = 32;

const ANCHOR_X = 15;
const ANCHOR_Y = 50;

const MAPSTYLE = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
  ];

class Map extends Component {
    constructor (props) {
        super(props);

        this.state = {
            name: props.name || 'map',
            map: {
                center: props.center,
                zoom: props.zoom,
                component: null,
            },
            mapContainerStyle: {
                width: '100%',
                height: window.innerHeight - 64,
            },
            fetch: {
                delay: 300,
                handler: null,
            },
            infoWindow: {
                display: false,
                position: {},
                content: '',
            },
            minZoomLabel: 10,
            trafficLayer: false,
            libraries: ['places', 'drawing']
        }
    }    

    componentWillUnmount() {
        if (this.state.fetch.handler) clearTimeout(this.state.fetch.handler);
    }

    fetchData = () => {
        this.onBoundsChange();
    }

    changeCenter ( lat, lng ) {
        let state = {...this.state};
        state.map.center = { lat, lng};
        this.setState ( state );
    }

    onLoadMap = (map) => {
        let currentState = {...this.state};
        currentState.map.component = map;

        this.setState( currentState );
        this.props.onLoad && this.props.onLoad(map);
        this.onBoundsChange();
    }

    onUnmountMap = (map) => {
        let currentState = {...this.state};
        currentState.map.component = null;
        this.setState( currentState );
    }

    onBoundsChange = () => {
        const center = this.state.map.component.getCenter();
        const zoom = this.state.map.component.getZoom()
        localStorage.setItem(`${this.state.name}_center`, JSON.stringify({lat: center.lat(), lng: center.lng()}));
        localStorage.setItem(`${this.state.name}_zoom`, JSON.stringify(zoom));
        //No tengo que guardar el estado cuando se actualizan las coordenadas porque trae problemas para desplazarse
                
        //let currentState = {...this.state};
        //currentState.map.display_labels = zoom < 10;
        //this.setState( currentState, this.fetchData );

        const bounds = this.state.map.component.getBounds();
        if (! bounds ) return;
        
        let query = {
            'ne_lat': bounds.getNorthEast().lat(),
            'ne_lng': bounds.getNorthEast().lng(),
            'sw_lat': bounds.getSouthWest().lat(),
            'sw_lng': bounds.getSouthWest().lng(),
        }        

        if (this.state.fetch.handler) clearTimeout(this.state.fetch.handler);
        let handler = setTimeout( () => {
            this.props.fetchData(query);
        }, this.state.fetch.delay);

        let currentState = { ...this.state };
        currentState.fetch.handler = handler;
        this.setState ( currentState );
    }

    hideInfoWindow = () => {
        let currentState = this.state;
        currentState.infoWindow = {
            display: false,
            position: null,
            content: '',
        };
        this.setState(currentState);
        
    }

    displayInfoWindow = (position, content) => {
        let currentState = this.state;
        currentState.infoWindow = {
            display: true,
            position: position,
            content: content,
        };
        this.setState(currentState);
    }

    handleMapClick = () => {
        let currentState = this.state;
        currentState.infoWindow = {
            display: false,
            position: null,
            content: null,
        };
        this.setState(currentState);

    }

    /*

    handleTicketClick = (id) => {
        if (this.props.disableTicketInfoWindow||false) return;
        const item = this.props.tickets[id] || {};
        //this.state.map.component.setCenter( {lat: item.latitude, lng: item.longitude});
        const content = ticketInfoWindowBuilder(item);
        this.displayInfoWindow( {lat: item.latitude, lng: item.longitude}, content);
    }    

    handleUserClick = (id) => {
        console.log (id, this.state.items.users[id]);
    }


    renderTicket = ( [key, element]  ) => {
        const showLabel = this.state.map.component.getZoom() >= this.state.minZoomLabel;
        return (
            <Marker
                position={ {lat: element.latitude, lng: element.longitude} }
                label={ showLabel ? element.installation_id : ' ' }
                icon={ element.icon || null }
                key={ `ticket_${element.id}` }
                onClick={ () => this.handleTicketClick( element.id) }
            />
        );
    }

    renderUser = ( [key, element] ) => {
        return (
            <Marker
                position={ {lat: element.latitude, lng: element.longitude} }
                label={ element.username }
                icon={ element.icon || null }
                key={ `user_${element.id}` }
                onClick={ () => this.handleUserClick( element.id) }
            />
        );
    }

    renderPolygon = ( [key, element] ) => {        
        let center = {lat: 0, lng: 0};
        element.path.forEach( (element) => {
            center.lat += element.lat;
            center.lng += element.lng;
        });
        center.lat = center.lat / element.path.length;
        center.lng = center.lng / element.path.length;
        
        return (
            <div>
                <Polygon
                    key={`polygon_${element.id}`}
                    path={ element.path }
                    options={{
                        fillColor: element.color,
                        fillOpacy: element.alpha,
                        strokeColor: element.color,
                        stokeOpacy: element.alpha + 0.2,
                    }}
                />
                <Marker
                    key={`polygon_label_${element.id}`}
                    position={ center }
                    label={element.description}
                    icon={transparentMarker}
                />
            </div>
        );
    }
    */
    renderRtu = (rtu) => {
        return (
            <Marker
                position={ {lat: rtu.lat, lng: rtu.lng} }                
                key={ `rtu_${rtu.id}` }                
                onClick={ () => {this.handleRtuClick(rtu)} }                
                icon={{
                    url: rtu.icon, 
                    size: {width: ICONSIZE, height: ICONSIZE}, 
                    anchor: {x: ANCHOR_X, y: ANCHOR_Y}, 
                    scaledSize: {width: ICONSIZE, height: ICONSIZE}, 
                }}
                title={ rtu.mac }                    
            />
        );
    }

    handleRtuClick = (rtu) => {
        let newState = {...this.state};
        const infoWindowContent = rtuInfoWindowBuilder(rtu);
        
        newState.infoWindow = {
            display: true,
            position: {lat: rtu.lat, lng: rtu.lng},
            content: infoWindowContent,
        };

        this.setState(newState);
    }

    render = () => {
        const { center, zoom } = this.state.map;
        const { mapContainerStyle, infoWindow} = this.state;
        const { rtus = [], children, satelliteMap = false } = this.props;

        return (
            <div style={{position: 'relative', height: mapContainerStyle.height-90, width: '100%'}}>
                <div style={{position: 'absolute', left:0, right: 0, top: 0, bottom: 0}}>
                    <LoadScript googleMapsApiKey={GMAPS_KEY} libraries={this.state.libraries}>
                        <GoogleMap
                            id="device_map"
                            mapContainerStyle={mapContainerStyle}
                            zoom={zoom}
                            center={center}
                            mapTypeId={satelliteMap?'satellite':'roadmap'}
                            onLoad={this.onLoadMap}
                            onUnmount={this.onUnmountMap}
                            onBoundsChanged={this.onBoundsChange}
                            onClick={this.handleMapClick}
                            options={{
                                mapTypeControl: false,
                                styles: MAPSTYLE,
                            }}            
                        >
                            { this.props.trafficLayer && <TrafficLayer />}
                            { rtus.map( (rtu) => (this.renderRtu(rtu))) }
                            {
                                infoWindow.display && 
                                <InfoWindow
                                    onCloseClick={this.hideInfoWindow}
                                    position={infoWindow.position}
                                    options = { {pixelOffset: new window.google.maps.Size( 0, -ANCHOR_Y) } }
                                >
                                    <div>                                        
                                        {infoWindow.content}
                                    </div>
                                </InfoWindow>
                            }
                            <DrawingManager
                                drawingMode={this.props.drawingMode || ''}
                                options={{drawingControl: false}}
                                onPolygonComplete={this.props.onPolygonComplete}
                            />
                            {children}
                        </GoogleMap>
                    </LoadScript>
                </div>
            </div>    
        );
    }
}

Map.propTypes = {
    name: PropTypes.string.isRequired,
    center: PropTypes.object.isRequired,
    zoom: PropTypes.number.isRequired,

    disableTicketInfoWindow: PropTypes.bool,

    drawingMode: PropTypes.string,
    
    //Objetos con objetos a mostrar en el mapa
    tickets: PropTypes.object,
    users: PropTypes.object,
    heatmaps: PropTypes.object,
    polygons: PropTypes.object,
    
    //Función que recibe un objeto query y trae los elementos a renderizar
    fetchData: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
}

export default withStyles(styles)(Map);