import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Slide } from '@material-ui/core';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const PopUp = (props) => {
    const handleClose = () => {};
    const {title, children, open, classes, maxWidth, ...myprops} = {...props};

    return(
    <Dialog
        open={open}
        onClose={ handleClose }        
        TransitionComponent={Transition}
        maxWidth={maxWidth||'md'}
        {...myprops}
    >        
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
    </Dialog>
    );
}

PopUp.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
}

export default PopUp;
