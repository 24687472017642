import React from 'react'

import { store } from '../redux/store';
import { getUserPermission } from '../redux/reducers/users';
import { Grid, Link, Button } from '@material-ui/core';

import NavigationIcon from '@material-ui/icons/Navigation';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import BuildIcon from '@material-ui/icons/Build';
import DoneIcon from '@material-ui/icons/Done';

import { toast } from 'react-toastify';

const renderHeader = (element, userRole) => {
return <div align="right">
            <h3>
                <small>CT: </small>{element.installation_id} <small>Tk:</small>{element.number}
            </h3>
            <hr />
        </div>
}

const renderButtons = (element, userRole) => {
    let btnLocation = true;
    let btnCopyLocation = true;
    //let btnAssign = [USER_IS_ROOT, USER_IS_ADMIN, USER_IS_CREW_SUPERVISOR].includes(userRole) && element.status === TICKET_STATUS_OPEN;
    //let btnRevoke = [USER_IS_ROOT, USER_IS_ADMIN, USER_IS_CREW_SUPERVISOR].includes(userRole) && element.status === TICKET_STATUS_ASSIGNED;
    let btnAssign = false;
    let btnRevoke = false;
    let btnResolve = true;
    let btnApprove = true;
    let btnViewResolution = false;

    return (
        <div>

            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                style={
                    {paddingTop: 10, paddingBottom: 10}
                }

            >
                {btnLocation && 
                    <Button
                        id="btnLocation"
                        variant="contained"
                        comopnent={Link}
                        title="Navegar"
                        href={`http://maps.google.com/maps?daddr=${element.latitude},${element.longitude}&ll=`}
                        target="_blank"

                    ><NavigationIcon /></Button>
                }

                {btnCopyLocation && 
                    <Button
                        id="btnCopyCoordinates"
                        variant="contained"
                        title="Copiar coordenadas al portapapeles"
                        onClick={ () => {
                            let dummy = document.createElement('input');
                            document.body.appendChild(dummy);
                            dummy.setAttribute('id', 'MYCLIPBOARDDUMMYELEMENT');
                            document.getElementById('MYCLIPBOARDDUMMYELEMENT').value=`${element.latitude},${element.longitude}`;
                            dummy.select();
                            document.execCommand('copy');
                            document.body.removeChild(dummy);
                            toast.success('Coordenadas copiadas al portapapeles');
                        }}
                    ><FileCopyIcon /></Button>
                }

                {btnAssign && 
                    <Button
                        id="btnAsign"
                        variant="contained"
                        title="Asignar cuadrilla"
                    ><PersonAddIcon/></Button>
                }
                {btnRevoke &&
                    <Button
                        id="btnRevoke"
                        variant="contained"
                        title="Cancelar asignación"
                    ><PersonAddDisabledIcon/></Button>
                
                }
                {btnResolve &&
                    <Button
                        id="btnResolve"
                        variant="contained"
                        title="Resolver"
                    ><BuildIcon/></Button>
                }
                {btnApprove &&
                    <Button
                        id="btnApprove"
                        variant="contained"
                        title="Aprobar trabajo"
                    ><DoneIcon/></Button>
                }
                {btnViewResolution && null}
            </Grid>
            <hr />
        </div>
    );

}

const renderBody = (element, userRole) => {
    const mapa = [
        {key: 'Type', value: element.installation_type},
        {key: 'AssignedAt', value: element.assigned_at},
        {key: 'AssignedUser', value: element.assigned_user},
        {key: 'UtrSegModel', value: element.rtu_model},
        {key: 'Status', value: element.status},
        {key: 'Cause', value: element.cause},
        {key: 'InitialObs', value: element.initial_obs},
        {key: 'VisitedAt', value: element.visited_at},
        {key: 'Latitude', value: element.latitude},
        {key: 'Longitude', value: element.longitude},

    ];

    return (
            <table className='MuiTable-root' style={ {width: '100%'}}>
                <tbody className='MuiTableBody-root'>
                    {
                        mapa.map( e => {
                            return(
                                <tr className='MuiTableRow-root' style={ {paddingTop: 10, paddingBottom: 10, borderBottom: '1 px solid black'}}>
                                    <td className='MuiTableCell-root'><strong>{e.key}</strong></td>
                                    <td className='MuiTableCell-root'>{e.value}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
    );
}

const ticketInfoWindowBuilder = (element) => {

    const userRole = getUserPermission(store.getState()).slice(0).shift();
    
    return [renderHeader(element, userRole),renderButtons (element,userRole), renderBody(element,userRole)];
}

export default ticketInfoWindowBuilder;