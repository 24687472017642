import React from 'react'
import { green, red, yellow } from '@material-ui/core/colors';
import { Icon } from '@material-ui/core';

const RtuStatusIcons = (props) => {

    const {is_online, last_state_at, last_state, is_on_battery, has_pending_ota, command } = props.rtu_info;
    const {onLinkClick = null} = props

    const result = [];
    let color = null;
    let icon = null;
    let title = null;

    //Online / Offline
    if (is_online === true) {
        color = { color: green[500] };
        icon = 'link_icon';
        title = `Online`;

    } else if (is_online === false) {
        color = { color: red[500] };
        icon = 'link_off_icon';
        title = `Offline`;
    };

    if (last_state_at) title += ` - Último reporte de estado: ${last_state_at}`;

    if (onLinkClick) {
        (icon) && result.push (<Icon title={title} style={{cursor:"pointer", ...color} } key="rtu_status_icon_link" onClick={onLinkClick}>{icon}</Icon>);

    } else {
        (icon) && result.push (<Icon title={title} style={color} key="rtu_status_icon_link">{icon}</Icon>);
        
    }


    //Power supply
    color = null;
    icon = null;
    title = null;

    if (is_on_battery === true) {
        title = `Batería - Estado: ${last_state?.power?.bat_stat||'Desconocido'}`;
        icon = 'battery_full_icon';
        color = (last_state?.power?.bat_stat === 'BATTERY OK')?{ color: yellow[500] }:{ color: red[500] };

    } else if (is_on_battery === false) {
        title = 'AC';
        icon = 'power_icon';
        color = { color: green[500] };
    }                 

    (icon) && result.push (<Icon title={title} style={color} key="rtu_status_icon_power">{icon}</Icon>);

    //OTA
    color = null;
    icon = 'memory_icon';
    title = 'OTA pendiente';
    (has_pending_ota || false) && result.push (<Icon title={title} style={color} key="rtu_status_icon_ota">{icon}</Icon>);

    //Comandos
    color = null;
    icon = 'settings_icon';
    title = `Comando pendiente: ${command?.description}`;
    (command?.pending || false) && result.push (<Icon title={title} style={color} key="rtu_status_icon_cmd">{icon}</Icon>);

    return result;
}

export default RtuStatusIcons;