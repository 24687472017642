import React, { Component } from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import ReactJson from 'react-json-view';
import dayjs from '../../helpers/dayjs';
import { Switch, TableFooter, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import FileDownload from 'js-file-download';

import { pageStyle } from '../styles';
import { API_RTU_DEBUG_LIST, API_RTU_DEBUG_DELETE } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import { GREENLABS_GREEN } from '../../constants/ui';
import '../../styles/swal2.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
class RtuDebugDialog extends Component {
    constructor (props) {
        super(props);

        this.cols = [
            {id: 'id', field: 'id', title: 'id', sorting: false, },
            {id: 'created_at', field: 'created_at', title: 'Fecha y hora', sorting: false, render: (element) => element.created_at && dayjs(element.created_at).format('DD/MM/YYYY HH:mm')},
        ]

        const timer = setInterval ( () => {
            this._fetch_newer_entries();

        }, 5000);

        this.state = { 
            dateFrom: '',
            dateTo: '',
            debugInfo: null,
            debugInfoId: null,
            items: [],
            timer: timer,
            followList: false,
        }

        this.tableRef = React.createRef();

        this.newest_id = null;
        this.oldest_id = null;
    }

    componentWillUnmount = () => {
        if (this.state.timer) clearInterval(this.state.timer);
    }

    componentDidMount = () => {
        this._fetch_entries();
    }

    updateField = (field, value) => {
        let newState = {...this.state};
        newState[field] = value;
        this.setState(newState, this.doSearch);
    }

    doSearch = () => {
        this._fetch_entries();
    }

    _fetch_newer_entries = () => {
        if (! this.newest_id) return;

        this._fetch_entries(this.newest_id);

    }

    _fetch_older_entries = () => {
        if (! this.oldest_id) return;

        this._fetch_entries(null, this.oldest_id);
    }

    _fetch_entries = (newerThan = null, olderThan = null) => {
        let q = {sort: -1};
        if (newerThan) q['newer_than'] = newerThan;
        if (olderThan) q['older_than'] = olderThan;

        if (this.state.dateFrom) q['from'] = this.state.dateFrom
        if (this.state.dateTo) q['to'] = this.state.dateTo


        let url = API_RTU_DEBUG_LIST.replace('{$mac}', this.props.rtu.mac);

        loggedUserServerFetch (url, 'get', {}, {}, q).then ( (response) => {

            const items = response.data || [];

            if (items.length > 0) {
                let newItems = [];
                if (olderThan) {
                    this.oldest_id = items[items.length-1]._id;
                    newItems = [...this.state.items, ...response.data];
                }
                if (newerThan) {
                    this.newest_id = items[0]._id;
                    newItems = [...response.data, ...this.state.items];
                }
                if (! newerThan && ! olderThan) {
                    this.newest_id = items[0]._id;
                    this.oldest_id = items[items.length-1]._id;
                    newItems = [...response.data];
                }

                this.setState ({...this.state, items: newItems }, () => {
                    if (this.state.followList && this.state.items.length > 0) this.displayInformation(this.state.items[0]);
                });
            }
        })
    }

    displayInformation = (debugItem)  => {
        const { __v, ...rest } = debugItem;
        this.setState( {...this.state, debugInfoId: debugItem._id, debugInfo: rest});
    }

    deleteDebugInformation = (debugInfo) => {
        Swal.fire({
            text: '¿Desea eliminar permanentemente el registro? Esta operación no se puede deshacer',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText:'Cancelar',
            icon: 'question',
            customClass: {
                container: 'always-on-top'
            }
        }).then( (result) => {
            if (! result.value ) return;

            const url = API_RTU_DEBUG_DELETE.replace('{$mac}',this.props.rtu.mac).replace('{$debugid}', debugInfo._id);
            
            loggedUserServerFetch ( url, 'delete').then( () => {


                if (debugInfo.id === this.state.debugInfoId) this.setState({...this.state, debugInfo: null, debugInfoId: null,})
                this.setState({...this.state, items: this.state.items.filter( e => e._id !== debugInfo._id)});
                
            }).catch( (err) => {
                console.error('Error al eliminar el evento de debug' , debugInfo, err);
                toast.error('Ocurrio un error al eliminar el evento');
                
            }).then( () => {
                
            });
        });
    }

    downloadDebugInformation = (debugInfo) => {
        let dInfo = debugInfo;
        delete dInfo['__v'];
        FileDownload(JSON.stringify(dInfo, null, 2), `DEBUG-${debugInfo?._id}-${this.props.rtu.mac}.txt`);
    }

    render = () => {
        const { classes } = this.props;
        if (! this.props.open) return null;
        
        let title = `Diagnóstico de la RTU ${this.props?.rtu?.mac||''}`;
        if (this.props?.rtu?.friendly_name) title += ` - ${this.props.rtu.friendly_name}`;

        return (
            <Dialog fullScreen open={this.props.open} onClose={this.props.onClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} style={{backgroundColor: '#4b9451'}}>
                    <Toolbar>
                        <Typography 
                            variant="h6" 
                            className={classes.title} 
                            style={{color: 'white', flex: 1}}
                        >
                            {title}
                        </Typography>
                        <IconButton 
                            color="inherit" 
                            onClick={this.props.onClose}
                            aria-label="close" 
                            title="Cerrar" 
                            edge="end"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid 
                    container 
                    style={{paddingTop: 75}} 
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                >
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={1}>
                            <Grid key='debugLeftPanel' item xs={12} sm={6}>
                                <Paper elevation={0}>
                                    <Grid container spacing={2} style={{paddingLeft: '17px'}}>
                                        <Grid item xs={5}>
                                            <TextField                                            
                                                fullWidth
                                                id="dateFrom"
                                                label="Desde"
                                                type="date"
                                                InputLabelProps={{shrink: true,}}
                                                value={this.state.dateFrom}
                                                onChange={ (event) => this.updateField('dateFrom', event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                fullWidth
                                                id="dateTo"
                                                label="Hasta"
                                                type="date"
                                                InputLabelProps={{shrink: true,}}
                                                value={this.state.dateTo}
                                                onChange={ (event) => this.updateField('dateTo', event.target.value)}
                                            />
                                            </Grid>
                                        <Grid item xs={2} style={{textAlign:"right"}}>                                            
                                            <IconButton 
                                                color="inherit" 
                                                onClick={this.doSearch}
                                                aria-label="search" 
                                                title="Buscar"
                                                style={{paddingRight: '17px'}}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                            <Switch 
                                                label="Mostrar siempre el último evento"
                                                checked={this.state.followList}
                                                onChange={ () => this.setState({...this.state, followList: !this.state.followList}) }
                                            />
                                        </Grid>
                                    </Grid>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Fecha y hora</TableCell>
                                                    <TableCell>Información</TableCell>
                                                    <TableCell align="right">Acciones</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.items.map( e => {
                                                    const { _id, __v, ...rest} = e;
                                                    let info = JSON.stringify(rest);
                                                    info = info.length > 50 ? info.substring(0, 50) + '...' : info;
                                                    const isSelected = this.state.debugInfoId === e._id;
                                                    const style = isSelected ? {backgroundColor: GREENLABS_GREEN, color: 'white'} : {};
                                                    return (
                                                        <TableRow style={style}>
                                                            <TableCell style={style}>{dayjs(e._createdAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                                            <TableCell style={style}>{info}</TableCell>
                                                            <TableCell style={style} align='right'>
                                                                <IconButton
                                                                    title ='Ver información de debug'
                                                                    onClick={ () => this.displayInformation(e)}
                                                                ><FindInPageIcon style={style}/></IconButton>
                                                                <IconButton 
                                                                    title ='Descargar información de debug'
                                                                    onClick={ () => this.downloadDebugInformation(e)}
                                                                ><GetAppIcon style={style}/></IconButton>
                                                                <IconButton 
                                                                    title ='Eliminar registro de debug'
                                                                    onClick={ () => this.deleteDebugInformation(e)}
                                                                ><DeleteForeverIcon style={style}/></IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                )}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan={3} align='right'>
                                                        <IconButton 
                                                            color="inherit" 
                                                            onClick={this._fetch_older_entries}
                                                            aria-label="search" 
                                                            title="Buscar"
                                                            style={{paddingRight: '17px'}}
                                                        >
                                                            <QueuePlayNextIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid key='debugRightPanel' item xs={12} sm={6}>
                                <Paper elevation={1}>
                                    {this.state.debugInfoId !== null && (typeof this.state.debugInfo === 'object') && <ReactJson
                                        src = { this.state.debugInfo }
                                        name = { `Evento ${this.state.debugInfoId}` || ''}
                                        iconStyle = 'triangle'
                                        collapsed = { 2 }
                                        displayDataTypes = { false }
                                        enableClipboard = { false }
                                    />}
                                    {this.state.debugInfoId !== null && (typeof this.state.debugInfo !== 'object') && <pre>{this.state.debugInfo}</pre>}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

export default withStyles(pageStyle)(RtuDebugDialog);