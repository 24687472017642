import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';

//Icons
import InputIcon from '@material-ui/icons/Input';
import AlarmIcon from '@material-ui/icons/Alarm';
import SaveIcon from '@material-ui/icons/Save';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockIcon from '@material-ui/icons/Lock';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

import { LocalShipping, ViewModule, Router, MoveToInbox, Memory, Map, PhonelinkSetup } from '@material-ui/icons';

import { Profile, SidebarNav } from './components';
import { LOGOUT_URL, PASSWORD_URL, USER_URL, INSTANCE_URL, RTU_URL, ASSEMBLY_URL, FIRMWARE_URL, OTA_URL, DELIVERY_URL, 
  TRIGGERS_URL, EVENTS_URL, MAP_URL, API_KEY_URL, RTU_TYPE_URL, SETUP_URL } from '../../../../constants/url' 

import { COMMAND_URL } from '../../../../constants/url' 

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2),    
  }
}));

const pages = [
  {
    type: 'title',
    title: 'Remotas'
  },
  {
    title: 'Mapa',
    href: MAP_URL,
    icon: <Map />
  },
  {
    title: 'Remotas',
    href: RTU_URL,
    icon: <Router />
  },
  {
    title: 'Firmware',
    href: FIRMWARE_URL,
    icon: <Memory />
  },
  {
    title: 'OTA',
    href: OTA_URL,
    icon: <SaveIcon />
  },
  {
    title: 'Comandos',
    href: COMMAND_URL,
    icon: <SettingsIcon />
  },
  {
    title: 'Triggers',
    href: TRIGGERS_URL,
    icon: <AssignmentTurnedInIcon />
  },
  {
    title: 'Eventos',
    href: EVENTS_URL,
    icon: <AlarmIcon />
  },
  {
    type: 'separator',
  },
  {
    type: 'title',
    title: 'Ensamblajes'
  },
  {
    title: 'Ensamblajes',
    href: ASSEMBLY_URL,
    icon: <MoveToInbox />
  },
  {
    title: 'Entregas',
    href: DELIVERY_URL,
    icon: <LocalShipping />
  },
  {
    type: 'separator',
  },
  {
    type: 'title',
    title: ''
  },
  {
    title: 'Instancias',
    href: INSTANCE_URL,
    icon: <AccountTreeIcon/>
  },
  {
    title: 'Tipos de RTU',
    href: RTU_TYPE_URL,
    icon: <ViewModule />
  },
  {
    title: 'Configuraciones',
    href: SETUP_URL,
    icon: <PhonelinkSetup />
  },
  {
    title: 'API Keys',
    href: API_KEY_URL,
    icon: <LockIcon />
  },
  {
    title: 'Usuarios',
    href: USER_URL,
    icon: <PersonIcon />
  },    
  {
    type: 'separator',
  },
  {
    title: 'Cambiar contraseña',
    href:  PASSWORD_URL,
    icon: <VpnKeyIcon />
  },
  {
    title: 'Cerrar sesión',
    href: LOGOUT_URL,
    icon: <InputIcon />
  },
];

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
