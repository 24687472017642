import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_USER_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';
import { MIN_PASSWORD_LENGTH } from '../../constants/ui';
import { USER_IS_ROOT } from '../../constants/profiles';

class UserForm extends Component {
    constructor (props) {
        super(props);
        
        this.state = {
            formData: {},
            loading: true,
        };
    }

    componentDidMount = async () => {
        try {
            const response = await loggedUserServerFetch (API_USER_FORM, 'get', {}, {}, {});
            await new Promise((resolve) => this.setState({...this.state, formData: response?.data||{}, loading: false}, resolve) );            
            
        } catch (error) {
            console.error(error);
            toast.error ('Ocurrió un error obteniendo los datos del formulario', 'err_user_form');
        }
    }


    buildSchema = (values) => {
        const userTypes = this.state?.formData?.user_type || [];
        const customers = this.state?.formData?.instance || [];

        const minPasswordLength = this.state?.formData?.min_password_length || MIN_PASSWORD_LENGTH;
        
        let userTypeOptions = {};
        userTypes.forEach(element => {
            userTypeOptions[element.id] = element.description;
        });
        
        
        let customerOptions = {};
        customers.forEach ( element => customerOptions[element.id] = element.description);
    
        const schema = {
            title: '',
            fields: [
                {
                    name: 'id',
                    type: 'hidden',
                    label: 'Id',
                },
                {
                    name: 'name',
                    type: 'text',
                    label: 'Nombre y apellido',
                    required: true,
                    disabled: false,
                    autoFocus: true,
                },
                {
                    name: 'email',
                    type: 'email',
                    label: 'E-mail',
                    required: true,
                    disabled: false,
                },
                {
                    name: 'username',
                    type: 'text',
                    label: 'Nombre de usuario',
                    required: true,
                    disabled: false,
                },
                {
                    name: 'password',
                    type: 'password',
                    label: `Contraseña (Al menos ${minPasswordLength} caracteres)`,
                    minLength: minPasswordLength,
                    disabled: false,
                    requiredIf: {
                        match:'any',
                        rules:[
                            {field: 'id', value: null, operator:'isNull'},
                        ]
                    }
                },
                {
                    name: 'active',
                    type: 'switch',
                    label: 'Cuenta activa',
                    checked: true,
                    disabled: false,
                },
                {
                    name: 'user_type',
                    type: 'select',
                    label: 'Tipo de usuario',
                    required: true,
                    disabled: false,
                    options: userTypeOptions,
                },
                {
                    name: 'instance_id',
                    type: 'select',
                    label: 'Cliente',
                    required: true,
                    disabled: false,
                    options: customerOptions,
                    hiddenIf: {
                        match: 'any',
                        rules: [
                            { field: 'user_type', value:[`${USER_IS_ROOT}`,USER_IS_ROOT], operator: 'contains' },
                            { field: 'user_type', value: null, operator: 'isNull' }
                        ]
                    },
                }
            ]
        }
        return schema;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema;
        if (this.state.loading) return this.renderLoading();
        let myprops = {...this.props};

        return (
            <AbstractForm 
                schema={schema}
                {...myprops}
            />)
    }
}

export default UserForm;