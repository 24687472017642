import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';

import { DEFAULT_RTU_MAP_CENTER, DEFAULT_RTU_MAP_ZOOM } from '../constants/ui';
import { API_MAP_FORM, API_MAP_RTU_LIST } from '../constants/api';

import ls from 'localstorage-slim';

import { toast } from 'react-toastify';
import MapControl, { POSITION_RIGHT_CENTER, POSITION_TOP_CENTER, POSITION_BOTTOM_CENTER } from '../components/MapControl';
import { Button, ButtonGroup, TextField } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TrafficIcon from '@material-ui/icons/Traffic';
import MapIcon from '@material-ui/icons/Map';
import HeightIcon from '@material-ui/icons/Height';


import loggedUserServerFetch from '../services/loggedUserServerFetch';
import Map from '../components/Map';
import { pageStyle } from '../components/styles';

import RTU_ICON_ONLINE from '../img/rtu-online.png';
import RTU_ICON_OFFLINE from '../img/rtu-offline.png';
import { getPosition } from '../redux/reducers/users';
import { connect } from 'react-redux';
import ComboBox from '../components/Map/ComboBox';

const RTU_ICON = {
    ONLINE: RTU_ICON_ONLINE,
    OFFLINE: RTU_ICON_OFFLINE,
}


/*


import SimpleListMenu from '../components/SimpleListMenu';
import swal from 'sweetalert2';
import pointInsidePolygon from '../helpers/pointInsidePolygon';
*/

class RtuMapPage extends Component{
    constructor(props) {
        super(props);
        
        const map_name = 'rtu_map';

        const center =  localStorage.getItem(`${map_name}_center`) ? JSON.parse(localStorage.getItem(`${map_name}_center`)) : DEFAULT_RTU_MAP_CENTER ;
        const zoom = localStorage.getItem(`${map_name}_zoom`) ? JSON.parse(localStorage.getItem(`${map_name}_zoom`)) : DEFAULT_RTU_MAP_ZOOM;

        //const hiddenHeights = localStorage.getItem('ticket_map_hidden_heights') ? JSON.parse(localStorage.getItem('ticket_map_hidden_heights')) : [];
        //const hiddenPolygons = localStorage.getItem('ticket_map_hidden_polygons') ? JSON.parse(localStorage.getItem('ticket_map_hidden_polygons')) : false;

        this.state = {
            map: {
                center: center,
                zoom: zoom,
                hideTrafficLayer: true,
                satellite: false,
                ref: React.createRef(),
                name: map_name,

                drawingMode: '',
            },

            items: {
                rtus: [],
            },

            lastQuery: {},
            hiddenHeights: [],
            hiddenPolygons: false,

            filter: {
                installation: ls.get('rtu-map-filter-installation') || 'T',
                customer: ls.get('rtu-map-filter-customer') || [],
                link: ls.get('rtu-map-filter-link') || '2',
            },

            customers: [],
            /*
            hiddenHeights: hiddenHeights,            
            hiddenPolygons: hiddenPolygons,

            crews: {
                1: 'Leonardo',
                2: 'Lucas',
            },
            */
        }
    }

    setStateAsync = async (state) => {
        return new Promise((resolve) => this.setState(state, resolve) );
    }

    componentDidMount = async () => {

        const {data} = await loggedUserServerFetch (API_MAP_FORM, 'get');
        const customers = (data?.instances || []).map ( e => ({value: e.id, description: e.description}));
        this.setState ( {...this.state, customers});        
    }
    
    /*

    updateDimensions = () => {
        let currentState = { ...this.state };
        currentState.mapContainerStyle.height = window.innerHeight - 64;
        this.setState ( currentState );
    }    
    */

    //BOTONES DE ABAJO AL CENTRO
    handleFilterChange = async (value, name) => {
        let state = {...this.state};

        switch (name) {
            case 'installationFilter':
                state.filter.installation = value;
                ls.set('rtu-map-filter-installation', state.filter.installation);
                break;

            case 'customerFilter':
                let fcar = state.filter.customer;

                if (value === '-2') {
                    fcar = [];

                } else {
                    fcar.includes ( value ) ? fcar.splice( fcar.indexOf( value ),1) : fcar.push(value);
                
                }

                state.filter.customer = fcar;
                ls.set('rtu-map-filter-customer', state.filter.customer);
                break;

            case 'linkFilter':
                state.filter.link = value;
                ls.set('rtu-map-filter-link', state.filter.link);
                break;
        
            default:
                break;
        }

        await this.setStateAsync(state);
        this.state.map.ref?.current && this.state.map.ref.current.fetchData();
    }

    buildRtuFilterQuery = () => {
        let rtuQuery = {};
        const filter = this.state.filter;
        
        if (filter.customer && filter.customer.length > 0) rtuQuery['exclude_customer_filter'] = filter.customer;
        if (filter.installation && filter.installation !== 'T') rtuQuery['installation_filter'] = filter.installation;
        if (filter.link && filter.link !== '2') rtuQuery['link_filter'] = filter.link;

        return rtuQuery;
    }

    //BOTONES DEL LADO DERECHO
    handleToggleMapType = () => {
       let currentState = {...this.state};
       currentState.map.satellite = ! currentState.map.satellite;
       this.setState(currentState);            
    }
    handleToggleTrafficLayer = () => {
        let currentState = {...this.state};
        currentState.map.hideTrafficLayer = ! currentState.map.hideTrafficLayer;
        this.setState(currentState);            
    }
    
    handleGoToMyPosition = () => {
        if (! navigator.geolocation) {
            toast.error ('Este navegador no soporta geolocalización');
            return;
        }

        const {latitude = null, longitude = null} = this.props?.userPosition || {};

        if (latitude === null || longitude === null) {
            toast.error ('No se pudo establecer la ubicación');
            return;
        }

        let currentState = {...this.state};
        currentState.map.center = {
            lat: latitude,
            lng: longitude,
        };
        this.setState(currentState , () => this.state.map.ref?.current && this.state.map.ref.current.changeCenter(latitude, longitude));
    }
    
    /*
    handleTogglePolygons = () => {
        localStorage.setItem('ticket_map_hidden_polygons', JSON.stringify(! this.state.hiddenPolygons));

        this.setState( { ...this.state, hiddenPolygons: ! this.state.hiddenPolygons }, () => {
            this.fetchData( this.state.lastQuery );
        });        
    }

    handleAssignByPolygon = () => {
        const currentState = {...this.state};
        currentState.map.drawingMode = 'polygon';
        this.setState(currentState);
    }

    handleOnPolygonComplete = (polygon) => {        
        setInterval (() => {            
            polygon.setMap(null);

        }, 5000);

        //Deshabilito el modo de dibujo
        const currentState = {...this.state};
        currentState.map.drawingMode = '';
        this.setState(currentState);
        
        //Obtener las coordenadas del polígono
        let path = [];
        let tickets = [];
        polygon.getPath().forEach( element => path.push([element.lat(), element.lng()]) );
        let options = this.state.crews;
        
        //¿Tengo tickets en el path?
        Object.entries(this.state.items.tickets||{}).forEach( ( [id, point] )  => {
            if (pointInsidePolygon ([point.latitude, point.longitude], path))
                tickets.push(point.id);
        })

        if (tickets.length === 0) {
            swal.fire ( 'No hay elementos en el polígono seleccionado' );
            return;
        }
        
        swal.fire({
            title: 'Seleccione una cuadrilla',
            input: 'select',
            inputOptions: options,
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            inputValudator: (value, resolve, reject) => {
                if (value.length === 0) {
                    resolve ('Debe seleccionar un usuario');
                } else {
                    resolve();
                }
            }
        })
        .then((result) => {    
            //Si cancelé, salgo
            if (typeof result.dismiss != 'undefined') return;

            let user = result.value;


            //TODO: Enviar al server el crew y la lista de tickets

            console.log ('CREW SELECCIONADO', user);
            console.log ('TICKETS', tickets);

            //Cuando vuelve el request, hacer fetchData
        });
    }

    */

    getData = (url, query={}, callback) => {
        loggedUserServerFetch (url, 'get', {}, {}, query).then ( (response) => {
            callback (response?.data??{});

        }).catch ( (error) => {
            if (!error.response) {
                console.error('Network error updating grid', error);
                
            } else if (window.navigator.onLine) {
                console.error('Error updating grid', error, window.navigator, error.response);
                //Error obteniendo datos
                //toast.error(self.strings.error.server_comm, 'error_server_comm');
                //reject (error);

            } else {
                //Esperando conexión a internet.
            }
        })                                

    }

    fetchData = (query={}) => {
        const self = this;
        this.setState({...this.state, lastQuery: query}, () => {            
            
            //Obtengo RTUs            
            const rtuFilterQuery = this.buildRtuFilterQuery();
            const rtuQuery = { ...query, ...rtuFilterQuery};

            self.getData(API_MAP_RTU_LIST, rtuQuery, (data) => {

                const rtus = data?.items || [];

                const newRtus = rtus.map( i => ({ ...i, icon: i.is_online ? i?.icons?.online || RTU_ICON.ONLINE : i?.icons?.offline || RTU_ICON.OFFLINE}) );

                let currentState = {...self.state};
                currentState.items.rtus = newRtus;

                self.setState(currentState);
            })

            //Acá puedo obtener otros elementos par el mapa.

        });




        //fetch de datos
        /*
        if (this.state.hiddenHeights.length > 0)
            query['exclude_level']=this.state.hiddenHeights.join(',');
        */

        //Rtus
        /*
        this.setState({...this.state, lastQuery: query}, () => {            
            loggedUserServerFetch (API_MAP_RTU_LIST, 'get', {}, {}, query).then ( (response) => {                
                let currentState = {...this.state};
                currentState.items.rtus = response.data?.items || [];
                this.setState(currentState);
    
            }).catch ( (error) => {
                console.log(error);
                toast.error('Ocurrió un error consultando remotas');
            })                                
        });
        */


        /*
        //Usuarios
        loggedUserServerFetch (API_TICKET_LIST, 'get', {}, {}, qs).then ( (response) => {                
            let currentState = {...this.state};
            currentState.items.tickets = response.data;
            this.setState(currentState);
        }).catch ( (error) => {
            console.log(error);
            toast.error('Ocurrió un error comunicándose con el servidor buscando tickets');
        })                                
        
        //Poligonos
        if (this.state.hiddenPolygons) {
            let currentState = {...this.state};
            currentState.items.polygons = {};
            this.setState(currentState);            
            
        } else {
            loggedUserServerFetch (API_POLYGON_LIST, 'get', {}, {}, {}).then ( (response) => {                
                let currentState = {...this.state};
                currentState.items.polygons = response.data;
                this.setState(currentState);
            }).catch ( (error) => {
                console.log(error);
                toast.error('Ocurrió un error comunicándose con el servidor buscando polígonos');
            })                                
        }
        */               
    }

    /*
    handleClosePopup = (ev) => {            
        this.setState ( { ...this.state, heightFilterMenu: false});
    }

    handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
          event.preventDefault();
          this.setState ( {...this.state, heightFilterMenu: false})
        }
    }

    handleToggleHeightFilter = (event, id) => {
        const currentState = {...this.state};

        if (currentState.hiddenHeights.includes(id)) {
            const index = currentState.hiddenHeights.indexOf(id);
            if (index > -1) currentState.hiddenHeights.splice(index, 1);

        } else {
            currentState.hiddenHeights.push(id);
        }
        localStorage.setItem('ticket_map_hidden_heights', JSON.stringify(currentState.hiddenHeights));
        this.setState(currentState);
    }
    */
    render() {
        return(
            <Map 
                name={this.state.map.name}
                center={this.state.map.center}
                zoom={this.state.map.zoom}
            
                disableTicketInfoWindow={false}
                trafficLayer={! this.state.map.hideTrafficLayer}
            
                fetchData={this.fetchData}
                satelliteMap={this.state.map.satellite}

                rtus={this.state.items.rtus}
                ref={this.state.map.ref}
            >
                {/*<MapControl position={POSITION_TOP_CENTER}>
                    <div style={{marginTop: 10, padding: 5, backgroundColor:'white'}}>
                        <TextField id="outlined-basic" label="Buscar..." variant="outlined"></TextField>
                    </div>
                </MapControl>*/}

                <MapControl position={POSITION_RIGHT_CENTER}>
                    <ButtonGroup orientation='vertical' style={{paddingRight: 10}}>
                        <Button type="button" variant="contained" color="default" title="Tipo de mapa" onClick={this.handleToggleMapType}><MapIcon /></Button>
                        <Button type="button" variant="contained" color="default" title="Tráfico" onClick={this.handleToggleTrafficLayer}><TrafficIcon /></Button>
                        <Button type="button" variant="contained" color="default" title="Mi ubicación" onClick={this.handleGoToMyPosition}><MyLocationIcon /></Button>
                    </ButtonGroup>
                </MapControl>
                <MapControl position={POSITION_BOTTOM_CENTER}>
                    <ButtonGroup style={{paddingBottom: 10}}>
                        <ComboBox 
                            key="combobox_filtro_instalacion"
                            name="installationFilter"
                            label="Instalación"
                            icon="push_pin"
                            value={this.state.filter.installation}
                            onChange={ this.handleFilterChange }
                            options={[{value: 'I', description: 'Solo instaladas'},{value: 'N', description: 'Solo no instaladas'},{value: 'T', description: 'Todas'}]}
                        />
                        <ComboBox 
                            key="combobox_filtro_cliente"
                            name="customerFilter"
                            label="Cliente"
                            icon="account_tree"
                            value={this.state.filter.customer}
                            onChange={ this.handleFilterChange }
                            selectedForeColor="white"
                            selectedBackgroundColor="red"
                            options={[...this.state.customers, {value: '-2', description: 'Todos'}]}
                        />
                        <ComboBox 
                            key="combobox_filtro_enlace"
                            name="linkFilter"
                            label="Conexión"
                            icon="link"
                            value={this.state.filter.link}
                            onChange={ this.handleFilterChange }
                            options={[{value: '0', description: 'Solo offline'},{value: '1', description: 'Solo online'},{value: '2', description: 'Todas'}]}
                        />
                        

                    </ButtonGroup>
                </MapControl>
            </Map>

        );
    }
}

const mapStateToProps = state => ({
    userPosition: getPosition(state),
});

const mapDispatchToProps = null;

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(RtuMapPage));