import { Button, Icon } from '@material-ui/core';
import React from 'react'

const IconButton = (props) => {
    const { title, icon, onClick } = props;

    return (        
        <Button type="button" variant="contained" color="default" title={title} onClick={onClick}><Icon>{icon}</Icon></Button>

    )
}

export default IconButton;

