import React, { createRef, useCallback } from 'react'
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton, Typography } from '@material-ui/core';

const ColorPicker = ({label, defaultValue, previewIcon, onChange, originalColor = null}) => {

    const control = createRef();

    const resetColor = useCallback ((color) => {
        control?.current && (control.current.value = color);
        onChange(color);
    });

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item><Typography>{label}</Typography></Grid>
                <Grid item>
                    <input type="color" defaultValue={defaultValue} onChange={e => onChange(e.target.value)} ref={control}/>
                    {originalColor && <IconButton size="small" onClick={() => resetColor(originalColor)} title="Restablecer color original">
                        <ClearIcon />
                    </IconButton>}
                </Grid>
                <Grid item><img src={previewIcon} style={{ height: '32px' }}/></Grid>
            </Grid>
        </>
    )
}

ColorPicker.propTypes = {
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    previewIcon: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    originalColor: PropTypes.string,
};

export default ColorPicker;