import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import dayjs from '../../helpers/dayjs';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import { toast } from 'react-toastify';
import FileDownload from 'js-file-download';

import { withStyles } from '@material-ui/core/styles';
import { pageStyle } from '../styles';
import DownloadableGallery from '../DownloadableGallery';
import { API_HISTORY_TICKET_PRINT } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';

const dateFormat  = (date) => {
    return date && dayjs(date).format('DD/MM/YYYY HH:mm');
}

const divider = (caption) => {
    return <div><hr /><h2 align="center">{caption}</h2></div>;
}

const header = (history, classes) => {
    let approval = null;
    
    if (history.approved_by) {
        approval = [
            <p>El ticket fue resuelto por <strong>{history?.resolution?.crew}</strong> el <strong>{dateFormat(history?.resolution?.visited_at)}</strong></p>,
            <span style={{color: 'green'}}>El ticket está resuelto y aprobado por <strong>{history.approved_by}</strong> el <strong>{dateFormat(history.approved_at)}</strong></span>
        ];            
    } else {
        approval = [
            <p style={{color: '#FFAE42'}}>El ticket está resuelto, pendiente de aprobación</p>,
            <span>El ticket fue resuelto por <strong>{history?.resolution?.crew}</strong></span>
        ];
    }

    let certTypeStr = 'Inversión';
    if (history?.certification?.type === 0) certTypeStr = 'Explotación';

    return (
        <Grid container className={classes.root} spacing={0}>
            <Grid item align="right"  spacing={1} xs={12} sm={12}>
                <IconButton aria-label="Imprimir" component="span" onClick={ () => handlePrint(history)}>
                    <PrintIcon />
                </IconButton>
            </Grid>
            <Grid item align="center" spacing={1} xs={12} sm={3}>Ticket <strong>{history?.number}</strong></Grid>
            <Grid item align="center" spacing={1} xs={12} sm={2}>Instalación <strong>{history?.installation?.id}</strong></Grid>
            <Grid item align="center" spacing={1} xs={12} sm={2}>Tipo <strong>{history?.installation?.type}</strong></Grid>
            <Grid item align="center" spacing={1} xs={12} sm={2}>UTR <strong>{history?.rtu_type}</strong></Grid>
            <Grid item align="center" spacing={1} xs={12} sm={3}>Visitado el <strong>{history?.resolution?.visited_at && dayjs(history?.visited_at).format('DD/MM/YYYY HH:mm')}</strong></Grid>
          
            <Grid item align="center" spacing={1} xs={12} sm={3}>Tipo de certificado <strong>{certTypeStr}</strong></Grid>
            <Grid item align="center" spacing={1} xs={12} sm={2}>N Certificado <strong>{history?.certification?.number}</strong></Grid>

            <Grid item align="center" spacing={1} xs={12}>{approval}</Grid>
        </Grid>
    )
}

const jobs = (info, classes) => {
    return (
        <div>
            {divider ('Matrículas')}

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell>Descripción</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {info.jobs?.map ( (e) => (
                        <TableRow>
                            <TableCell>{e.code}</TableCell>
                            <TableCell>{e.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

const extraFields = (info, classes) => {
    if (info.extra && info.extra.length > 0) {
        return (
            <div>
                {divider ('Campos adicionales')}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Campo</TableCell>
                            <TableCell>Valor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {info.extra.map ( (e) => (
                            <TableRow>
                                <TableCell>{e.field}</TableCell>
                                <TableCell>{e.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        )    
    } else {
        return null;
    }
}

const photoGalery = (props, classes) => {
    const {photos} = props;
    if (photos && photos.length > 0 ) {

        const img = photos.map ( (photo, index) => {
            return ({
                src: photo.url,
                thumbnail: photo.url,
                thumbnailWidth: 126,
                thumbnailHeight: 126,
                name: photo.name,
            });
        })
        

        return <div>
            {divider ('Fotos')}
            <DownloadableGallery 
                photos={img}
            />,
        </div>
    } else {
        return null;
    }
}

const handlePrint = (history) => {
    const id = history.history_id;
    const url = API_HISTORY_TICKET_PRINT.replace('{$id}', id);

    loggedUserServerFetch (url, 'get', {}, {}, {}, {responseType: 'blob'}).then( response => {
        FileDownload(response.data, `Ticket-${history.number}.pdf`);

    }).catch ( error => {
        console.error(error);
        toast.error('Ocurrió un error al intentar imprimir el ticket');
    })
}

const TicketHistory = (props) => {

    const {open, info:history, classes} = props;

    if ( (! open) || (! history) ) return null;

    const ticket_number = history.number

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open={open}
            onClose={ () => {} }
            aria-labelledby="rtuTicketDetails"
            disableEnforceFocus={true}
        >
            <DialogTitle id="rtuTicketDetails">Detalles del ticket {ticket_number} </DialogTitle>
            <DialogContent>
                { header (history, classes) }
                { jobs (history, classes) }
                { extraFields (history, classes) }
                { photoGalery (history, classes) }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>

    )



}

export default withStyles(pageStyle)(TicketHistory);