import React from 'react'
import dayjs from 'dayjs';
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import { red, yellow, green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';
import { Icon } from '@material-ui/core';

import { pageStyle } from "../styles/styles.js";
import AbstractAbmPage from './AbstractAbmPage.js';
import { API_COMMAND_RTU_LIST, API_COMMAND_RTU_ACTIVATE, API_COMMAND_RTU_CANCEL } from '../constants/api';
import { GREENLABS_GREEN } from '../constants/ui';

const GreenBadge = withStyles( () => ({ badge: {backgroundColor: GREENLABS_GREEN} }))(Badge);


class CommandsPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = null;
        this.strings = {
            createItem: '',
            editItem: '',
            itemCreationSuccess: '',
            itemUpdateSuccess: '',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el comando seleccionado',
                save: 'Ocurrió un error al comando. Intente nuevamente.',
            }
        }
        
        this.urls = {
            list: API_COMMAND_RTU_LIST,
            item: null,
            toggle: API_COMMAND_RTU_ACTIVATE,
            delete: API_COMMAND_RTU_CANCEL,
            create: null,
            update: null,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 2,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'rtu_mac', field: 'rtu_mac', title: 'MAC', sorting: true, render: (elem) => elem.rtu.mac||''},
                {id: 'command', field: 'command', title: 'Comando', sorting: true, render: (elem) => {
                    const title = elem.from_tls?'Despachado desde TLS':'Despachado desde TLSM';
                    return (
                        <GreenBadge variant="dot" badgeContent={elem.from_tls?1:0} title={title} overlap="rectangular">
                            <Typography title={title}>{elem.command.description}</Typography>
                        </GreenBadge>
                    );
                }},
                {id: 'created_at', field: 'created_at', title: 'Fecha de creación', sorting: true, render: (element) => dayjs(element.created_at).format('DD/MM/YYYY HH:mm:ss')},
                {id: 'uploaded_at', field: 'uploaded_at', title: 'Fecha de descarga', sorting: true, render: (element) => element.uploaded_at && dayjs(element.uploaded_at).format('DD/MM/YYYY HH:mm:ss')},
                {id: 'executed_at', field: 'executed_at', title: 'Fecha de ejecución', sorting: true, render: (element) => element.executed_at && dayjs(element.executed_at).format('DD/MM/YYYY HH:mm:ss')},
                {id: 'cancelled_at', field: 'cancelled_at', title: 'Fecha de cancelación', sorting: true, render: (element) => element.cancelled_at && dayjs(element.cancelled_at).format('DD/MM/YYYY HH:mm:ss')},
                {id: 'status', field: 'status', title: 'Estado', sorting: false, render: (element) => {
                    let txt = '';
                    let deco = {};
                    let icon = '';
                    let result = [];
                    
                    if (element.cancelled) {
                        txt = 'Cancelado';
                        deco = { color: red[500] };
                        icon = 'cancel';
                        result.push(<Icon style={deco}  title={txt}>{icon}</Icon>);
    
                    } else {
                        if (element.executed) {
                            txt = 'Comando ejecutado';
                            deco = { color: green[500] };
                            icon = 'check_circle_outline';
        
                        } else if (element.uploaded) {
                            txt = 'Comando descargado, pendiente de ejecución';
                            deco = { color: green[500] };
                            icon = 'cloud_download';
                        
                        } else {
                            txt = element.active?'Comando pendiente de descarga':'Pausado';
                            deco = element.active?{ color: green[500] }:{ color: yellow[500] };
                            icon = element.active?'hourglass_empty':'pause_circle_outline';
        
                        }
                        result.push(<Icon style={deco}  title={txt}>{icon}</Icon>);
                    }
    
                    return result;
                }}
            ],
            actions:[
                (element) => ({
                    icon: 'download',
                    tooltip: element.has_param?'Descargar parámetros':'El comando no tiene parámetros',
                    disabled: ! element.has_param,
                    onClick: (event, element) => { this.handleDownloadParameter(element) }
                }),
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Pausar comando':'Activar comando',
                    disabled: element.cancelled || element.executed || element.uploaded,
                    onClick: (event, element) => { this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: element.cancelled?'El comando ya fue cancelado':'Cancelar comando',
                    disabled: element.cancelled || element.executed,
                    onClick: (event, element) => { this.handleCancelCommand(element.id, element.rtu.mac, element.command.description, !! element.uploaded_at) }
                }),
            ]
        };    
    }

    handleCancelCommand = (id, mac, description, isUploaded = false) => {
        let caption = `¿Desea cancelar el comando ${description} de la RTU ${mac}?`;
        if (isUploaded) caption = `Está a punto de cancelar un comando que fue enviado al equipo y puede ocasionar un comportamiento inesperado. ¿Desea cancelar el comando ${description} de la RTU ${mac}?`;
        this.handleDelete (id, caption);
    }

    handleDownloadParameter = (element) => {
        const txt = element.param;
        const filename = `${element.rtu.mac} - ${element.command.description}.txt`;
        const hiddenElement = document.createElement('a');

        hiddenElement.href = `data:attachment/text,${encodeURI(txt)}`;
        hiddenElement.target = '_blank';
        hiddenElement.download = filename;
        hiddenElement.click();
    }
}

const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(CommandsPage));

