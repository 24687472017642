import React, { Component } from 'react'

import BlockBody from './BlockBody.js';

class PowerInfo extends Component {

    render = () => {
        const {bat_stat, vbat, vext} = this.props.data.power;
        
        return (
                <BlockBody fields={[
                    {name: 'Estado de la batería', value: bat_stat},
                    {name: 'Vbat', value: vbat},
                    {name: 'Vext', value: vext},
                ]}/>
        )
    }

}

export default PowerInfo;