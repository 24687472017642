import React, { useCallback, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core';

import AbstractForm from './AbstractForm'

import useIconPainter from '../../hooks/useIconPainter';
import useSelectObject from '../../hooks/useSelectObject';
import ColorPicker from '../ColorPicker';
import IconGallery from '../IconGallery';

import { loadIcons } from '../../helpers/loadIcons';

const COLOR_ICON_RED = '#ff5252';
const COLOR_ICON_GREEN = '#0f9d58';

const schema = {
    title: '',
    fields: [
        {
            name: 'id',
            type: 'hidden',
            label: 'Id',
        },
        {
            name: 'description',
            type: 'text',
            label: 'Nombre',
            required: true,
            disabled: false,
            autoFocus: true,
        },
    ]
};

const RtuTypeForm = (props) => {

    const {onCreate, onUpdate, ...otherProps} = props;

    const iconLibrary = useSelectObject([], 'name', props?.values?.icon_template||null);
    const onlineIconPainter = useIconPainter(null, props?.values?.online_color||COLOR_ICON_GREEN);
    const offlineIconPainter= useIconPainter(null, props?.values?.offline_color||COLOR_ICON_RED);

    useEffect(async () => {        
        const icons = await loadIcons();
        iconLibrary.setItems(icons);
        (iconLibrary.key === null) && iconLibrary.selectByKey(icons[0].name);
        onlineIconPainter.setSelectedIcon(iconLibrary.item?.data);
        offlineIconPainter.setSelectedIcon(iconLibrary.item?.data);
    },[])

    useEffect(() => {
        onlineIconPainter.setSelectedIcon(iconLibrary.item?.data);
        offlineIconPainter.setSelectedIcon(iconLibrary.item?.data);
    }, [iconLibrary.item])


    const iconInfo = {
        template: iconLibrary?.item?.name,
        onlineIcon: onlineIconPainter.paintedIcon,
        offlineIcon: offlineIconPainter.paintedIcon,
        onlineColor: onlineIconPainter.selectedColor,
        offlineColor: offlineIconPainter.selectedColor,
    }

    return (
        <AbstractForm
            schema={schema}
            onCreate={(form) => onCreate({...form, ...iconInfo})}
            onUpdate={(form, id) => onUpdate({...form, ...iconInfo}, id)}
            {...otherProps}
        >
            <>
                <Typography variant="h5" style={{paddingTop: '30px'}}>Iconos</Typography>
                <IconGallery
                    items={iconLibrary.items}
                    onItemSelect={ (item) => iconLibrary.selectByKey(item.name) }
                />
                <ColorPicker
                    label="Online"
                    defaultValue={onlineIconPainter.selectedColor}
                    previewIcon={onlineIconPainter.paintedIcon}
                    onChange={onlineIconPainter.setSelectedColor}
                    originalColor={COLOR_ICON_GREEN}
                />
                <ColorPicker
                    label="Offline"
                    defaultValue={offlineIconPainter.selectedColor}
                    previewIcon={offlineIconPainter.paintedIcon}
                    onChange={offlineIconPainter.setSelectedColor}
                    originalColor={COLOR_ICON_RED}
                />
            </>
        </AbstractForm>
    )
}
export default RtuTypeForm;
