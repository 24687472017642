import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { Minimal } from '../layouts';
import {dec2hex, hex2float} from '../helpers/convert';
import { API_DEMO } from '../constants/api';
import { Container, Grid } from '@material-ui/core';
import dayjs from 'dayjs';
import SimpleCard from '../components/SimpleCard';
import SimpleList from '../components/SimpleList';

const decodeVector = (vector) => {
    const length = vector.length;
    let result = null;
    let tmp = null;

    switch (length) {
        case 4:
            tmp = '0x' + dec2hex(vector[2]) + dec2hex(vector[3]) + dec2hex(vector[0]) + dec2hex(vector[1]);
            result = [{ field: 'Total', unit: 'KWh', value: hex2float(tmp)/100}];
            break;

        case 12:
            result = [];
            for (let i = 0 ; i < 3 ; i++) {
                const offset = i * 4;
                tmp = '0x' + dec2hex(vector[2 + offset]) + dec2hex(vector[3 + offset]) + dec2hex(vector[0 + offset]) + dec2hex(vector[1 + offset]);
                result.push(hex2float(tmp));    
            }

            break;
    
        case 36:
            result = [];
            const iter = [ {field: 'v1_n', index: 0, unit: 'V'}, {field: 'v2_n', index: 1, unit: 'V'}, {field: 'v3_n', index: 2, unit: 'V'}, {field: 'i1', index: 6, unit: 'A'}, {field: 'i2', index: 7, unit: 'A'}, {field: 'i3', index: 8, unit: 'A'}];

            for (let i = 0 ; i < iter.length ; i++ ) {
                const {field, index, unit} = iter[i];
                const offset = index * 4;
                
                tmp = '0x' + dec2hex(vector[2 + offset]) + dec2hex(vector[3 + offset]) + dec2hex(vector[0 + offset]) + dec2hex(vector[1 + offset]);
                tmp = hex2float(tmp).toFixed(3);
                result.push ( { field, unit, value: tmp });
            }
            break;

        default:
            break;
    }
    
    return result;
}

const processDebugData = (data) => {
    const result = [];

    const modbuses = data.data.modbus;
    const items = modbuses.length -1; //Saco la tabla de demo
    
    for (let i = 0 ; i < items ; i ++) {
        let modbus = {...(modbuses[i])};
        const vector = modbus.Data;
        const decoded = decodeVector (vector);
        modbus.decoded = decoded;
        result.push (modbus);
    }
    return result;
}

const translate = (txt) => {
    let result = '';
    switch (txt) {
        case 5000:
        case '5000':
            result = 'Actual';
            break;

        case 5001:
        case '5001':
            result = 'Máximos';
            break;
        
        case 103:
        case '103':
            result = 'Energía';
            break;

        case 'v1_n':
            result = 'V1';
            break;
        
        case 'v2_n':
            result = 'V2';
            break;

        case 'v3_n':
            result = 'V3';
            break;
    
        case 'i1':
            result = 'I1';
            break;

        case 'i2':
            result = 'I2';
            break;

        case 'i3':
            result = 'I3';
            break;
        default:
            result = txt;
            break;
    }
    return result;
}
const processDisplayData = (dd) => {
    const result = dd.map( item => {
        let row = {};
        row.address = item.Address;
        row.name = translate(row.address);
        row.online = item.online;
        row.values = item.decoded.map ( e => ({label: translate(e.field), value: `${e.value} ${e.unit}`}));
        return row;
    });

    return result;
}

const DemoPage = (props) => {
    const [data, setData] = useState(null);
    const [displayData, setDisplayData] = useState(null);

    useEffect( () => {
        console.log('Start interval');
        const interval = setInterval( async () => {

            //Acá pido los datos nuevos
            try {                
                let fetch = await axios.get(API_DEMO);
                fetch = fetch.data||{};

                if ( fetch?.id && (fetch.id === data?.id) ) {
                    console.log ('No hay datos nuevos', fetch?.id , data?.id);
                    return;
                }
                
                //Si el ID es distinto, entonces procesar
                const processed = processDebugData(fetch);

                //Actualizar
                setData (fetch);
                setDisplayData(processed);
    
                console.log(processed);
                
            } catch (error) {
                console.error (error);
            }

        }, 5000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    const loading = (displayData === null);

    if (loading) 
        return (
            <Minimal>
                <div
                style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
                >
                <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </div>
            </Minimal>
        );
  
    const headerData = [
        {id: 0, title: 'Mac', value: data.rtu.mac},
        {id: 1, title: 'Nombre', value: data.rtu.name},
        {id: 2, title: 'Versión de Hw / Sw', value: `${data.data.info['hw version']} / ${data.data.info['sw version']}`},
        {id: 3, title: 'Última actualización', value: dayjs(data.timestamp).format('DD/MM/YYYY HH:mm:ss')}        
    ]

    const modbusData = loading?[]:processDisplayData(displayData);

    return (
        <Minimal>            
            <Container>
                { /* Header */ }
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    style={{paddingTop: '20px', paddingBottom: '20px'}}
                >
                    {
                        headerData.map ( e => ( 
                            <Grid item key={`row_header_${e.id}`}>
                                <SimpleCard label={e.title} value={e.value} key={`row_header_card_${e.id}`}/>
                            </Grid>)
                        )
                    }
                </Grid>

                { /* Modbus */ }
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="flex-start"                    
                >
                    {modbusData.map ( e => {
                        return (
                        <Grid item key={`row_modbus_${e.address}`} xs={3}>
                            <h5>{e.name} ({e.online?'Online':'Offline'})</h5>
                            <SimpleList items={e.values} />
                        </Grid>
                    )})}


                </Grid>
            </Container>

        </Minimal>
    );
}

export default DemoPage;