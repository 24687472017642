import SimpleForm from './SimpleForm';
import PropTypes from 'prop-types';
import { useState } from 'react';


const onSubmit = async (form, props, setWorking, onBeforeSubmit = null) => {
    setWorking(true);
    try {
        if (onBeforeSubmit && typeof onBeforeSubmit === 'function') await onBeforeSubmit(form);

        props.isNew ? await props.onCreate (form) : await props.onUpdate (form, form.id);
        
    } catch (error) {
        const response = error?.response;
        if (props.onSubmitError && typeof props.onSubmitError === 'function') props.onSubmitError (response, error);
    }
    setWorking(false);
}

const AbstractForm = (props) => {
    let {disableSaveButton, ...myprops} = {...props};

    

    const [working, setWorking] = useState(false);
    
    /*
    myprops.schema = (values) => {
        let schema = {fields: [
            {
                key: 'id',
                name: 'id',
                type: 'hidden',
            },
            {
                key: 'description',
                name: 'description',
                type: 'text',
                label: 'Nombre del cliente',
                required: true,
                disabled: false,
                autoFocus: true,
            },
            {
                key: 'active',
                name: 'active',
                type: 'switch',
                label: 'Activo',
            }
        ]};
        return schema;
    };
    */
    myprops.onSubmit = (values) => onSubmit(values, myprops, setWorking, myprops.onBeforeSubmit||null);
    myprops.onCancel = myprops.onClose;
    myprops.disableSaveButton  = disableSaveButton || working;

    return (
        SimpleForm(myprops)
    );
}


AbstractForm.propTypes={    
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onSubmitError: PropTypes.func,
    disableSaveButton: PropTypes.bool,
    isNew: PropTypes.bool.isRequired,
    values: PropTypes.object,
    schema: PropTypes.any.isRequired,
}

export default AbstractForm;