import React from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Form from './components/form';

const RtuSetup = (props) => {
    const {form, rtu, readOnly, open, onClose, onSave} = props;

    if (form === null || rtu === null || !open) return null;

    return (
        <>
            <TableContainer compoenent={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Mac</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Ver. Firmware</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{rtu.mac}</TableCell>
                            <TableCell>{rtu.friendly_name}</TableCell>
                            <TableCell>{rtu.fw_ver}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Form setup={rtu.config} form={form} onSubmit={ config => onSave ( rtu.id, config) } onClose={onClose} readonly={readOnly}/>
        </>
    )

}

RtuSetup.propTypes = {
    open: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    rtu: PropTypes.object,
    form: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}

export default RtuSetup;