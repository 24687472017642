import React from 'react'
import { Select as SelectControl, FormGroup, MenuItem, InputLabel, Box } from '@material-ui/core';

const MultiSelect = ({onChange, value, ...props}) => {
    if (value.length === 0) value = [];
    if (! Array.isArray(value)) value = [value];
    return (
        <Box pt={3}>
            <FormGroup row>
                <InputLabel id="demo-simple-select-label">{props.label||''}</InputLabel>
                <SelectControl
                    id={`field_inner_${props.name}`}
                    key={`field_inner_${props.name}`}
                    name={props.name}                
                    onChange={ (ev) => onChange( ev.target.name, ev.target.value ) }
                    fullWidth
                    value={value}
                    multiple
                    {...props}
                >
                    { Object.entries(props.options||{}).map( (item) => (<MenuItem key={`field_inner_${props.name}_${item[0]}`} value={item[0]}>{item[1]}</MenuItem> )) }
                </SelectControl>
            </FormGroup>
        </Box>
    )
}

export default MultiSelect;