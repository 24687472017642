import {createAction} from 'redux-actions';
export const ACTION_LOGOUT_USER = 'ACTION_LOGOUT_USER';
export const ACTION_SET_USER_LOGON = 'ACTION_SET_USER_LOGON';
export const ACTION_UPDATE_POSITION = 'ACTION_UPDATE_POSITION';

//export const ACTION_DO_USER_LOGIN = 'ACTION_DO_USER_LOGIN';

export const logoutUser = createAction (ACTION_LOGOUT_USER);
export const setUserLogon = (payload) => ({type: ACTION_SET_USER_LOGON, payload});
export const updatePosition = (payload) => ({type: ACTION_UPDATE_POSITION, payload});

/*
esta acción genera una promise, y en cuanto se termina de ejecutar, recién invoca a la acción en el reducer
const doUserLogon = createAction(ACTION_DO_USER_LOGIN, (user,pass) => {
    fetch('URL').then(v => v.json());
})
/*

/*
Esta acción usa thunk. toma un array de ciudades en payload, itera y despacha acciones.
El parámetro getState es opcional, y si lo pongo, puedo obtener el estado actual. 
export const setWeather = payload => {
    return (dispatch, getState) => {
        payload.forEach(city => {
            dispatch(getWeatherCity(city));

            const url = getWeatherUrlByCity (city);
            dispatch(setLoadingFlag, true);

            const currentState = getState();

            fetch(url).then(resolve => {
                if (resolve.ok) {
                    return resolve.json();
                } else {
                    throw resolve.error;
                }
    
            }).then(data => {
                const newData = weatherTranslator(data);
                dispatch(setWeatherCity({city,weather: newData}));
                dispatch(setLoadingFlag, false);

            }).catch(error => {
                dispatch(setLoadingFlag, false);
                console.log(error);
                debugger;
            })
        })
    }
}
*/