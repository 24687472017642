import React from 'react'
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';

import { pageStyle } from "../styles/styles.js";
import StateDialog from '../components/StateDialog';
import AbstractAbmPage from './AbstractAbmPage.js';
import { API_EVENT, API_EVENT_LIST} from '../constants/api';
import loggedUserServerFetch from '../services/loggedUserServerFetch';

class EventsPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = null;
        this.strings = {
            createItem: '',
            editItem: '',
            itemCreationSuccess: '',
            itemUpdateSuccess: '',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el evento seleccionado',
                save: '',
            }
        }
        
        this.urls = {
            list: API_EVENT_LIST,
            item: null,
            toggle: null,
            delete: null,
            create: null,
            update: null,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'created_at', field: 'created_at', title: 'Fecha y hora', sorting: true, render: (element) => element.created_at && dayjs(element.created_at).format('DD/MM/YYYY HH:mm:ss')},
                {id: 'mac', field: 'mac', title: 'MAC', sorting: true, render: (element) => element?.rtu?.mac },
                {id: 'description', field: 'description', title: 'Descripción', sorting: true, },
            ],
            actions:[
                (element) => ({
                    icon: 'favorite_icon',
                    tooltip: 'Ver estado que disparó el evento',
                    onClick: (event, element) => { this.handleDisplayState(element.id, element.state) },
                }),
            ]
        };
        
        this.state.state_display = null;
        this.state.state_display_loading = false;
    }

    handleDisplayState = async (id, oState) => {
        let { state, local } = oState;
        if (local) {
            this.setState ( {...this.state, state_display: state, state_display_loading: false} );

        } else {
            //El estado está en mongo, lo tengo que ir a buscar
            try {

                await this.setStateAsync ( { ...this.state, state_display_loading: true, state_display: {}});
                const url = API_EVENT.replace('{$id}', id);
                const { data } = await loggedUserServerFetch (url, 'get');                
                this.setState ( {...this.state, state_display: {id, ...data?.state?.state}, state_display_loading: false} );
    
            } catch (error) {
                toast.error ('Ocurrió un error obteniendo la información del estado');
                console.error(error);
            }
    
        }
        
    }

    handleCloseDisplayState = () => {
        this.setState ( {...this.state, state_display: null} );
    }

    postRender = () => {
        return(
            <StateDialog
                open = { !! this.state.state_display }
                title = { `Estado de ${this.state?.state_display?.id} - ${this.state?.state_display?.time||'Sin timestamp'}` }
                state = { this.state.state_display || {} } 
                name = { this.state.state_display?.id || ''}
                loading = { this.state.state_display_loading}
                onCloseClick = { this.handleCloseDisplayState }
            />
        )
    }
}

const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(EventsPage));

