import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";

import { getUpdateInterval } from '../redux/reducers/ui';
import { pageStyle } from "../styles/styles.js";

import AbstractAbmPage from './AbstractAbmPage.js';
import { API_RTU_TYPE, API_RTU_TYPE_DELETE, API_RTU_TYPE_LIST, API_RTU_TYPE_CREATE, API_RTU_TYPE_EDIT } from '../constants/api';
import RtuTypeForm from '../components/forms/RtuTypeForm';

class RtuTypePage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = RtuTypeForm;
        this.strings = {
            createItem: 'Nuevo tipo de RTU',
            editItem: 'Editar tipo de RTU',
            itemCreationSuccess: 'Tipo de RTU creado exitosamente',
            itemUpdateSuccess: 'Tipo de RTU actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el tipo de RTU seleccionado',
                save: 'Ocurrió un error al guardar el tipo de RTU. Intente nuevamente.',
            }
        }
        
        this.urls = {
            list: API_RTU_TYPE_LIST,
            item: API_RTU_TYPE,
            toggle: null,
            delete: API_RTU_TYPE_DELETE,
            create: API_RTU_TYPE_CREATE,
            update: API_RTU_TYPE_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'description', field: 'description', title: 'Nombre', sorting: true, },
                {id: 'icon', field: '', title: 'Iconos', sorting: false, render: e => {
                    return (
                        <><img src={e.online_icon_url} height={32}/> <img src={e.offline_icon_url} height={32}/></>
                    )
                }},
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo tipo de RTU',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: () => {  this.handleEdit(element.id) },
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar el tipo de RTU ${element.description}?`) }
                }),
            ]
        };

        this.popupMaxWidth = 'sm';
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(RtuTypePage));


