import axios from 'axios';

import { API_PASSOWRD_RECOVERY_ISSUE, 
    API_PASSWORD_RECOVERY_CANCEL, 
    API_PASSWORD_RECOVERY_UPDATE,
    API_PASSWORD_RECOVERY_CHECK } from "../constants/api";

const sender = (url, method = 'GET', payload={}) => {
    return axios({
        url: url,
        method: method,
        data: payload,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export const issuePasswordRecovery = (email) => {
    return sender(API_PASSOWRD_RECOVERY_ISSUE, 'POST', {email});
};

export const cancelPasswordRecovery = (tag) => {
    return sender(`${API_PASSWORD_RECOVERY_CANCEL}/${tag}`, 'DELETE', )
}

export const setPassword = (tag, email, password, close) => {
    return sender(API_PASSWORD_RECOVERY_UPDATE, 'PUT', {'tag': tag, 'email': email, 'pass': password, 'close':close});
}

export const isTokenValid = (tag) => {
    return sender(`${API_PASSWORD_RECOVERY_CHECK}/${tag}`, 'GET', );
}