import React from 'react'
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';

const FormBackButton = (props) => {
    const { title, color, onClick, label } = props;

    return (
        <Button
            type="button"
            variant="contained" 
            color={color || "default"}
            title={title || 'Cerrar'}
            onClick={onClick}
            startIcon={<ArrowBack />}
    >{label||'Volver'}</Button>);
}

FormBackButton.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string
}

export default FormBackButton;