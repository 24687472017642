import { TextField } from "@material-ui/core";

const InputNumber = (props) => {
    const {description, name, value, min, max, readonly = false} = props;
    return (
        <TextField
            id = {`setup_component_${name}`} 
            type = "number"
            label = {description} 
            name = {name} 
            defaultValue = {value}
            readonly = {readonly}
            inputProps={ {min, max} }
            fullWidth
        />
    )
}

export default InputNumber;