import React from 'react'
import RtuState from '../../components/RtuState';

const renderHeader = (rtu) => {
    const {mac, name, type = {}} = rtu;
    return (
        <div align="right">
            <h3>
                {mac && <span><small>MAC:</small>{mac}</span> }
                {name && <span><small> Nombre:</small>{name}</span> }
                { type.id && <span><small> Tipo:</small>{type.description}</span>}
            </h3>
        </div>
    )
}
    
const renderBody = (rtu) => {
    const {state} = rtu;
    let result = 'La RTU todavía no informó estado';

    if (state)
        result = <div style={{paddingTop: 20}}><RtuState state = { rtu.state || {} } name = { rtu.name || rtu.mac || ''} /></div>

    return result;
}

const rtuInfoWindowBuilder = (rtu) => {

    //const userRole = getUserPermission(store.getState()).slice(0).shift();
    
    return [renderHeader(rtu), renderBody(rtu)];
}

export default rtuInfoWindowBuilder;