import React  from 'react'
import { TableRow, TableCell } from '@material-ui/core';

const BlockBody = (props) => {
    const {fields} = props;
    return (
        <tbody>
            {fields.map( (item) => {
                return(<TableRow><TableCell>{item.name}</TableCell><TableCell align="right">{item.value}</TableCell></TableRow>)
            })}
        </tbody>
    );
}

export default BlockBody;