import { Avatar, Grid, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { Component } from 'react'
import { green, red } from '@material-ui/core/colors';

class IoInfo extends Component {

    render = () => {
        const inputs = this.props.data.inputs;

        const buildCell = (value, index) => {
            
            const color = (value)? green[500] : red[500];
            const description = `Entrada ${index}`;

            return (
                <Grid item>
                    <Avatar style={ { backgroundColor: color, height: '24px', width: '24px' }} alt={description} title={description}>{index}</Avatar>
                    
                </Grid>
            )
        }
        
        return (
            <TableBody>
                <TableRow>
                    <TableCell colSpan={2}>

                        <Grid
                            container
                            spacing={1}                            
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            >
                                { inputs.map ( (value, index) => buildCell (value, index))}
                            
                        </Grid>

                    </TableCell>
                </TableRow>
            </TableBody>
       )
    }

}

export default IoInfo;