import { useCallback, useEffect, useState } from "react";
import PropTypes from 'prop-types';

const useSelectObject = (items, key, selectedKey = null) => {
    
    const searchIndex = useCallback ( (value, elements) => {
        if (value === null) return null
        const index = elements.findIndex(element => element[key] === value);
        return index > -1 ? index : null;
    },[]);

    const selectByIndex = useCallback ( (index) => {
        setValue( index === null? null : elements[index][key] );
    });

    const [elements, setElements] = useState(items||[]);    
    const [value, setValue] = useState(selectedKey);
    const [index, setIndex] = useState(searchIndex(value, elements));
    const [element, selectElement] = useState( index === null? null : elements[index] || null);

    useEffect ( () => {    
        const index = searchIndex(value, elements);

        if ( (value === null) || (! elements) || (index === null) ) {
            selectElement(null);
            setIndex(null);            
            return;
        }
        
        selectElement ( elements[index] );
        setIndex (index);

    }, [elements, value])

    return {
        item: element,
        key: value,
        index: index,

        items: elements,
        setItems: setElements,

        selectByKey: setValue,
        selectByIndex,
    }
}

useSelectObject.propTypes = {
  items: PropTypes.array.isRequired,
  key: PropTypes.string,
  selectedKey: PropTypes.string
};

export default useSelectObject;