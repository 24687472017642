import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './components/routes/PrivateRoute';
import ProtectedRoute from './components/routes/ProtectedRoute';
import PublicRoute from './components/routes/PublicRoute';

//import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { Main as MainLayout } from './layouts';

import { USER_IS_ROOT } from './constants/profiles';
import { LOGIN_URL, LOGOUT_URL, HOME_URL, PASSWORD_RECOVER_URL, PASSWORD_URL, USER_URL, INSTANCE_URL, RTU_URL, ASSEMBLY_URL, 
  DELIVERY_URL, FIRMWARE_URL, OTA_URL, TRIGGERS_URL, EVENTS_URL, COMMAND_URL, MAP_URL, API_KEY_URL, RTU_TYPE_URL, SETUP_URL, DEMO_URL } from './constants/url';
import { MIN_PASSWORD_LENGTH } from './constants/ui';

import LoginPage from './pages/LoginPage';
import WelcomePage from './pages/WelcomePage';
import PasswordRecoverPage from './pages/PasswordRecoverPage';
import E404Page from './pages/error/E404Page';
import PasswordPage from './pages/PasswordPage';
import UsersPage from './pages/UsersPage';
import InstancesPage from './pages/InstancesPage';
import RtusPage from './pages/RtusPage';
import AssembliesPage from './pages/AssembliesPage';
import DeliveriesPage from './pages/DeliveriesPage';
import FirmwaresPage from './pages/FirmwaresPage';
import OtasPage from './pages/OtasPage';
import TriggersPage from './pages/TriggersPage';
import EventsPage from './pages/EventsPage';
import CommandsPage from './pages/CommandsPage';
import RtuMapPage from './pages/RtuMapPage';
import ApiKeyPage from './pages/ApiKeyPage';
import RtuTypePage from './pages/RtuTypePage';
import SetupPage from './pages/SetupPage';
import DemoPage from './pages/DemoPage';

const Routes = (props) => {

  const {isAuthenticated, profiles, logout} = props;
  return (
    <Switch>
      <Route exact path={DEMO_URL} component={DemoPage} isAuthenticated={isAuthenticated} redirect={HOME_URL} success_redirect={HOME_URL} />
      <PublicRoute exact path={LOGIN_URL} component={LoginPage} isAuthenticated={isAuthenticated} redirect={HOME_URL} success_redirect={HOME_URL} />

      <PublicRoute exact path={PASSWORD_RECOVER_URL} component={PasswordRecoverPage} isAuthenticated={isAuthenticated} redirect={HOME_URL} />
      <PublicRoute path={`${PASSWORD_RECOVER_URL}/0/:tag`} component={PasswordRecoverPage} isAuthenticated={isAuthenticated} redirect={HOME_URL} cancel={true}/>
      <PublicRoute path={`${PASSWORD_RECOVER_URL}/1/:tag`} component={PasswordRecoverPage} isAuthenticated={isAuthenticated} redirect={HOME_URL} recover={true} minimum_password_length={MIN_PASSWORD_LENGTH}/>

      <PrivateRoute exact path={LOGOUT_URL} component={logout} isAuthenticated={!!isAuthenticated} redirect={LOGIN_URL} />    

      <PrivateRoute 
        exact 
        path={HOME_URL} 
        component={WelcomePage} 
        isAuthenticated={!!isAuthenticated} 
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <PrivateRoute 
        exact 
        path={PASSWORD_URL} 
        component={PasswordPage} 
        isAuthenticated={!!isAuthenticated} 
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={RTU_URL}
        component={RtusPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={COMMAND_URL}
        component={CommandsPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={ASSEMBLY_URL}
        component={AssembliesPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={DELIVERY_URL}
        component={DeliveriesPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={FIRMWARE_URL}
        component={FirmwaresPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={OTA_URL}
        component={OtasPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={TRIGGERS_URL}
        component={TriggersPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={EVENTS_URL}
        component={EventsPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />
      <ProtectedRoute 
        exact
        path={USER_URL}
        component={UsersPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={INSTANCE_URL}
        component={InstancesPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={MAP_URL}
        component={RtuMapPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />

      <ProtectedRoute 
        exact
        path={API_KEY_URL}
        component={ApiKeyPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />
      <ProtectedRoute 
        exact
        path={RTU_TYPE_URL}
        component={RtuTypePage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />
      <ProtectedRoute 
        exact
        path={SETUP_URL}
        component={SetupPage}
        authorizedProfiles={[USER_IS_ROOT]}
        userProfiles={profiles}
        isAuthenticated={isAuthenticated}
        redirect={LOGIN_URL}
        layout={MainLayout}
      />
      <Route component={E404Page} />
  </Switch>
  )

  /*
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );*/
};

export default Routes;
