import React, { Component } from 'react'
import ReactLoading from 'react-loading';
import { connect } from "react-redux";
import { BrowserRouter as Router, Redirect} from 'react-router-dom';
import Raven from 'react-raven';

import { ToastContainer, toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';

import theme from './theme';
import './styles/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { isUserLoggedOn, getUserPermission } from './redux/reducers/users';
import { isLoading } from './redux/reducers/loading';
import { setIsLoading } from './redux/actions';
import { setFocusState } from './redux/actions/ui';
import { logoutUser, updatePosition } from './redux/actions/users';
import { fetchProfile, doLogout as logoutFromServer } from './services/auth';

import { LOGIN_URL } from './constants/url';

import Routes from './Routes';
import loggedUserServerFetch from './services/loggedUserServerFetch';
import { API_PROFILE_UPDATE_POSITION } from './constants/api';
import Geolocation from './helpers/react-geolocation';

const browserHistory = createBrowserHistory();

class App extends Component{
  constructor (props) {
    super(props);
    this.state = {
      watch_position: null,
    }
    this.props.setLoading(true);
    this.doLogout = this.doLogout.bind(this);
    //this.geolocation = useGeolocation({}, this.updatePosition)


    //Si tengo en el store el token pero no estoy logueado, entonces tengo que obtener el perfil

  }

  doLogout() {
    logoutFromServer();
    this.props.logoutUser();
    toast.success('Se ha cerrado la sesión', {toastId: 'logout'});
    return (<Redirect to={LOGIN_URL}></Redirect>);
  }

  //Handle updates on user geolocaiton
  updatePosition = (oPosition) => {
    this.props.updatePosition (oPosition);
    if (this.props.isUserLoggedOn) {
      loggedUserServerFetch (API_PROFILE_UPDATE_POSITION, 'POST', oPosition);
    }
  }

  onFocusChange  = (evt = null) => {
    const event = evt?.type || 'focus';
    const focus = (event === 'focus');
    this.props.setFocusState(focus);
    
  }

  componentDidMount() {
    window.addEventListener('focus', this.onFocusChange);
    window.addEventListener('blur', this.onFocusChange);
    fetchProfile();
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocusChange);
    window.removeEventListener('blur', this.onFocusChange);
  }

  renderLoading () {
    return (
      <div
        style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>       
    </div>);
  }

  render () {
    const isAuthenticated = this.props.isUserLoggedOn;
    const profiles = this.props.userProfiles;
    
    return (
      <Router>
        <div>
          <Raven dsn="https://4e75917b013a4f10ad3f380a1d96b89d@o392870.ingest.sentry.io/5241160" />
          {this.props.isLoading?
            this.renderLoading()
          :
            <ThemeProvider theme={theme}>
              <Router history={browserHistory}>
                <Routes 
                  isAuthenticated={isAuthenticated}
                  profiles={profiles}
                  logout={this.doLogout}
                />
              </Router>
            </ThemeProvider>
  
          }
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>        
        <Geolocation onUpdateGeolocation={this.updatePosition} />
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedOn: isUserLoggedOn(state),
  isLoading: isLoading(state),
  userProfiles: getUserPermission(state),
});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
  updatePosition: value => dispatch(updatePosition(value)),
  setLoading: value => dispatch(setIsLoading(value)),
  setFocusState: value => dispatch(setFocusState(value)),
});


/*
App.defaultProps = {
  is_logged_on: false, token: null, screen_name: null, permission: []
}
*/

export default connect(mapStateToProps, mapDispatchToProps)(App);
