import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MaterialTable from "@material-table/core";

class TableSelector extends Component {
    constructor (props) {
        super(props);
        
        this.state = {
            selectedItems: props.value || [],
        }

    }

    countSelected = () => {
        let result = null;
        const selected = (this.state.selectedItems||[]).length;

        switch (selected) {
            case 0:
                result = 'No se seleccionaron elementos';
                break;

            case 1:
                result = '1 elemento seleccionado';
                break;

            default:
                result = `${selected} elementos seleccionados`;
                break;
        }
        return <p>{result}</p>;
    }

    updateSelectedAssemblies = (rows = []) => {
        const ids = rows.map ( (e) => e.id);
        const name = this.props.name;
        const onChange = this.props.onChange;
        this.setState( {...this.state, selectedItems: ids}, () => {           
            onChange (name, ids);
        });
        return rows;
    }

    formatItemData = (items = [], selectedItems = []) => {
        const result = (items || []).map ( item => {
            let r = {...item};
            r.tableData = {
                checked: selectedItems.includes( item.id ),
            }
            return r;
        })
        return result;
    }

    render = () => {
        const { options, columns, label } = this.props;
        const { selectedItems } = this.state;
        const tableOptions = this.formatItemData (options, selectedItems);
        const count = this.countSelected();

        return(
            <>
                <br />
                <MaterialTable
                    title={label}
                    columns={columns}
                    options={{search: true, 
                        selection: true, 
                        showTextRowsSelected: false, 
                        paging: false,
                        actionsColumnIndex: -1,
                    }}
                    data={ tableOptions }
                    onSelectionChange={ (rows) => this.updateSelectedAssemblies(rows) }
                    localization={{
                        body: {
                          emptyDataSourceMessage: 'No hay registros'
                        },
                        toolbar: {
                          searchTooltip: 'Buscar',
                          searchPlaceholder: 'Buscar',
                        },
                        pagination: {
                          labelRowsSelect: 'filas por página',
                          labelDisplayedRows: '{count} de {from}-{to}',
                          firstTooltip: 'Primer página',
                          previousTooltip: 'Página anterior',
                          nextTooltip: 'Próxima página',
                          lastTooltip: 'Última página'
                        }
                      }}
            
                />
                {count}
            </>
        )
    }
}

TableSelector.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    elementIdField: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    elements: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default TableSelector;