import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";

import { pageStyle } from "../styles/styles.js";
import { getUpdateInterval } from '../redux/reducers/ui';
import AbstractAbmPage from './AbstractAbmPage.js';
import { API_DELIVERY_LIST, API_DELIVERY_DOCUMENT, API_DELIVERY_CREATE } from '../constants/api';
import DeliveryForm from '../components/forms/DeliveryForm';
import filedownloader from '../helpers/filedownloader.js';
import leadingZeros from '../helpers/leadingZeros';

class DeliveriesPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = DeliveryForm;
        this.strings = {
            createItem: 'Nueva entrega',
            editItem: '',
            itemCreationSuccess: 'Entrega registrada exitosamente',
            itemUpdateSuccess: '',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra la entrega seleccionada',
                save: 'Ocurrió un error al guardar la entrega. Intente nuevamente.',
                E01: 'No se especificó el cliente',
                E02: 'Debe seleccionar al menos un ensamblaje',
                E03: 'No existe el cliente seleccionado',
                E04: 'No existen los ensamblajes seleccionados',
                E05: 'Ocurrió un error al enviar las remotas a TLS. Revise la tabla de equipos para más detalle.',
            }
        }
        
        this.urls = {
            list: API_DELIVERY_LIST,
            item: null,
            toggle: null,
            delete: null,
            create: API_DELIVERY_CREATE,
            update: null,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'id', field: 'id', title:'Número', sorting: true, render: (e) => leadingZeros(e.id) },
                {id: 'created_at', field: 'created_at', title: 'Fecha y hora', sorting: true, render: (element) => element.created_at && dayjs(element.created_at).format('DD/MM/YYYY HH:mm')},
                {id: 'sender', field: 'sender', title: 'Emisor', sorting: true, render: (element) => element.user.name},
                {id: 'dest', field: 'dest', title: 'Receptor', sorting: true, render: (element) => element.crew.description},
                {id: 'customer', field: 'customer', title: 'Cliente', sorting: true, render: (element) => element.instance.description},
                {id: 'qty', field: 'qty', title: 'Equipos', sorting: false, render: (element) => element.rtu?.length},
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nueva entrega',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: 'description',
                    tooltip: 'Descargar remito',
                    onClick: (event, element) => { this.handleDownloadDelivery (element) }
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar la entrega ${leadingZeros(element.id)}?`) }
                }),
            ]
        };    
    }

    handleDownloadDelivery = async (element) => {        
        try {
            const url = (API_DELIVERY_DOCUMENT).replace('{$id}', element.id);
            const filename = `Remito-${leadingZeros(element.id)}.pdf`;
            await filedownloader (url, filename);

        } catch (error) {
            toast.error ('Ocurrió un error al intentar descargar el archivo', 'err_file_download');
        }
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(DeliveriesPage));


