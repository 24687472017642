import React, { Component } from 'react'
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, Link } from '@material-ui/core';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import dayjs from '../../helpers/dayjs';
import { withStyles } from '@material-ui/core/styles';
import Badge from "@material-ui/core/Badge";

import Table from '../Table';
import { API_RTU_HISTORY_LIST, API_HISTORY_CREATE, API_HISTORY_INSTALLATION, API_HISTORY_TICKET } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import '../../styles/swal2.scss';
import { HISTORY_TYPE_NOTE } from '../../constants/misc';
import { pageStyle } from '../styles';
import InstallationHistory from '../InstallationHistory';
import TicketHistory from '../TicketHistory';
import { GREENLABS_GREEN } from '../../constants/ui';

const GreenBadge = withStyles( () => ({ badge: {backgroundColor: GREENLABS_GREEN} }))(Badge);

class RtuHistory extends Component {
    constructor (props) {
        super(props);

        this.columns = [
            {id: 'created_at', field: 'created_at', title: 'Timestamp', sorting: false, render: (element) => element.created_at && dayjs(element.created_at).format('DD/MM/YYYY HH:mm:ss')},
            {id: 'status', field: 'status', title: 'Estado', sorting: false, render: (element) => {
                const title = element.from_tls?'Historia originada en TLS':'Historia originada en TLSM';
                return (
                    <GreenBadge variant="dot" badgeContent={element.from_tls?1:0} title={title}>
                        {element.status}
                    </GreenBadge>
                );
            }},
            {id: 'user', field: 'user.name', title: 'Usuario', sorting: false},
            {id: 'ticket', field: 'ticket', title: 'Ticket', sorting: false, render: (e) => {                
                const number = e?.ticket?.number;
                if (! number) return null;

                return (
                    <Link component="button" 
                        variant="body1" 
                        onClick={ () => {this.displayTicketInfo(e)}}
                    >
                        {number}
                    </Link>       
                )
            }},
            {id: 'installation', field: 'installation', title: 'Instalación', sorting: false, render: (e) => {
                const installation_description = e?.installation?.description;
                if (! installation_description) return null;                

                return (
                    <Link 
                        component="button" 
                        variant="body1"
                        onClick={ () => {this.displayInstallationInfo(e)}}
                    >
                        {installation_description}
                    </Link>
                )
            }},
            {id: 'info', field: 'info', title: 'Info', sorting: false}
        ]

        const timer = setInterval ( () => {
            this.tableRef.current && this.tableRef.current.onQueryChange();
        }, 5000);

        this.state = {
            displayMainDialog: true,
            loading: (! (props.history && props.history.length > 0)),
            timer: timer,
            items: [],

            displayInstallationHistory: false,
            installationHistoryData: null,
            displayTicketHistory: false,
            ticketHistoryData: null,
            
            
            installationId: null,

        }
        this.tableRef = React.createRef();

    }

    componentWillUnmount = () => {
        if (this.state.timer) clearInterval(this.state.timer);
    }

    fetchElements = (query) => {
        const self = this;
        let q = {};
        if (query?.orderBy !== null && query?.orderBy !== undefined) {
            q['sort_field'] = query.orderBy?.field;
            q['sort_order'] = query.orderDirection;
        }

        return new Promise ((resolve,reject) => {
            const url = API_RTU_HISTORY_LIST.replace('{$id}', this.props.rtu.id);
            loggedUserServerFetch (url, 'get', {}, {}, q).then ( (response) => {
                
                const result = {
                    data: response.data||[],
                    //page: 1,
                    //totalCount: response.data.count,
                };
                self.setState( {...self.state, loading: false}, () => resolve(result) );
    
            }).catch ( (error) => {
                console.error(error);
                toast.error('Ocurrió un error obteniendo las historias');
                reject (error);
            })
        })
    }

    createNoteHistory = async () => {
        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputLabel: `Crear una nueva nota para la RTU ${this.props.rtu.mac}`,
            inputPlaceholder: 'Nota...',
            inputAttributes: {
                'aria-label': 'Ingrese una nota'
            },
            showCancelButton: true,
            customClass: {
                container: 'always-on-top',
            },

            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (!! value) {
                      resolve()
                    } else {
                      resolve('No puede crear una nota vacía...')
                    }
                  })
            }

        });

        if (text) {

            const payload = {
                rtu_id: this.props.rtu.id,
                type: HISTORY_TYPE_NOTE,
                info: text,
            }

            loggedUserServerFetch (API_HISTORY_CREATE, 'put', payload, {}, {}).then ( (response) => {
                
                this.tableRef.current && this.tableRef.current.onQueryChange();
                toast.success('Nota creada exitosamente');
    
            }).catch ( (error) => {
                console.error(error);
                toast.error('No se pudo crear la nota');                
            })

        }
    }

    displayTicketInfo = (history) => {
        const url = API_HISTORY_TICKET.replace('{$id}', history.id);
        loggedUserServerFetch (url, 'get', {}, {}, {}).then ( (response) => {
            let ticketHistoryData = response.data;
            ticketHistoryData.history_id = history.id;
            
            this.setState({...this.state, displayTicketHistory: true, ticketHistoryData: ticketHistoryData, installationId: history.installation?.description});

        }).catch ( (error) => {
            console.error(error);
            toast.error('Ocurrió un error al consultar la historia del ticket');
        })

    }

    displayInstallationInfo = (history) => {
        const url = API_HISTORY_INSTALLATION.replace('{$id}', history.id);
        loggedUserServerFetch (url, 'get', {}, {}, {}).then ( (response) => {
            this.setState({...this.state, displayInstallationHistory: true, installationHistoryData: response.data, installationId: history.installation?.description});

        }).catch ( (error) => {
            console.error(error);
            toast.error('Ocurrió un error al consultar la historia de la instalación');
        })

    }

    closeInformationPopups = () => {
        this.setState ( {
            ...this.state, 
            installationHistoryData: null, 
            displayInstallationInfo: false,
            installationId: null,
            displayTicketHistory: false,
            ticketHistoryData: null,

        });
    }

    renderLoading = () => {
        return <div align="center"><CircularProgress /></div>
    }

    render = () => {
        let title = `Historia de la RTU ${this.props.rtu.mac}`;
        if (this.props.rtu.friendly_name) title += ` - ${this.props.rtu.friendly_name}`;

        return (
            <div>                
                <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={this.state.displayMainDialog}
                    onClose={this.props.onClose}
                    aria-labelledby="rtuHistoryDialog"
                    disableEnforceFocus={true}
                >
                    <DialogTitle id="rtuHistoryDialog">{title}</DialogTitle>
                    <DialogContent>
                        {this.state.loading && this.renderLoading()}
                        <Table
                                columns={this.columns}
                                defaultSortColumnIndex={0}
                                defaultSortDirection='desc'
                                data={ query => this.fetchElements( query ) }
                                tableRef={this.tableRef}
                                hidden={this.state.loading}
                                displaySearch={false}
                                tableOptions={{
                                    paging: false,
                                }}
                                actions={[                                
                                    {
                                        icon: 'add',
                                        tooltip: 'Nueva nota',
                                        disabled: ! this.props?.allowAddNotes,
                                        isFreeAction: true,
                                        onClick: this.createNoteHistory,
                                    },
                                ]}    
                            />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">Cerrar</Button>
                    </DialogActions>
                </Dialog>
                <InstallationHistory 
                    open={this.state.displayInstallationHistory}
                    info={this.state.installationHistoryData}
                    installation={this.state.installationId}
                    onClose={this.closeInformationPopups}
                />
                <TicketHistory
                    open={this.state.displayTicketHistory}
                    info={this.state.ticketHistoryData}
                    onClose={this.closeInformationPopups}
                />
            </div>
        )
    }
}

RtuHistory.propTypes = {
    rtu: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    allowAddNotes: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
}

export default withStyles(pageStyle)(RtuHistory);