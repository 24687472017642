import React from 'react'
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';


const IconGallery = ({ items, onItemSelect }) => {

	const elements = (typeof items.map === 'function') ? items : [];

	return (
		<Grid container spacing={2} alignItems="center">
			{elements.map((item, index) => (
				<Grid item key={index}>
					<IconButton onClick={() => onItemSelect(item, index)}>
						<img src={item.data} alt={item.name} title={item.name} style={{ maxHeight: '32px' }} />
					</IconButton>
				</Grid>
			))}
		</Grid>
	);
};

IconGallery.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.string.isRequired
    })
  ).isRequired,
  onItemSelect: PropTypes.func.isRequired
};

export default IconGallery;