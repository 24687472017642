import React from "react";
import PropTypes from "prop-types";
import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubMenu from "./SubMenu";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Icon } from '@material-ui/core';


function renderMenuItemWithIcon (menuItem) {
  return (
    <MenuItem 
      key={menuItem.key} 
      onClick={menuItem.onClick}
      disabled={menuItem.disabled||false}
    >
      <ListItemIcon><Icon>{menuItem.icon}</Icon></ListItemIcon>
      <ListItemText primary={menuItem.caption}/>      
    </MenuItem>
  );

}

function renderMenuItemWithoutIcon (menuItem) {
  return (
    <MenuItem 
      key={menuItem.key} 
      onClick={menuItem.onClick}
      disabled={menuItem.disabled||false}
      style={menuItem.style||{}}
    >
      {menuItem.caption}
    </MenuItem>
  );
}

function renderSubitemsMenu (menuItem) {
  return (
    <SubMenu
      key={menuItem.key}
      caption={menuItem.caption}
      menuItems={menuItem.subMenuItems}
      disabled={menuItem.disabled||false}
      icon={menuItem.icon||null}
    />
  );
}

export default class EnhancedMenu extends React.Component {

  renderMenuItems = () => {

    const { menuItems } = this.props;

    return menuItems.map(menuItem => {
      const hasIcon = menuItem.hasOwnProperty("icon");
      const hasSubItems = menuItem.hasOwnProperty("subMenuItems");

      if (hasSubItems) return renderSubitemsMenu (menuItem);
      if (hasIcon) return renderMenuItemWithIcon (menuItem);
      return renderMenuItemWithoutIcon (menuItem);      
    })
  };          

  render() {
    const { anchorElement, open, onClose, ...others } = this.props;
    return (
      <MuiMenu
        {...others}
        anchorEl={anchorElement}
        open={open}
        onClose={onClose}
      >
        {this.renderMenuItems()}
      </MuiMenu>
    );
  }
}

EnhancedMenu.propTypes = {
  anchorElement: PropTypes.any,
  menuItems: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
