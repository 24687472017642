import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import RtuState from '../components/RtuState';


const StateDialog = (props) => {
    if (! props.open) return null;    
    const { loading = false, title } = props;

    if (loading) 
        return <>
            <Dialog
                fullWidth = { true }
                open = { props.open }
            >
                <DialogTitle id = 'title'>Cargando estado...</DialogTitle>
                <DialogContent>                
                    <div
                        style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <Box mx="auto"><CircularProgress /></Box>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ props.onCloseClick } color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>


    return (
        <Dialog
            fullWidth = { true }
            open = { props.open }
        >
            <DialogTitle id = 'title'>{title}</DialogTitle>
            <DialogContent>                
                <RtuState state = { props.state || {} } name = { props.name || ''} />
            </DialogContent>
            <DialogActions>
                <Button onClick={ props.onCloseClick } color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default StateDialog;