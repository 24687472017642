import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { Component } from 'react'

class BlockHeader extends Component {

    render = () => {
        const {title} = this.props;
        return(
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2} className={this.props.className||''}>{title}</TableCell>
                </TableRow>
            </TableHead>            
        )
    }
}

export default BlockHeader;