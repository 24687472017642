import React, { Component } from 'react'
import Table from '../Table';
import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import RtuActionDropdown from '../RtuActionDropdown';
import dayjs from '../../helpers/dayjs';
import RtuStatusIcons from '../RtuStatusIcons';
import RtuInfoStatus from '../RtuInfoStatus';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { yellow } from '@material-ui/core/colors';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

class RtuTable extends Component {
    constructor (props) {
        super(props);
        this.cols = [
            {id: 'mac', field: 'mac', title: 'MAC', sorting: true, },
            {id: 'friendly_name', field: 'friendly_name', title: 'Nombre', sorting: true, render: elem => {
                const icon = <IconButton 
                    title="Cambiar nombre" 
                    aria-label={`Cambiar nombre de la remota ${elem.mac}`}
                    key={`rtu_edit_name_${elem.id}`} 
                    onClick={ () => this.props.onRename(elem)}
                    color="default"
                    size="small"
                ><EditIcon /></IconButton>;

                return (
                    <div onMouseEnter={ () => this.handleMouseOverMac (elem.id)} onMouseLeave={ () => this.handleMouseOverMac(null)}>
                        {elem.friendly_name}
                        {( (this.state.hovering === elem.id) || (! elem.friendly_name )) && icon}
                    </div>
                )
            }},
            {id: 'imei', field: 'imei', title: 'IMEI', sorting: true, },
            {id: 'rtu_type', field: 'rtu_type.description', title: 'Tipo', sorting: true, },
            {id: 'status', field: 'status', title: 'Estado', sorting: true, render: (element) => <RtuInfoStatus status={element.status}/>},
            {id: 'created_at', field: 'created_at', title: 'Creación', sorting: true, render: (element) => element.created_at && dayjs(element.created_at).format('DD/MM/YYYY HH:mm')},
            {id: 'usr_state', field: 'usr_state', title: 'Operación', sorting: false, render: (e) =>(
                <RtuStatusIcons  
                    rtu_info={e} 
                    onLinkClick={e.last_state ? () => this.props.onViewState(e.id, e.last_state) : null } 
                />
            )},
            {id: 'fw_ver', field: 'fw_ver', title: 'Firmware', sorting: true, render: (element) => {
                if (! element?.firmware?.id) {                    
                    return (
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    El firmware de este equipo no se encuentra en la base de datos
                                </React.Fragment>
                            }
                        >
                            <span>{element.fw_ver} <ErrorIcon fontSize="small" style={{color: yellow[500]}}/></span>
                        </HtmlTooltip>
                    );
                } else {
                    const {firmware} = element;
                    return (
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <strong>Descripción</strong> {firmware.description} <br/>
                                    <strong>Bytes</strong> {firmware.size} <br/>
                                    <strong>CRC32</strong> {firmware.crc32} <br/>
                                    <strong>Compilación</strong> {firmware.compiled_at} <br/>
                                </React.Fragment>
                            }
                        >
                            <span>{element.fw_ver}</span>
                        </HtmlTooltip>
                    );    
                }
            }},

            {id: 'signal_level', field: 'signal_level', title: 'Señal', sorting: false, render: (element) => {
                if (! element.last_state) return null;
                const simcard = element.last_state.modem.simcard;
                let sim, url, user, pass, socket = null;

                simcard.some ( (e, index) => {
                    if (e.status === 'SIM READY') {
                        socket = index;
                        sim = e.ccid;
                        ({url, user, pass} = e.apn);
                        return true;
                    }
                    return false;
                });

                return (
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <strong>{url}</strong><br/><br/>
                                <strong>Sim card</strong> {sim} <br/>
                                <strong>Socket</strong> {socket} <br/>
                                <strong>URL</strong> {url} <br/>
                                <strong>user</strong> {user} <br/>
                                <strong>pass</strong> {pass} <br/>
                            </React.Fragment>
                        }
                    >
                        <span>{element.signal_level}</span>
                    </HtmlTooltip>

                );
            }},
            {id: 'delivered_to', field: 'delivered_to', title: 'Cliente', sorting: true, render: (element) => element?.customer?.description}          
        ];

        this.state = { 
            rtuId: null,

            actionsAnchorEl: null,
            selectedElement: null,

            hovering: null,
        };
    }

    handleMouseOverMac = (id) => {
        this.setState({...this.state, hovering: id});
    }

    handleOpenActionsMenu = (event, element) => {
        this.setState( {...this.state, actionsAnchorEl: event.currentTarget, selectedElement: element });
    }

    handleCloseActionsMenu = () => {
        this.setState( {...this.state, actionsAnchorEl: null, selectedElement: null });
    }

    render = () => {
        const {dataSource, tableRef, isHidden, onViewState, displaySearch = true} = this.props; 

        return (
            <div>

                <Table
                    columns = { this.cols }
                    defaultSortColumnIndex = { 0 }
                    defaultSortDirection = 'asc'
                    data = { dataSource }
                    tableRef = { tableRef }
                    hidden = { isHidden }
                    displaySearch = { displaySearch }
                    actions = {[
                        (element) => (
                            {
                                icon: 'more_vert',
                                tooltip: 'Acciones',
                                disabled: false,
                                onClick: (event, element) => { 
                                    this.handleOpenActionsMenu(event, element);
                                },
                            }
                        ),
                        {
                            icon: this.props.displaySearch ? 'zoom_in' : 'zoom_out',
                            tooltip: this.props.displaySearch ? 'Cambiar a búsqueda avanzada' : 'Cambiar a búsqueda simple',
                            isFreeAction: true,
                            onClick: this.props.onToggleSearch,
                        },
                        {
                            icon: 'cloud_upload_icon',
                            tooltip: 'Importar remotas',
                            isFreeAction: true,
                            onClick: () => { this.props.uploadRtuHandler() },
                        },
                        {
                            icon: 'cloud_download_icon',
                            tooltip: 'Descargar remotas listadas',
                            isFreeAction: true,
                            onClick: () => { this.props.downloadRtusHandler() },  
                        },
                    ]}
                >
                </Table>
                <RtuActionDropdown
                    anchor = { this.state.actionsAnchorEl }
                    open = { !! this.state.actionsAnchorEl }
                    element = { this.state.selectedElement }
                    onClose = { this.handleCloseActionsMenu }
                    key = "dropdownActionMenu"

                    commands = { this.props.commands }
                    
                    handleEditConfig = { (element) => this.props.onEditConfig(element) }
                    handleDispatchCommand = { this.props.onSendCommand }
                    handleDownloadFat = { (element) => this.props.onDownloadFat(element) }
                    handleDebug = { (element) => this.props.onDebug(element) }
                    handleShowHistory = { (element) => this.props.onShowHistory(element)}
                    handleEditJsonConfig = { (element) => this.props.onEditJsonConfig(element) }
                    handleDisplayOtaForm = { (element) => this.props.handleDisplayOtaForm(element) }

                    handleAssemble = { (element) => this.props.onAssemble (element) }

                    handleSetType = { element => this.props.onSetType (element) }

                    handleLabelDownload = { element => this.props.onLabelDownload (element) }
                />                
            </div>
        );

    }
}

export default RtuTable;