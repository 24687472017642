import axios from 'axios';
import { store } from '../redux/store';

import { logoutUser } from '../redux/actions/users';
import { toast } from 'react-toastify';

const loggedUserServerFetch = async (url, method, payload={}, headers={}, query={}, extraConfig={}) => {
    const token = localStorage.token;
    headers['x-auth'] = token;

    let queryString = '';

    Object.keys(query).forEach( (key) => {
        let value = query[key];
        if (Array.isArray(value)) value=value.join(',');

        queryString = `${queryString}&${key}=${value}`;
        queryString = queryString.trim();
    });
    url = url.trim();

    if (queryString.length>0 && url.indexOf('?') < 0) queryString = '?' + queryString.slice(1);
    url = `${url}${queryString}`;


    return axios({
        url,
        method,
        headers,
        data: payload,
        ...extraConfig,

    }).catch ( (error) => {
        console.error(error);
        if ( error?.response?.status === 401 ) {
            store.dispatch ( logoutUser() );
            toast.error ('Su sesión ha expirado');
            error.alreadyHandled = true;
        }

        throw error;
    });
}

export default loggedUserServerFetch;