//Esto debe coincidir con las constantes del backend models\Rtu.php
export const RTU_STATUS_BORN = 'Fabricada';
export const RTU_STATUS_BUILT = 'Ensamblada';
export const RTU_STATUS_DELIVERED = 'Entregada';
export const RTU_STATUS_INSTALLED = 'Instalada';
export const RTU_STATUS_UNINSTALLED = 'Desinstalada';

export const HISTORY_TYPE_NOTE = 'Nota';
export const HISTORY_TYPE_BORN = 'Fabricación';
export const HISTORY_TYPE_ASSEMBLY = 'Ensamble';
export const HISTORY_TYPE_DELIVER = 'Entrega';
export const HISTORY_TYPE_INSTALL = 'Instalación';
export const HISTORY_TYPE_UNINSTALL = 'Desinstalación';
export const HISTORY_TYPE_COMMAND = 'Comando';
export const HISTORY_TYPE_OTA = 'OTA';    

export const COMMAND_REQUEST_CONFIG_ID = 2;

export const MAX_MODBUS_INTERFACES = 8;