import { TextField } from "@material-ui/core";

const InputText = (props) => {
    const {description, name, value, readonly = false} = props;

    return (
        <TextField
            id = {`setup_component_${name}`} 
            label = {description} 
            name = {name} 
            defaultValue = {value}
            readonly = {readonly}
            inputProps={ {maxLength: 15, pattern: "^((\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\.){3}(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])$"} }
            fullWidth
        />
    )
}

export default InputText;