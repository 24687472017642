import React, {useState} from 'react'
import Gallery from 'react-grid-gallery';
import { PropTypes } from 'prop-types';
import FileDownload from 'js-file-download';
import axios from 'axios';
import { IconButton } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { toast } from 'react-toastify';


const downloadImage = (photo) => {
  const { src, name } = photo;

  axios.get(src).then( (result) => {
    const content = result.data;
    FileDownload(content, name);

  }).catch ( err => {
    toast.error('Ocurrió un error al intentar descargar la imagen');
    console.error(err);

  }).then ( () => {});
  
}

const DownloadableGallery = (props) => {
  const {photos} = props;
  const [ currentImage, setCurrentImage ] = useState (0);

  return (
    <Gallery 
      images={photos}
      enableImageSelection={false}
      enableLightbox={true}
      currentImageWillChange={ (index) => setCurrentImage(index)}
      customControls={
        [
          <IconButton 
            key="downloadImage" 
            aria-label="Descargar imagen " 
            label="Descargar imagen"
            style={{color: 'white'}}
            onClick={ () => downloadImage(photos[currentImage]) }
          >
            <GetApp />
          </IconButton>
        ]
      }
    />
  );
}


DownloadableGallery.propTypes = {
    photos: PropTypes.array.isRequired,
}

export default DownloadableGallery;