import React from 'react'
import PropTypes from 'prop-types'
import { Box, CardActions, Grid } from '@material-ui/core';
import FormBackButton from '../../form-builder/FormBackButton';
import FormSubmitButton from '../../form-builder/FormSubmitButton';
import Group from './group';

const doSubmit = (event, handleSubmit) => {
    event.preventDefault();

    //Traduzco el form a json
    const fd = new FormData(event.target);
    const config = Object.fromEntries(fd.entries());        

    handleSubmit(config);
}


const Form = (props) => {    
    const {setup = null, form = null, onSubmit, onClose, readonly = false, hideButtons = false} = props;

    if (setup === null || form === null) return null;

    return (
        <form onSubmit={ev => doSubmit(ev, onSubmit)}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                {form.form.map( e => <Group fields={e.fields} orientation={e.orientation} setup={setup} readonly={readonly}/>)}
            </Grid>
            {
                hideButtons ? null :
                <Box pt={3} pb={1}>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <FormBackButton onClick={onClose} />
                        {(!readonly) && <FormSubmitButton />}
                    </CardActions>
                </Box>
            }
        </form>
    )
}

Form.propTypes = {
    setup: PropTypes.object,
    form: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    readonly: PropTypes.bool
}

export default Form;