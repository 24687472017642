import React from 'react'

const WithOnClick = (onClick) =>  (WrappedObject) => {
    return React.cloneElement(WrappedObject,
        {
            onClick: (ev) => { onClick(ev) }
        }
    );
}

export default WithOnClick;