import React from 'react';
import { toast } from 'react-toastify';
import PopUp from './popup/Popup';
import OtaForm from './forms/OtaForm';
import { API_OTA_CREATE } from '../constants/api';
import loggedUserServerFetch from '../services/loggedUserServerFetch';

const OtaPopup = (props) => {
    const { open, onClose, rtu = null } = props;

    if (! open) return null;

    const handleCreateItem = async ( form ) => {
        const url = API_OTA_CREATE;
        const method = 'put';
        let result = null;
        try {
            result = await loggedUserServerFetch(url, method, form);
            result = result?.data;            
            toast.success('Pedido de OTA generado exitosamente', 'success_ota_enqueue');
            props.onClose(true);

        } catch (error) {
            let msg = 'Ocurrió un error al guardar el pedido de OTA. Intente nuevamente';
            
            console.error(error, error?.response?.data);
            result = error?.response?.data;
            
            toast.error(msg, 'handle_save_error');
            throw error;
        }

        return result;
        
    }

    return (
        <PopUp
            open={true}
            maxWidth="md"
            fullWidth
        >
            <OtaForm 
                onClose = { onClose }
                isNew = { true }
                values = { {active: true, platform: `${rtu?.rtu_type?.id}`, rtu: [rtu?.id] }}
                preSelectedRtu = { rtu }
                onCreate = { handleCreateItem }    
            />
        </PopUp>
    )

}

export default OtaPopup;