import React from 'react'
import dayjs from 'dayjs';
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';
import AbstractAbmPage from './AbstractAbmPage.js';
import AssemblyForm from '../components/forms/AssemblyForm';
import RtuStatusIcons from '../components/RtuStatusIcons';
import DialogPhoto from '../components/DialogPhoto';
import filedownloader from '../helpers/filedownloader.js';

import { toast } from 'react-toastify';
import { API_ASSEMBLY_LIST, API_ASSEMBLY_CREATE, API_ASSEMBLY_DELETE, API_ASSEMBLY_PHOTO, API_DELIVERY_DOCUMENT } from '../constants/api';
import { RTU_STATUS_BUILT } from '../constants/misc';

const leadingZeros = (number, qty = 8) => number.toString().padStart(qty,'0').slice(-qty);

class AssembliesPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = AssemblyForm;
        this.strings = {
            createItem: 'Crear ensamblaje',
            editItem: 'Editar ensamblaje',
            itemCreationSuccess: 'Ensamblaje creado exitosamente',
            itemUpdateSuccess: 'Ensamblaje actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el ensamblaje seleccionado',
                save: 'Ocurrió un error al guardar el ensamblaje. Intente nuevamente.',
            }
        }
        
        this.urls = {
            list: API_ASSEMBLY_LIST,
            item: null,
            toggle: null,
            delete: API_ASSEMBLY_DELETE,
            create: API_ASSEMBLY_CREATE,
            update: null,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'created_at', field: 'created_at', title: 'Fecha y hora', sorting: true, render: (element) => dayjs(element.created_at).format('DD/MM/YYYY HH:mm')},
                {id: 'rtu_mac', field: 'rtu_mac', title: 'MAC', sorting: true, render: (e) => (e?.rtu?.mac)},
                {id: 'status', field: 'status', title: 'Estado', sorting: false, render: (e) => (<RtuStatusIcons  rtu_info={e?.rtu}/>)},
                {id: 'fw_ver', field: 'fw_ver', title: 'Fw ver', sorting: true},
                {id: 'type', field: 'type', title: 'Tipo', sorting: true, render: (e) => e?.rtu?.type?.description || 'Desconocido'},
                {id: 'imei', field: 'imei', title: 'IMEI', sorting: true},
                {id: 'assembler', field: 'assembler', title: 'Armador', sorting: true, render: (element) => element?.user?.name},
                {id: 'delivered', field: 'delivered', title: 'Entregada', render: (e) => (e.rtu?.has_been_delivered?'Sí':'No')},
            ],
            actions:[
                (element) => ({
                    icon: 'description',
                    tooltip: 'Descargar remito de la entrega',
                    disabled: ! element.rtu?.has_been_delivered,
                    onClick: (event, element) => { this.handleDownloadDelivery ({id: element.rtu.delivery_id}) }
                }),
                (element) => ({
                    icon: 'photo',
                    tooltip: 'Ver foto del ensamblaje',
                    disabled: ! element.has_photo,
                    onClick: (event, element) => { this.handleDisplayPhoto (element) }
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: (element.rtu.status === RTU_STATUS_BUILT)?'Eliminar ensamblaje':`No se puede eliminar el ensamblaje de una RTU que no esté en estado ${RTU_STATUS_BUILT}`,
                    disabled: element.rtu.status !== RTU_STATUS_BUILT,
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar el ensamblaje de ${element.rtu.mac}?`) }
                }),                                
                {
                    icon: 'add',
                    tooltip: 'Nuevo ensamblaje',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate() },
                },

            ]
        };

        this.state.photoPreviewUrl = null;
        this.state.photoPreviewTitle = null;
        this.state.photoPreviewUrl = null;

    }

    postRender = () => {
        return (
            <DialogPhoto
                open={!! this.state.photoPreviewUrl}
                onClose={this.handleCloseDisplayPhoto} 
                title={this.state.photoPreviewTitle}
                url={this.state.photoPreviewUrl}
            />
        )
    }

    handleDisplayPhoto = (element) => {
        const url = API_ASSEMBLY_PHOTO.replace('{$id}', element.id);
        this.setState({...this.state, photoPreviewUrl: url, photoPreviewTitle:`Foto del ensamblaje de la remota ${element.rtu?.mac}`});
    }

    handleCloseDisplayPhoto = () => {
        this.setState({...this.state, photoPreviewUrl: null, photoPreviewTitle: null});
    }

    handleDownloadDelivery = async (element) => {
        try {
            const url = (API_DELIVERY_DOCUMENT).replace('{$id}', element.id);
            const filename = `Remito-${leadingZeros(element.id)}.pdf`;
            await filedownloader (url, filename);

        } catch (error) {
            toast.error ('Ocurrió un error al intentar descargar el archivo', 'err_file_download');
        }
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(AssembliesPage));


