import React from 'react'
import dayjs from 'dayjs';
import { red, yellow, green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';
import { Icon} from '@material-ui/core';
import AbstractAbmPage from './AbstractAbmPage.js';
import { API_OTA_LIST, API_OTA_ACTIVATE, API_OTA_DELETE, API_OTA_CREATE, API_OTA } from '../constants/api';
import OtaForm from '../components/forms/OtaForm';

class OtaPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = OtaForm;
        this.strings = {
            createItem: 'Nuevo pedido de OTA',
            editItem: 'Editar OTA',
            itemCreationSuccess: 'Pedido de OTA generado exitosamente',
            itemUpdateSuccess: 'Pedido de OTA actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el OTA seleccionado',
                save: 'Ocurrió un error al guardar el pedido de OTA. Intente nuevamente.',
                E01: 'No se especificó un firmware',
                E02: 'Debe seleccionar al menos una RTU',
            }
        }
        
        this.urls = {
            list: API_OTA_LIST,
            item: API_OTA,
            toggle: API_OTA_ACTIVATE,
            delete: API_OTA_DELETE,
            create: API_OTA_CREATE,
            update: null,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 5,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'download_file', field: 'download_file', title: 'Archivo', sorting: false, render: (e) => {
                    return (<a href={e.firmware.uri}><Icon title="Descargar archivo">get_app</Icon></a>);
                }},
                {id: 'mac', field: 'mac', title: 'MAC', sorting: true, render: (e) => {
                    return e.rtu.mac;
                }},
                {id: 'name', field: 'name', title: 'Nombre', sorting: true, render: (e) => {
                    return e.rtu.name;
                }},
                {id: 'fw', field: 'fw', title: 'Firmware', sorting: true, render: (e) => {
                    return e.firmware.description;
                }},
                {id: 'type', field: 'rtu_type.description', title: 'Tipo', sorting: true},
    
                {id: 'created_at', field: 'created_at', title: 'Creación', sorting: true, render: (element) => element.created_at && dayjs(element.created_at).format('DD/MM/YYYY HH:mm:ss')},
                {id: 'last_download_at', field: 'last_download_at', title: 'Última descarga', sorting: true, render: (element) => element.last_download_at && dayjs(element.last_download_at).format('DD/MM/YYYY HH:mm:ss')},
                {id: 'end_download_at', field: 'end_download_at', title: 'Fin de descarga', sorting: true, render: (element) => element.end_download_at && dayjs(element.end_download_at).format('DD/MM/YYYY HH:mm:ss')},
    
                {id: 'status', field: 'status', title: 'Estado', sorting: false, render: (element) => {
                    
                    let txt = null;
                    let deco = null;
                    let icon = null;
    
                    if (element.cancelled) { 
                        txt = 'Cancelado';
                        deco = { color: red[500] };
                        icon = 'cancel';
    
                    } else if (element.end_download_at) {
                        txt = 'Transferido';
                        deco = { color: green[500] };
                        icon = 'check_circle_outline';
    
                    } else {
                        txt = element.active?'Disponible':'Pausado';
                        deco = element.active?{ color: green[500] }:{ color: yellow[500] };
                        icon = element.active?'play_circle_outline':'pause_circle_outline';
                    }
                    return (<Icon style={deco} title={txt}>{icon}</Icon>);
                }}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo OTA',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Pausar':'Reanudar',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar el pedido de OTA ${element?.firmware?.description} de ${element?.rtu?.mac} ?`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(OtaPage));


