import React from 'react'
import { yellow, green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';
import { Icon} from '@material-ui/core';
import AbstractAbmPage from './AbstractAbmPage.js';
import { API_TRIGGER_LIST, API_TRIGGER_ACTIVATE, API_TRIGGER_DELETE, API_TRIGGER, API_TRIGGER_CREATE, API_TRIGGER_EDIT } from '../constants/api';

import TriggerForm from '../components/forms/TriggerForm';

class TriggersPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = TriggerForm;
        this.strings = {
            createItem: 'Nuevo trigger',
            editItem: 'Editar trigger',
            itemCreationSuccess: 'Trigger generado exitosamente',
            itemUpdateSuccess: 'Trigger actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el trigger seleccionado',
                save: 'Ocurrió un error al guardar el trigger. Intente nuevamente.',




                E01: 'No se especificó un firmware',
                E02: 'Debe seleccionar al menos una RTU',
            }
        }
        
        this.urls = {
            list: API_TRIGGER_LIST,
            item: API_TRIGGER,
            toggle: API_TRIGGER_ACTIVATE,
            delete: API_TRIGGER_DELETE,
            create: API_TRIGGER_CREATE,
            update: API_TRIGGER_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'description', field: 'description', title: 'Descripción', sorting: true, },
                {id: 'field', field: 'field', title: 'Campo', sorting: false, render: (e) => {
                    if (e.type === 1) return e.field;
                    return null;
                }},
                {id: 'operator', field: 'operator', title: 'Operador', sorting: false },
                {id: 'value', field: 'value', title: 'Valor', sorting: false, render: (e) => {
                    if (e.type === 1) return e.value;
                    return null;
                }},
                {id: 'status', field: 'status', title: 'Estado', sorting: false, render: (element) => {
                    const txt = element.active?'Activo':'Pausado';
                    const deco = element.active?{ color: green[500] }:{ color: yellow[500] };
                    const icon = element.active?'play_circle_outline':'pause_circle_outline';
                    return (<Icon style={deco}  title={txt}>{icon}</Icon>);
                }}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo trigger',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Pausar':'Reanudar',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => ({
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: () => { this.handleEdit(element.id); } 
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar el trigger ${element?.description} ?`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(TriggersPage));


