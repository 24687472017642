import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { CloudUpload } from '@material-ui/icons';

import * as XLSX from 'xlsx';


class DinamycTable extends Component {
    constructor (props) {
        super(props);

        this.importRef = React.createRef();

        this.state = {
            values: props.values||[],
            loading: false,
        }
    }

    addRow = () => {
        let newState = {...this.state};
        let newRow = {};

        if ( typeof this.props.newRowTemplate === 'function') {
            newRow = this.props.newRowTemplate([...this.state.values]);

        } else {
            newRow = {...this.props.vauleTemplate};
        }
        
        newState.values.push(newRow);
        this.setState(newState);
    }

    removeRow = (index) => {
        let newState = {...this.state};
        newState.values.splice(index,1);
        this.props.onChange && this.props.name && this.props.onChange(this.props.name, [...newState.values]);
        this.setState(newState);
    }

    onChange = (index, name, value) => {
        let newState = {...this.state};
        newState.values[index][name] = value;
        this.props.onChange && this.props.name && this.props.onChange(this.props.name, [...newState.values]);
        this.setState(newState);
    }

    handleImport = (e) => {
        e.preventDefault();
        const files = e.target.files;
        const f = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            this.setState({...this.state, loading: true}, () => {
                
                setTimeout( () => {
                    const bstr = e.target.result;
                    const wb = XLSX.read (bstr, {type: 'binary'});
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = XLSX.utils.sheet_to_json(ws, {header: 1});
                    const result = [];
                    const columns = this.props.columns;
                    
                    const columnNames = columns.map(e=>e.id);
                    
                    for ( let i = 1 ; i < data.length; i++) {
                        const row = data[i];
                        const obj = columnNames.reduce ((obj, key, index) => ({ ...obj, [key]: (typeof row[index] === 'undefined')? null : row[index] }), {});
                        result.push({...obj});
                    }
                    
                    this.props.onChange && this.props.name && this.props.onChange(this.props.name, [...result]);
                    this.setState({...this.state, loading: false, values:[...result]});
                }                
                , 500);
            });
            
        }
        reader.readAsBinaryString(f);
    }

    openImportDialog = () => {
        const ref = this.importRef;        
        ref.current?.click();
    }

    renderImport = () => {        
        const ref = this.importRef;
        return (
        <Grid container justifyContent="flex-end">
            <input type="file" ref={ref} onChange={this.handleImport} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style={{display: 'none'}}/>
            <Grid item justifyContent="flex-end">
                <IconButton aria-label={`Importar archivo`} size="small" title="Importar archivo" onClick={this.openImportDialog}>
                    <CloudUpload />
                </IconButton>
            </Grid>
        </Grid>
        )

    }

    render = () => {
        const {classes, columns, renderCellContent, enableImport} = this.props;
        const {values, loading} = this.state;
        const headerColumns = [...columns];
        headerColumns.push('&nbsp;');

        return (
            <React.Fragment>
                {enableImport && this.renderImport()}
                <Dialog
                    open={loading}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Importando archivo</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item alignContent="center">Esta tarea puede demorar. Por favor espere...</Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Grid container>
                    <Grid xs={12}> 
                        <TableContainer component={Paper}>
                            <Table className={classes?.Table}>
                                <TableHead>
                                    <TableRow>
                                        {headerColumns.filter( e=> (e.display !== false)).map ( c => (<TableCell key={c.id}>{c.label}</TableCell>) )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {values.map ( (value,index) => 
                                        (<TableRow>
                                            {columns.filter( e=> (e.display !== false)).map (c => <TableCell>{renderCellContent(c.id, index, value, (v) => this.onChange(index, c.id, v))}</TableCell> )}
                                            <TableCell>
                                                <IconButton onClick={ () => this.removeRow(index) } aria-label={`Eliminar fila ${index+1}`} size="small" title={`Eliminar fila ${index+1}`}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item justifyContent="flex-end">
                        <IconButton onClick={ this.addRow } aria-label={`Nueva fila`} size="small" title="Nueva fila">
                            <AddIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

}

DinamycTable.propTypes = {
    values: PropTypes.array,
    newRowTemplate: PropTypes.object.isRequired || PropTypes.func.isRequired,    
    columns: PropTypes.object.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
}

export default DinamycTable;