import { useCallback, useEffect, useState } from "react";

const useIconPainter = ( icon = null, color = null ) => {
    const [selectedColor, setSelectedColor] = useState(color);
    const [selectedIcon, setSelectedIcon] = useState (icon);
    const [paintedIcon, setPaintedIcon] = useState (null);

    const convertHexToRgb = useCallback ( hex => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : {r: 0, g: 0, b: 0};
    })
    
    useEffect(() => {
        if (selectedColor !== null && selectedIcon != null) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.src = selectedIcon;
            img.onload = () => {
                const { r, g, b } = convertHexToRgb(selectedColor);
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                const canvasData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = canvasData.data;
                
                for (let i = 0; i < data.length; i += 4) {
                    data[i] += r;
                    data[i+1] += g;
                    data[i+2] += b;                
                }                

                ctx.putImageData(canvasData, 0, 0);

                setPaintedIcon(canvas.toDataURL());
            }
        }

    }, [selectedColor, selectedIcon])

    return {
        selectedColor,
        setSelectedColor,
        selectedIcon,
        setSelectedIcon,
        paintedIcon,
    }
}

export default useIconPainter;