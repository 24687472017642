import { ACTION_IS_LOADING } from '../actions';
import { createSelector } from 'reselect';

export const loadingReducer = (state = {}, action) => {
    let result = null;

    switch (action.type) {
        case ACTION_IS_LOADING:
            result = action.payload;
            break;
    
        default:
            result = state;
            break;
    }
    return result;
}

export const isLoading = createSelector(
    (state) => state.loading,
    state => state);
