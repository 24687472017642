import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { pageStyle } from '../styles';


const installationTable = (props) => {
    const {info, classes} = props;
    return (
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Ticket</TableCell>
                    <TableCell>Fecha y hora</TableCell>
                    <TableCell>Cuadrilla</TableCell>
                    <TableCell>Matrículas</TableCell>
                </TableRow>
            </TableHead>
        <TableBody>
            {info.map((row) => (
                <TableRow key={row.ticket_number}>
                    <TableCell>{row.ticket_number}</TableCell>
                    <TableCell>{row.timestamp}</TableCell>
                    <TableCell>{row.user_name}</TableCell>
                    <TableCell>
                        <ul>
                            {row.jobs.map( (job) => <li>{job.code} - {job.description}</li>)}
                        </ul>
                    </TableCell>
                </TableRow>
          ))}
        </TableBody>
      </Table>
    );
}


const InstallationHistory = (props) => {

    const {open, info, installation} = props;

    if ( (! open) || (! info) ) return null;

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open={open}
            onClose={ () => {} }
            aria-labelledby="rtuHistoryDetails"
            disableEnforceFocus={true}
        >
            <DialogTitle id="rtuHistoryDetails">Historia de la instalación {installation} </DialogTitle>
            <DialogContent>
                {installationTable(props)}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>

    )



}

export default withStyles(pageStyle)(InstallationHistory);