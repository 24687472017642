export const HOME_URL = '/';
export const MAP_URL = '/map';
export const LOGIN_URL = '/login';
export const LOGOUT_URL = '/logout';
export const PASSWORD_URL = '/password';
export const USER_URL = '/users';
export const INSTANCE_URL = '/instances';
export const RTU_URL = '/rtus';
export const ASSEMBLY_URL = '/assemblies';
export const DELIVERY_URL = '/deliveries';
export const FIRMWARE_URL = '/firmwares';
export const OTA_URL = '/otas';
export const TRIGGERS_URL = '/triggers';
export const EVENTS_URL = '/events';
export const COMMAND_URL = '/commands';
export const API_KEY_URL = '/api_keys';
export const RTU_TYPE_URL = '/rtu_type';
export const SETUP_URL = '/setup';

export const PASSWORD_RECOVER_URL = '/passwordRecover';

export const DEMO_URL = '/demo';