import React, { Component } from 'react'
import AbstractForm from './AbstractForm';
import { MAX_IMAGE_FILE_SIZE } from '../../constants/ui';
import RtuStatus from '../RtuStatus';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import { API_RTU } from '../../constants/api';

class AssemblyForm extends Component {
    constructor (props) {
        super(props);

        this.schema = {
            fields: [
                {
                    key: 'mac',
                    name: 'mac',
                    type: 'mac-scanner',
                    label: 'Ingrese MAC o escanee un QR',
                    required: true,
                    disabled: false,
                    autoFocus: true,
                    listenOnChange: this.handleOnChangeMac,
                },
                {
                    key: 'photo',
                    name: 'photo',
                    type: 'dropzone',
                    label: 'Archivo',
                    required: true,
                    disabled: false,
                    acceptedFiles: ['image/*'],
                    dropzoneText: "Arrastrá y soltá una imagen o hacé click acá",
                    filesLimit: 1,
                    maxFileSize: MAX_IMAGE_FILE_SIZE,

                    showFileNames: true,
                    showAlerts: ['error', 'info'],
                    alertSnackbarProps: {
                        anchorOrigin: { horizontal: 'right', vertical: 'top'},
                        autoHideDuration: 3000,
                    },                    
                }
            ]    
        };

        this.state = {
            rtuState: {},
            saveDisabled: true,
            timer: null,
            rtuOk: false,
        }
    }

    componentWillUnmount = () => {
        if (this.state.timer) clearInterval(this.state.timer);
    }

    componentDidMount = () => {
        if ( this.props?.values?.mac ) this.handleOnChangeMac(this.props?.values?.mac);
    }

    handleOnChangeMac = (mac) => {
        if (this.state.timer) clearInterval(this.state.timer);

        const fnc = this.updateRtuStatus;

        const timer = setInterval(() => {
            fnc(mac);
        }, 5000);
        fnc(mac);

        this.setState({...this.state, timer: timer });
    }

    updateRtuStatus = (mac) => {
        loggedUserServerFetch ( (API_RTU).replace('{$id}',mac), 'get', {}, {}, {} ).then ( (response) => {

            let rtu = response.data;

            /*TODO: Validar que la RTU que quiero ensamblar esté Fabricada, 
            o si estoy editando, que sea la RTU del ensamblaje y estado Ensamblada*/

            this.setState( { ...this.state, rtuState: rtu, rtuOk: rtu?.last_state||false});

        }).catch ( (error) => {
            console.error('Error', error);
            if (this.state.timer) clearInterval(this.state.timer);
            this.setState({...this.state, timer:null, rtuState: null, rtuOk: false});

        }).then ( () => {

        })
    }

    render = () => {
        if (this.props.hidden) return null;
        
        return (
            <AbstractForm
                schema={this.schema} 
                values={this.props.values} 
                disableSaveButton={! this.state.rtuOk }
                {...this.props}               
            >
                <RtuStatus status={this.state.rtuState}/>
            </AbstractForm>
        );
    }
}

export default AssemblyForm;