import { MenuItem, TextField } from "@material-ui/core";

function createOptions (options, value) {
    const pf = String(options);
    value = String(value);

    const separator = pf.includes(',')?',':'.';

    const componentOptions = pf.split(separator).map( e => <MenuItem value={e} key={e}>{e}</MenuItem>);
    
    return componentOptions;
}


const InputSelect = (props) => {    
    const {description, name, value, options, readonly = false} = props;
    const componentOptions = createOptions (options, value);
    return (
        <TextField
            select
            fullWidth
            id = {`setup_component_${name}`} 
            label = {description} 
            name = {name}
            defaultValue = {value}
            readonly = {readonly}
        >
            {componentOptions}
        </TextField>
    )
}

export default InputSelect;