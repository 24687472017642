import React from 'react'
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";

import { getUpdateInterval } from '../redux/reducers/ui';
import { pageStyle } from "../styles/styles.js";

import AbstractAbmPage from './AbstractAbmPage.js';
import { API_INSTANCE_LIST, API_INSTANCE, API_INSTANCE_ACTIVATE, API_INSTANCE_DELETE, API_INSTANCE_CREATE, API_INSTANCE_EDIT, API_INSTANCE_PING } from '../constants/api';
import ActiveIcon from '../components/icondisplay/ActiveIcon.js';
import InstanceForm from '../components/forms/InstanceForm';
import { toast } from 'react-toastify';
import loggedUserServerFetch from '../services/loggedUserServerFetch';

class InstancePage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = InstanceForm;
        this.strings = {
            createItem: 'Nueva instancia',
            editItem: 'Editar instancia',
            itemCreationSuccess: 'Instancia cargada exitosamente',
            itemUpdateSuccess: 'Instancia actualizada exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra la instancia seleccionada',
                save: 'Ocurrió un error al guardar la instancia. Intente nuevamente.',
            }
        }
        
        this.urls = {
            list: API_INSTANCE_LIST,
            item: API_INSTANCE,
            toggle: API_INSTANCE_ACTIVATE,
            delete: API_INSTANCE_DELETE,
            create: API_INSTANCE_CREATE,
            update: API_INSTANCE_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'description', field: 'description', title: 'Nombre', sorting: true, },
                {id: 'url', field: 'url', title: 'URL', sorting: true, },
                {id: 'outgoing_api_key', field: 'outgoing_api_key', title: 'Clave API hacia TLS', sorting: false, render: (element) => {
                    return <div>{element.has_outgoing_api_key?'Sí':'No'}</div>
                }},
                {id: 'status', field: 'status', title: 'Estado', sorting: false, render: (e) => <ActiveIcon active={!!e.active} activeLabel="Activo" inactiveLabel="Inactivo"/>},
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nueva instancia',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: this.state.ping ? 'settings_input_antenna' : 'link',
                    tooltip: this.state.ping ? 'Comprobando conexión...' : 'Comprobar conexión',
                    disabled: this.state.ping,
                    onClick: () => {  this.handleCheckLink(element.id); },
                }),
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar':'Activar',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => ({
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: () => {  this.handleEdit(element.id) },
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar la instancia ${element.description}?`) }
                }),
            ]
        };

        this.state.ping = false;
    }

    /**
     * Check if BACKEND can comunicate with instance API. 
     * @param {string} url 
     * @param {string} api_key 
     */
    handleCheckLink = async (id) => {
                        
        try {
            await this.setStateAsync ({...this.state, ping: true});
            const url = (API_INSTANCE_PING).replace('{$id}', id);            
            await loggedUserServerFetch (url, 'get', {}, {}, {});
            toast.success('Comunicación exitosa');
            
        } catch (error) {
            let msg =  '';
            console.error('Error al pinguear', error);            
            if (! error.response) {
                msg = 'Error de red. No se puede conectar o el acceso está bloqueado por CORS';

            } else if (error.response.status === 401) {
                msg = 'No está autorizado para acceder a la API de la instancia';
            
            } else if (error.response.status === 404) {
                msg = 'No se encuentra la API';
            
            } else if (error.response.status === 500) {
                msg = 'El servidor respondió con un error interno';

            } else {
                msg = 'Ocurrió un error desconocido. Vuelva a intentar más tarde';
            }
            toast.error(msg);            
        }
        this.setState({...this.state, ping: false});
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(InstancePage));


