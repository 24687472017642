import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const SimpleList = (props) => {
  const classes = useStyles();
  const { items } = props;

  return (
    <List className={classes.root}>
      { items.map ( e => (
        <ListItem>
          <ListItemText primary={e?.label} secondary={e?.value} />
        </ListItem>
      ))}
    </List>
  );
}

export default SimpleList;