import { Menu, MenuItem, withStyles } from '@material-ui/core';
import React, { useState } from 'react'
import IconButton from './IconButton'
import PropTypes from 'prop-types'

let menuItemSelectedStyle = {}

const buildMenuItems = (options, onChange, value, withStyle = false) => {
    const result = options.map ( e => {
        const id = e.value;
        const description = e.description;        
        const isSelected = Array.isArray(value) ? value.includes(id) : (value === id);
        
        const menuItemStyle = theme => ({
            selected: menuItemSelectedStyle,
        });

        const Item = withStyle ?  withStyles(menuItemStyle)(MenuItem) : MenuItem;

        return (
            <Item 
                key={`option_menu_${id}`} 
                onClick={ () => onChange(id)} 
                selected={isSelected}

            >
                {description}
            </Item>
        )
    })

    return result;
}

const ComboBox = (props) => {
    const { name, value = null, label, icon, onChange, options, selectedForeColor = null, selectedBackgroundColor = null } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleSelect = (value) => {
        onChange(value, name);
        handleClose();
    }

    const withStyle = (selectedForeColor || selectedBackgroundColor);

    if (selectedForeColor) menuItemSelectedStyle.color = selectedForeColor;
    if (selectedBackgroundColor) menuItemSelectedStyle.backgroundColor = `${selectedBackgroundColor} !important`;
    
    return (
        <>
            <IconButton 
                title={label} 
                icon={icon}
                id={`${name}_toggle_menu`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            />
            <Menu
                id={`${name}_menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}                
            >
                {buildMenuItems (options, handleSelect, value, withStyle)}
            </Menu>

        </>
    )
}

ComboBox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
}

export default ComboBox;