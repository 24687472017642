import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_OTA_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';

class OtaForm extends Component {
    constructor (props) {
        super(props);
        
        this.state = {
            fw: [],
            rtu: [],
            rtu_type: {},

            version: null,
            timestamp: null,
            crc32: null,
            type: null,
        };
        
        this.populateForm();
    }

    populateForm = () => {
        loggedUserServerFetch (API_OTA_FORM, 'get', {}, {}, {}).then ( (response) => {
            const fw = response?.data?.fw || [];
            let rtu = response?.data?.rtu || [];
                        
            if (this.props.preSelectedRtu) 
                rtu = rtu.filter ( r => r.id === this.props.preSelectedRtu.id );

            const rtu_type = Object.fromEntries(
                rtu.map( e => ( [e.rtu_type_id, e.rtu_type_description ]) )
                .sort((a, b) => a[0] - b[0])
            );


            this.setState({...this.state, fw, rtu, rtu_type});

        }).catch ( (error) => {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de OTA');
            this.props.onClose();
        })

    }

    buildSchema = (values) => {

        let fwOptions = {};
        let rtuOptions = [];
        let targetPlatform = null;

        targetPlatform = parseInt(values?.platform)||null;

        if (targetPlatform !== null) {
            const rtus = this.state.rtu.filter( e => e.rtu_type_id === targetPlatform);
            rtuOptions = rtus.map( element => {
                const label = [];
                if (element.name) label.push(element.name);
                label.push (element.mac);            
                if (element.fw_ver) label.push (`FW ver: ${element.fw_ver}`);
                return {label: label.join(' - '), value: element.id};
            })

            const fws = this.state.fw.filter( fw => fw.rtu_types.some(e => e.id === targetPlatform));
            fws.forEach ( fw => {
                const str_plat = fw.rtu_types.map( e => e.description).join(', ');
                fwOptions[fw.id] = `${fw.description} - Versión ${fw.version} - ${str_plat}`;
            });

        }

        
        const schema =  {
            fields: [            
                {
                    key: 'platform',
                    name: 'platform',
                    type: 'select',
                    label: 'Tipo de remota',
                    required: true,
                    disabled: false,
                    options: this.state.rtu_type,
                },
                {
                    key: 'rtu',
                    name: 'rtu',
                    type: 'multiselect-autocomplete',
                    label: 'Remotas',
                    required: true,
                    disabled: targetPlatform === null,
                    idField: 'id',
                    labelField: 'description',
                    options: rtuOptions,
                },
                {
                    key: 'firmware',
                    name: 'firmware',
                    type: 'select',
                    label: 'Versión de firmware',
                    required: true,
                    disabled: targetPlatform === null,
                    autoFocus: true,
                    options: fwOptions,
                },
                {
                    key: 'active',
                    name: 'active',
                    type: 'switch',
                    label: 'Activo',
                    required: false,
                    disabled: false,
                }
            ]
        };
        return schema;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema;
        if (! this.state.fw && ! this.state.rtu ) return this.renderLoading();
        return (
            <AbstractForm 
                schema={schema} 
                {...this.props}
            />)
    }
}

export default OtaForm;